import { useState, useCallback, useContext, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import CountryList from 'country-list';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';

import AuthContext from '@/core/AuthContext';

import UserService from '@/services/user.service';

import UpdateUser from './UpdateUser.component';

const UPDATEABLE_FIELDS = ['firstName', 'lastName', 'email', 'country', 'postalCode'];

export default PresentationContainer({
  component: UpdateUser,
  controller: function UpdateUserController() {
    const { user, refetch } = useContext(AuthContext);

    const [formState, setFormState] = useState({
      values: pick(user, UPDATEABLE_FIELDS)
    });

    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getCountries = () => sortBy(CountryList.getData(), ['name']);
    const getCountryLabel = ({ name }) => name;
    const getCountrySelected = ({ code: optionId }, { code: valueId }) => optionId === valueId;
    const getCountryByCode = code => CountryList.getData().find(data => data.code === code);

    const handleEditPress = useCallback(() => setIsEditModeActive(true));
    const handleCancelPress = useCallback(() => setIsEditModeActive(false));

    const handleFormChange = state => setFormState(state);
    const handleFormSubmit = async ({ values, validationState }) => {
      if (!validationState.valid) {
        return;
      }

      setIsSubmitting(true);

      const updatedUser = pick(values, UPDATEABLE_FIELDS);

      try {
        await UserService.update({ ...updatedUser, country: updatedUser.country.code });
        refetch();
        setIsEditModeActive(false);
      } catch (e) {
        setErrors([e.message]);
      }

      setIsSubmitting(false);
    };
    const handleErrorsClose = () => setErrors([]);

    const canUpdate = !isEqual(
      pick(formState.values, UPDATEABLE_FIELDS),
      pick(user, UPDATEABLE_FIELDS)
    );

    return {
      user,
      formState,
      errors,
      isEditModeActive,
      canUpdate,
      isSubmitting,
      getCountries,
      getCountryLabel,
      getCountrySelected,
      getCountryByCode,
      handleFormChange,
      handleFormSubmit,
      handleErrorsClose,
      handleEditPress,
      handleCancelPress
    };
  }
});
