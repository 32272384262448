import React from 'react';
import Alert from '@material-ui/lab/Alert';

const RouterError = () => (
  <div>
    <Alert color="error">An unexpected error has occured. Try to refresh the page.</Alert>
  </div>
);

export default RouterError;
