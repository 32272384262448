import axios from 'axios';

import AuthService from './auth.service';

import { API_DOMAIN } from './service.constants';

export const getAxios = async () => {
  const defaultOptions = {
    baseURL: API_DOMAIN,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  const { token } = (await AuthService.getAuthData()) || {};

  if (token) {
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });
  }

  return instance;
};
