import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import Immutable from 'immutable';
import createSagaMiddleware from 'redux-saga';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

import Router from '@/Router.component';
import { reducer, saga } from '@/store';
import theme from '@/theme/material-theme';

const queryClient = new QueryClient();

const logger = createLogger({
  stateTransformer: state =>
    Object.keys(state).reduce(
      (newState, key) => ({
        ...newState,
        [key]: Immutable.Iterable.isIterable(state[key]) ? state[key].toJS() : state[key]
      }),
      {}
    )
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(logger, sagaMiddleware));

sagaMiddleware.run(saga);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider {...{ store }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  </QueryClientProvider>
);

export default App;
