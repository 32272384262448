import React from 'react';

import get from 'lodash/get';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import LoadingOverlay from '@/components/LoadingOverlay';

import './ExerciseConfig.styles.scss';

const ExerciseConfig = ({ controller }) => (
  <div className="Admin-ExerciseConfig">
    <LoadingOverlay visible={controller.isLoading} />
    <div>
      <TextField
        select
        label="Recommended Exercise"
        value={get(controller, 'config.recommended', '')}
        onChange={controller.onRecommendedChange}
        variant="outlined"
      >
        {get(controller, 'exercises', []).map(exercise => (
          <MenuItem key={exercise.id} value={exercise.id}>
            {exercise.name}
          </MenuItem>
        ))}
      </TextField>
    </div>

    <Divider />

    <Typography>Label Priority</Typography>

    <TextField
      select
      label="Add Label"
      value=""
      onChange={controller.onAddLabelChange}
      variant="outlined"
      classes={{ root: 'add-label' }}
      disabled={!controller.isLabelPickerEnabled}
      helperText={!controller.isLabelPickerEnabled ? 'No available labels' : undefined}
    >
      {controller.availableLabels.map(label => (
        <MenuItem key={label.id} value={label.id}>
          {label.name}
        </MenuItem>
      ))}
    </TextField>

    <Paper className="label-priority-card">
      <List component="nav" aria-label="secondary mailbox folders">
        {get(controller, 'config.labelPriority', []).map((labelId, index, arr) => {
          const label = get(controller, 'labels', []).find(({ id }) => labelId === id);

          if (!label) return null;

          return (
            <ListItem button key={labelId}>
              <ListItemText primary={label.name} />
              <ListItemSecondaryAction>
                <IconButton
                  size="small"
                  disabled={index === 0}
                  onClick={controller.onLabelUpPress(label.id)}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={index >= arr.length - 1}
                  onClick={controller.onLabelDownPress(label.id)}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton size="small" onClick={controller.onLabelDeletePress(label.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  </div>
);

export default ExerciseConfig;
