import React from 'react';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TextInputControl = ({
  value,
  onChange,
  onTouch,
  validationState,
  errorMessages,
  ...restOfProps
}) => {
  const { dirty, pending, touched, valid, ...restOfValidation } = validationState;

  const hasError = (dirty || touched) && !valid;

  const errors = Object.keys(restOfValidation).reduce((prev, key) => {
    if (!restOfValidation[key]) {
      return [...prev, errorMessages[key]];
    }
    return prev;
  }, []);

  const handleChange = e => {
    onChange(e.target.checked);
    onTouch();
  };

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        {...restOfProps}
        control={<Checkbox checked={value || false} onChange={handleChange} />}
      />
      {errors.length > 0 && <FormHelperText>{errors.join(', ')}</FormHelperText>}
    </FormControl>
  );
};

export default TextInputControl;
