import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';

import './ForgotPassword.styles.scss';

const ForgotPassword = ({ controller }) => (
  <div className="ForgotPassword">
    <div className="overlay" />

    <Card className="form-container">
      <CardContent>
        {!controller.success && (
          <>
            <div className="form-control">
              <Typography align="center">Enter the email associated with your account</Typography>
            </div>

            <div className="form-control">
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={controller.email}
                onChange={controller.onEmailChange}
                error={!!controller.errors.email}
                helperText={(controller.errors?.email || []).join(', ')}
              />
            </div>

            <div className="form-control actions">
              <Button variant="contained" color="primary" onClick={controller.onSendPress}>
                Send
              </Button>
            </div>

            {controller.errors.form && (
              <Alert severity="error" variant="filled">
                {controller.errors.form.join(', ')}
              </Alert>
            )}
          </>
        )}
        {controller.success && (
          <div className="form-control">
            <Typography align="center">{`A recovery email has been sent to ${controller.email} if that account exists`}</Typography>
          </div>
        )}
      </CardContent>
    </Card>
  </div>
);

export default ForgotPassword;
