import { v4 as uuid } from 'uuid';
import QueryString from 'query-string';

import { getAxios } from './service.helpers';

const SESSION_KEY = 'USER_SESSION_ID';

const debounce = (fn, duration, { selectKey } = {}) => {
  const timers = {};

  return (...args) =>
    new Promise((resolve, reject) => {
      const key = selectKey ? selectKey(...args) : '__default__';
      const { [key]: timer } = timers;

      if (timer) {
        clearTimeout(timer);
      }

      timers[key] = setTimeout(() => {
        Promise.resolve(...args)
          .then(fn)
          .then(resolve)
          .catch(reject);
      }, duration);
    });
};

const sendPageView = debounce(
  async requestData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/user/log`, requestData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: log, error } = data;

    if (!success) {
      throw error;
    }

    return log;
  },
  2000,
  { selectKey: data => data.meta.full }
);

export default class SessionService {
  static _inProgressPageViewData = {};

  static _inProgressPageViewCallbacks = {};

  static generateId() {
    const id = uuid();

    sessionStorage.setItem(SESSION_KEY, id);

    return id;
  }

  static getSessionId() {
    const sessionId = sessionStorage.getItem(SESSION_KEY);

    if (sessionId) {
      return sessionId;
    }

    return this.generateId();
  }

  static pageView = async (urlString, userId) => {
    const { href, protocol, host, pathname, search } = new URL(urlString);

    const data = {
      sessionId: this.getSessionId(),
      source: 'web',
      eventType: 'pageView',
      userId,
      meta: {
        full: href,
        protocol,
        host,
        path: pathname,
        search: QueryString.parse(search)
      }
    };

    return sendPageView(data);
  };

  static checkoutComplete = async userId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/user/log`, {
      userId,
      sessionId: this.getSessionId(),
      source: 'web',
      eventType: 'checkoutComplete'
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: log, error } = data;

    if (!success) {
      throw error;
    }

    return log;
  };

  static uncaughtError = async (userId, data) => {
    const axios = await getAxios();

    await axios.post(`/user/log`, {
      sessionId: this.getSessionId(),
      source: 'web',
      eventType: 'uncaughtError',
      userId,
      meta: data
    });
  };
}
