import '@babel/polyfill';
import 'fontsource-roboto';
import '@/styles/material-ui.scss';
import '@/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';

import App from '@/App.component';
import AuthService from '@/services/auth.service';
import SessionService from '@/services/session.service';

import '@/styles/form.scss';

window.onerror = async (message, url, lineNo, columnNo, error) => {
  const { user: { id } = {} } = (await AuthService.getAuthData()) || {};

  await SessionService.uncaughtError(id, {
    message,
    url,
    lineNo,
    columnNo,
    error: {
      name: error.name,
      message: error.message,
      stack: error.stack
    }
  });
};

ReactDOM.render(<App />, document.getElementById('root'));
