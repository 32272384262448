import React from 'react';
import Form from 'react-forms-system';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import Alert from '@material-ui/lab/Alert';

import TextInputControl from '@/components/TextInputControl';
import SearchFieldControl from '@/components/SearchFieldControl';

import { required } from '@/core/validators';

import './UpdateUser.styles.scss';

const UpdateUser = ({ controller }) => (
  <div className="UpdateUser">
    <div className="form-group">
      <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
        User Details
      </Typography>
    </div>
    <Divider />

    {!controller.isEditModeActive && (
      <div>
        <div className="form-group">
          <div>
            <Typography variant="subtitle1">{`${controller.user.firstName} ${controller.user.lastName}`}</Typography>
          </div>

          <div>
            <Typography variant="caption">{`${controller.user.email}`}</Typography>
          </div>

          <div>
            <Typography variant="caption">
              {`${controller.getCountryByCode(controller.user.country).name}`}
            </Typography>
          </div>

          <div>
            <Typography variant="caption">{`${controller.user.postalCode || ''}`}</Typography>
          </div>
        </div>

        <div className="form-group">
          <div className="form-control">
            <Button variant="contained" color="primary" onClick={controller.handleEditPress}>
              Edit
            </Button>
          </div>
        </div>
      </div>
    )}
    {!!controller.isEditModeActive && (
      <Form onSubmit={controller.handleFormSubmit} onStateChange={controller.handleFormChange}>
        <div className="form-group">
          <div className="form-control">
            <Form.Control
              name="firstName"
              label="First Name"
              variant="outlined"
              component={TextInputControl}
              defaultValue={controller.user.firstName}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>

          <div className="form-control">
            <Form.Control
              name="lastName"
              label="Last Name"
              variant="outlined"
              component={TextInputControl}
              defaultValue={controller.user.lastName}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <Divider />

        <div className="form-group">
          <div className="form-control">
            <Form.Control
              name="email"
              label="Email"
              variant="outlined"
              component={TextInputControl}
              defaultValue={controller.user.email}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <Divider />

        <div className="form-group">
          <div className="form-control">
            <Form.Control
              name="country"
              label="Country"
              variant="outlined"
              component={SearchFieldControl}
              getOptions={controller.getCountries}
              getOptionLabel={controller.getCountryLabel}
              getOptionSelected={controller.getCountrySelected}
              defaultValue={controller.getCountryByCode(controller.user.country)}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <Divider />

        <div className="form-group">
          <div className="form-control">
            <Form.Control
              name="postalCode"
              label="Postal Code"
              variant="outlined"
              component={TextInputControl}
              defaultValue={controller.user.postalCode}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-control">
            <Button
              variant="contained"
              color="primary"
              disabled={controller.isSubmitting || !controller.canUpdate}
              type="submit"
            >
              Save Changes
              {controller.isSubmitting && (
                <CircularProgress size={24} className="button-progress" />
              )}
            </Button>
          </div>

          <div className="form-control">
            <Button
              onClick={controller.handleCancelPress}
              variant="outlined"
              disabled={controller.isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={controller.errors.length > 0}
          autoHideDuration={6000}
          onClose={controller.handleErrorsClose}
        >
          <Alert severity="error" variant="filled">
            {controller.errors.join(', ')}
          </Alert>
        </Snackbar>
      </Form>
    )}
  </div>
);

export default UpdateUser;
