import React from 'react';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone';

import Alert from '@material-ui/lab/Alert';

import './UserList.styles.scss';

const UserList = ({ controller }) => (
  <div className="UserList">
    {controller.isLoading && <CircularProgress size={50} />}
    {controller.error && <Alert severity="error">{controller.error.message}</Alert>}
    <div>
      <div className="actions">
        <IconButton
          onClick={controller.handleSyncSubscriptionPress}
          disabled={controller.isSyncing}
        >
          {controller.isSyncing ? <CircularProgress size={22} /> : <SyncTwoToneIcon />}
        </IconButton>
      </div>

      <Divider />
    </div>

    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>FirstName</TableCell>
              <TableCell>LastName</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subscription Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Trial</TableCell>
              <TableCell>Cancelled</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Last Active</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {controller.users.map(
              ({
                id,
                type,
                active,
                trialEnd,
                isCancelled,
                firstName,
                lastName,
                email,
                createTime,
                country,
                lastTouch,
              }) => (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{firstName}</TableCell>
                  <TableCell>{lastName}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell style={{ color: active ? 'green' : 'red' }}>
                    {active ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell style={{ color: trialEnd ? 'blue' : undefined }}>
                    {trialEnd ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell style={{ color: isCancelled ? 'red' : 'green' }}>
                    {isCancelled ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell>{moment(createTime).format('MMM DD, YYYY HH:mm:ss')}</TableCell>
                  <TableCell>{country}</TableCell>
                  <TableCell>
                    {lastTouch ? moment(lastTouch).format('MMM DD, YYYY HH:mm:ss') : 'Never'}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </div>
);

export default UserList;
