import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

import BancontactSvg from '@/assets/bancontact.svg';

const Bancontact = (props) => (
    <SvgIcon {...props}>
        <BancontactSvg />
    </SvgIcon>
);

export default Bancontact;
