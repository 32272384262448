import { useEffect, useState } from 'react';
import PresentationContainer from 'react-presentation-container';

import { Spaces, spacesMiddleware } from '@fastercise/common-frontend';

import AffiliateService from '@/services/affiliate.service';

import Affiliates from './Affiliates.component';

const AffiliatesCall = Spaces.defineCall('affiliateCall', async () => AffiliateService.read());

export default PresentationContainer({
  component: Affiliates,
  middleware: [spacesMiddleware([AffiliatesCall])],
  controller: function AffiliatesController({ spaces }) {
    const [selectedAffiliate, setSelectedAffiliate] = useState();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
      Spaces.call(AffiliatesCall);
    }, []);

    const { data: affiliates } = spaces.affiliateCall;

    const handleDeleteAffiliatePress = async (affiliate) => {
      await AffiliateService.update(affiliate.id, { active: false });
      Spaces.call(AffiliatesCall);
    };
    const handleUpdateAffiliatePress = async (affiliate) => {
      setSelectedAffiliate(affiliate);
    };

    const handleCloseAffiliateModal = () => setSelectedAffiliate(undefined);
    const handleCreateAffiliatePress = () => setSelectedAffiliate({});
    const handlePlanSubmit = async ({ code, user, plan }) => {
      try {
        if (!selectedAffiliate.id) {
          await AffiliateService.create({ code, planId: plan.id, userId: user.id });
        } else {
          await AffiliateService.update(selectedAffiliate.id, { code, planId: plan.id });
        }

        setSelectedAffiliate(undefined);
        Spaces.call(AffiliatesCall);
      } catch (e) {
        setErrors([e.message]);
      }
    };
    const handleErrorsClose = () => {
      setErrors([]);
    };

    return {
      affiliates: affiliates ?? [],
      selectedAffiliate,
      errors,
      handleDeleteAffiliatePress,
      handleUpdateAffiliatePress,
      handleCloseAffiliateModal,
      handleCreateAffiliatePress,
      handlePlanSubmit,
      handleErrorsClose,
    };
  },
});
