import { getAxios } from './service.helpers';

export default class UserService {
  static read = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/user`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: user, error } = data;

    if (!success) {
      throw error;
    }

    return user;
  };

  static readAll = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/user/all`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: users, error } = data;

    if (!success) {
      throw error;
    }

    return users;
  };

  static update = async updates => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.patch(`/user`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: updatedUser, error } = data;

    if (!success) {
      throw error;
    }

    return updatedUser;
  };

  static delete = async user => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.delete(`/user/${user.id}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw error;
    }

    return true;
  };

  static checkEmail = async email => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/user/check-email`, { email });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const {
      success,
      data: { available },
      error
    } = data;

    if (!success) {
      throw error;
    }

    return available;
  };
}
