import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

const AdminLogs = ({ controller }) => (
  <div>
    {controller.isLoading && <CircularProgress size={50} />}
    {controller.error && <pre>{controller.error.message}</pre>}
    {!controller.isLoading &&
      controller.logs.map(log => (
        <>
          <div key={log.id}>
            <pre>{JSON.stringify(log, null, 2)}</pre>
          </div>
          <Divider />
        </>
      ))}
  </div>
);

export default AdminLogs;
