import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const PercentField = ({ value, onChange, ...restOfProps }) => (
  <TextField
    {...restOfProps}
    type="number"
    value={(parseFloat(value) * 100).toString()}
    onChange={e =>
      onChange &&
      onChange({
        ...e,
        target: {
          ...e.target,
          value: e.target.value ? (parseFloat(e.target.value) / 100).toString() : null
        }
      })
    }
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>
    }}
  />
);

export default PercentField;
