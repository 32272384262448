import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';

import './ResetPassword.styles.scss';

const ResetPassword = ({ controller }) => (
  <div className="ForgotPassword">
    <div className="overlay" />

    <Card className="form-container">
      <CardContent>
        {!controller.success && (
          <>
            <div className="form-control">
              <Typography align="center">Enter your new password</Typography>
            </div>

            <div className="form-control">
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                value={controller.email}
                onChange={controller.onEmailChange}
                error={!!controller.errors.email}
                helperText={(controller.errors?.email || []).join(', ')}
              />
            </div>

            <div className="form-control">
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                value={controller.password}
                onChange={controller.onPasswordChange}
                error={!!controller.errors.password}
                helperText={(controller.errors?.password || []).join(', ')}
              />
            </div>

            <div className="form-control">
              <TextField
                label="Password Confirm"
                type="password"
                variant="outlined"
                value={controller.confirm}
                onChange={controller.onConfirmChange}
                error={!!controller.errors.confirm}
                helperText={(controller.errors?.confirm || []).join(', ')}
              />
            </div>

            <div className="form-control actions">
              <Button
                variant="contained"
                color="primary"
                onClick={controller.onChangePasswordPress}
              >
                Change Password
              </Button>
            </div>

            {controller.errors.form && (
              <Alert severity="error" variant="filled">
                {controller.errors.form.join(', ')}
              </Alert>
            )}
          </>
        )}
        {controller.success && (
          <div className="success-group">
            <div className="form-control">
              <Typography align="center">Password reset successful</Typography>
            </div>

            <div className="form-control">
              <Button onClick={controller.onLoginPress} variant="contained" color="primary">
                Login
              </Button>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  </div>
);

export default ResetPassword;
