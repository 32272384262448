import { useQuery } from 'react-query';
import PresentationContainer from 'react-presentation-container';

import ConfigService from '@/services/config.service';
import CollectionService from '@/services/collection.service';

import HomeConfig from './HomeConfig.component';

export default PresentationContainer({
  component: HomeConfig,
  controller: function HomeConfigController() {
    const {
      isLoading: configLoading,
      error: configError,
      data: config = {},
      refetch: refetchConfig,
    } = useQuery('ConfigService.read("home")', () => ConfigService.read('home'));

    const {
      isLoading: collectionsLoading,
      error: collectionsError,
      data: collections = [],
    } = useQuery('CollectionService.read()', () => CollectionService.read());

    const titleCollections = (config.titleCollections || []).reduce((prev, titleCollection) => {
      const collection = collections.find(
        (collection) => collection.id === titleCollection?.collectionId
      );

      if (!collection) {
        return prev;
      }

      return [...prev, { ...collection, displayOrder: titleCollection.displayOrder }];
    }, []);

    return { titleCollections, collections };
  },
});
