import { getAxios } from './service.helpers';

export default class CheckoutService {
  static read = async sessionId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/checkout/${sessionId}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: session = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return session;
  };

  static create = async sessionData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/checkout`, sessionData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: session = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return session;
  };

  static update = async (sessionId, sessionData) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.patch(`/checkout/${sessionId}`, sessionData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: session = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return session;
  };

  static createUser = async userData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/checkout/create-user`, userData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: user, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return user;
  };

  static createSubscription = async subscriptionData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(
      `/checkout/create-subscription`,
      subscriptionData
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: user, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return user;
  };
}
