import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Image = ({ controller }) => (
  <div className="Image">
    {controller.isVisible && <img src={controller.source} />}
    {!controller.isVisible && <CircularProgress />}
  </div>
);

export default Image;
