import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { v4 as uuid } from 'uuid';

import RandomColor from 'randomcolor';

import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import clamp from 'lodash/clamp';

const colors = RandomColor({ count: 100, luminosity: 'dark' });

const drawChart = (
  id,
  { type, data, x, y, domain, range, margin = { top: 20, right: 30, bottom: 30, left: 40 } }
) => {
  const containerElement = document.getElementById(id);
  const { clientHeight: height, clientWidth: width } = containerElement;

  containerElement.innerHTML = '';

  const [domainStart, domainEnd] = domain;
  const [rangeStart, rangeEnd] = range;

  const derivedXTickCount = x.tickCount || data.length;
  const derivedYTickCount = y.tickCount || rangeEnd;
  const limitedYTickCount = y.tickCountBounds
    ? clamp(derivedYTickCount, ...y.tickCountBounds)
    : derivedYTickCount;

  const chartHeight = height - margin.top - margin.bottom;

  const xScale = d3[x.scaleType]()
    .domain([x.format(domainStart), x.format(domainEnd)])
    .range([margin.left, width - margin.right]);

  const yScale = d3[y.scaleType]()
    .domain([y.format(rangeStart), y.format(rangeEnd)])
    .nice()
    .range([height - margin.bottom, margin.top]);

  const xAxis = (g) =>
    g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(
        d3.axisBottom(xScale).ticks(derivedXTickCount).tickFormat(x.formatTick).tickSizeOuter(0)
      );

  const yAxis = (g) =>
    g
      .attr('transform', `translate(${margin.left},0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(limitedYTickCount)
          .tickSize(-width + margin.right + margin.left)
      )
      .call((g) =>
        g
          .selectAll('.tick:not(:first-of-type)')
          .select('line')
          .attr('stroke', '#ccc')
          .attr('stroke-dasharray', '2,10')
      )
      .call((g) => g.selectAll('.tick').select('text').attr('font-size', '1.4em').attr('x', '-10'));

  const svg = d3.select(`#${id}`).append('svg').attr('viewBox', [0, 0, width, height]);

  svg.append('g').call(xAxis);

  svg.append('g').call(yAxis);

  if (type === 'bar') {
    const barWidth =
      x.scaleType === 'scaleBand' ? xScale.bandwidth() : (width / derivedXTickCount) * 0.7;

    const [firstPoint] = data;

    if (!firstPoint) return;

    if (isArray(y.get(firstPoint))) {
      const barCount = y.get(firstPoint).length;

      const groupScale = d3
        .scaleBand()
        .domain([0, barCount - 1])
        .range([0, barWidth])
        .padding([0.05]);

      svg
        .append('g')
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr('transform', (d) => {
          const value = x.get(d);
          const formattedValue = x.format(value);
          const scaledValue = xScale(formattedValue) || 0;
          const xPos = scaledValue + (x.scaleType === 'scaleBand' ? 0 : -(barWidth / 2));

          return `translate(${xPos}, 0)`;
        })
        .selectAll('rect')
        .data((d) => y.get(d).map((value, i) => ({ x: i, y: value })))
        .enter()
        .append('rect')
        .attr('fill', (d, i) => colors[i])
        .attr('x', (d) => {
          return groupScale(d.x);
        })
        .attr('y', (d) => yScale(y.format(d.y)))
        .attr('height', (d) => yScale(y.format(rangeStart)) - yScale(y.format(d.y)))
        .attr('width', groupScale.bandwidth());
    } else {
      const bars = svg
        .append('g')
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', (d) => {
          const scaledXValue = xScale(x.format(x.get(d)) || 0);
          const adaptedXValue =
            scaledXValue + (x.scaleType === 'scaleBand' ? margin.left : -(barWidth / 2));
          const scaledYValue = yScale(y.format(y.get(d)));

          return `translate(${adaptedXValue}, ${scaledYValue})`;
        });

      bars
        .append('rect')
        .attr('height', (d) => yScale(y.format(rangeStart)) - yScale(y.format(y.get(d))))
        .attr('width', barWidth)
        .attr('fill', (d, i) => colors[i]);

      bars
        .append('svg')
        .attr('overflow', 'visible')
        .attr('height', (d) => yScale(y.format(rangeStart)) - yScale(y.format(y.get(d))))
        .attr('width', barWidth)
        .append('text')
        .style('fill', (d) => {
          const barHeight = yScale(y.format(rangeStart)) - yScale(y.format(y.get(d)));

          return chartHeight - barHeight > 24 ? 'black' : 'white';
        })
        .text((d) => y.get(d))
        .attr('x', '50%')
        .attr('font-size', 12)
        .attr('y', (d) => {
          const barHeight = yScale(y.format(rangeStart)) - yScale(y.format(y.get(d)));

          return chartHeight - barHeight > 24 ? -16 : '50%';
        })
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle');
    }
  }

  if (type === 'line') {
    const [color] = colors;

    const xPos = (d, i) => {
      return xScale(x.format(x.get(d))) + margin.left / 2;
    };

    const yPos = (d, i) => {
      return yScale(y.format(y.get(d)));
    };

    svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', color)
      .attr('stroke-width', 3)
      .attr('d', d3.line().x(xPos).y(yPos));

    svg
      .append('g')
      .selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', xPos)
      .attr('cy', yPos)
      .attr('r', 5)
      .attr('fill', color);
  }
};

export default function Chart(props) {
  const chartId = `ID${uuid()}`;
  const chartProps = pick(props, ['type', 'data', 'x', 'y', 'domain', 'range']);

  useEffect(() => {
    drawChart(chartId, chartProps);
  }, [chartProps]);

  return <div id={chartId} style={{ height: '100%' }} />;
}
