import { getAxios } from './service.helpers';

export default class LogService {
  static read = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get('/logs');

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: products, error } = data;

    if (!success) {
      throw error;
    }

    return products;
  };
}
