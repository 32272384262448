export const SCREENS = {
  DEFAULT: undefined,
  OVERVIEW: 'overview',
  EXERCISES: 'exercises',
  EXERCISE_CONFIG: 'exercises-config',
  ADMIN_LOGS: 'admin-logs',
  USER_LIST: 'user-list',
  AFFILIATE_PLANS: 'affiliate-plans',
  AFFILIATES: 'affiliates',
  CHECKOUT_SESSIONS: 'checkout-sessions',
  USER_SESSIONS: 'user-sessions',
  FUNNELS: 'funnels',
  COLLECTIONS: 'collections',
  INFORMATION_ITEMS: 'information-items',
  HOME_CONFIG: 'home-config'
};
