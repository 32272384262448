import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';

import AuthService from '@/services/auth.service';

import ChangePassword from './ChangePassword.component';

export default PresentationContainer({
  component: ChangePassword,
  controller: function ChangePasswordController() {
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onOldPasswordChange = useCallback(e => setOldPassword(e.target.value), []);
    const onPasswordChange = useCallback(e => setPassword(e.target.value), []);
    const onConfirmChange = useCallback(e => setConfirm(e.target.value), []);

    const onCancelEditPress = () => {
      setOldPassword('');
      setPassword('');
      setConfirm('');
      setIsEditModeActive(false);
    };

    const onChangePasswordPress = useCallback(async () => {
      setErrors({});
      setIsSubmitting(true);

      const formErrors = {};

      if (!oldPassword) {
        formErrors.oldPassword = ['Required'];
      }

      if (!password) {
        formErrors.password = ['Required'];
      }

      if (!confirm) {
        formErrors.confirm = ['Required'];
      }

      if (password !== confirm) {
        formErrors.password = [...(formErrors.password || []), 'Passwords do not match'];
        formErrors.confirm = [...(formErrors.confirm || []), 'Passwords do not match'];
      }

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setIsSubmitting(false);
        return;
      }

      try {
        await AuthService.passwordChange(oldPassword, password);
        setSnackbar('Password change success!');
        onCancelEditPress();
      } catch (e) {
        setErrors({ form: [e.message] });
      }
      setIsSubmitting(false);
    });

    const onEditPress = () => setIsEditModeActive(true);

    const onSnackbarClose = () => setSnackbar(undefined);

    return {
      oldPassword,
      password,
      confirm,
      isEditModeActive,
      errors,
      snackbar,
      isSubmitting,
      onSnackbarClose,
      onCancelEditPress,
      onEditPress,
      onOldPasswordChange,
      onPasswordChange,
      onConfirmChange,
      onChangePasswordPress
    };
  }
});
