import React from 'react';
import isEqual from 'lodash/isEqual';

import SpacesCore from './SpacesCore';

export default (spaces = []) => (Component) => {
  return class WithSpace extends React.Component {
    constructor(props) {
      super(props);

      this.state = SpacesCore.getState(spaces);
    }

    _onUpdate = (space, newState) =>
      this.setState((prev) => ({...prev, [space]: newState}));

    componentDidMount() {
      SpacesCore.subscribe(this._onUpdate, spaces);
    }

    componentWillUnmount() {
      SpacesCore.unsubscribe(this._onUpdate, spaces);
    }

    shouldComponentUpdate(nextProps, nextState) {
      return !isEqual(nextProps, this.props) || !isEqual(this.state, nextState);
    }

    render() {
      return <Component {...this.props} spaces={this.state} />;
    }
  };
};
