import React from 'react';

import './Image.styles.scss';

const Image = ({ controller, src, alt }) => (
  <div className={['Image', ...(controller.isLandscape ? ['landscape'] : ['portrait'])].join(' ')}>
    <img alt={alt} src={src} ref={controller.imageRef} onLoad={controller.onImageLoad} />
  </div>
);

export default Image;
