import React from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import './Dialog.styles.scss';
import Modal from '../Modal';

const Dialog = ({
  title,
  onClose,
  type,
  actions,
  prompt,
  onPromptChange,
  promptLabel,
  ...restOfProps
}) => (
  <Modal {...restOfProps} onClose={onClose} className="Dialog">
    {title && <Modal.Header title={title} onClose={onClose} />}
    {type === 'prompt' && (
      <div className="prompt-container">
        <TextField
          variant="outlined"
          className="prompt"
          label={promptLabel}
          value={prompt}
          onChange={onPromptChange}
        />
      </div>
    )}
    {actions?.length > 0 && (
      <div className="actions">
        {actions.map(({ label, onPress, ...restOfProps }) => (
          <Button
            {...restOfProps}
            onClick={onPress}
            className={['action', ...(restOfProps.className ? [restOfProps.className] : [])].join(
              ' '
            )}
          >
            {label}
          </Button>
        ))}
      </div>
    )}
  </Modal>
);

export default Dialog;
