import React, { useState, useEffect } from 'react';
import Form from 'react-forms-system';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

import Modal from '@/components/Modal';
import TextInputControl from '@/components/TextInputControl';
import PercentControl from '@/components/PercentControl';
import CheckBoxControl from '@/components/CheckBoxControl';

import { required, percent, whenValue } from '@/core/validators';

import './PlanModal.styles.scss';

const TrialPeriodControl = ({
  value,
  onChange,
  onTouch,
  validationState,
  errorMessages = {},
  label
}) => {
  const { dirty, pending, touched, valid, ...restOfValidation } = validationState;

  const hasError = (dirty || touched) && !valid;

  const errors = Object.keys(restOfValidation).reduce((prev, key) => {
    if (!restOfValidation[key]) {
      return [...prev, errorMessages[key]];
    }
    return prev;
  }, []);

  const [trial = '', unit = 'none'] = value || ['', 'none'];

  const handleChange = ([trial, unit]) => {
    const trialValue = (() => {
      if (!trial || trial === '') {
        return null;
      }

      return trial;
    })();

    const unitValue = (() => {
      if (!unit || unit === 'none') {
        return null;
      }
      return unit;
    })();

    const newValue = (() => {
      if (!unitValue && !trialValue) {
        return null;
      }

      return [trialValue, unitValue];
    })();

    onChange && onChange(newValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          className="trial"
          variant="outlined"
          type="number"
          value={trial}
          label={label}
          style={{ flex: 1, marginRight: '4px' }}
          onChange={e => handleChange([e.target.value, unit])}
          onBlur={onTouch}
          error={hasError}
        />
        <FormControl
          variant="outlined"
          className="unit"
          style={{ flex: 1, marginLeft: '4px' }}
          error={hasError}
        >
          <InputLabel id="trial-unit-label">Unit</InputLabel>
          <Select
            labelId="trial-unit-label"
            id="trial-unit"
            label="Unit"
            value={unit}
            onChange={e => handleChange([trial, e.target.value])}
            onTouch={onTouch}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="weeks">Weeks</MenuItem>
            <MenuItem value="months">Months</MenuItem>
          </Select>
        </FormControl>
      </div>
      {hasError && <FormHelperText error>{errors.join(',')}</FormHelperText>}
    </div>
  );
};

function PlanModal({ plan, onClose, onSubmit }) {
  const [formState, setFormState] = useState({ values: plan });

  useEffect(() => {
    setFormState(prev => ({ ...prev, values: plan }));
  }, [plan]);

  const handleFormStateChange = state => {
    setFormState(state);
  };

  const handleSubmitPress = () => {
    if (!formState.validationState.valid) {
      return;
    }

    onSubmit && onSubmit(formState.values);
  };

  return (
    <Modal visible onClose={onClose} className="PlanModal">
      <Modal.Header onClose={onClose} />

      <Form onStateChange={handleFormStateChange} onSubmit={handleSubmitPress}>
        <div className="content form">
          <div className="group">
            <div className="control">
              <Form.Control
                name="name"
                component={TextInputControl}
                value={formState?.values.name}
                label="Plan Name"
                variant="outlined"
                errorMessages={{ required: 'Required' }}
                validators={{ required }}
              />
            </div>
          </div>

          <Divider />

          <div className="group">
            <div className="control">
              <Typography>Affiliate</Typography>
            </div>

            <div className="control">
              <Form.Control
                name="affiliateRate"
                component={PercentControl}
                value={formState?.values.affiliateRate}
                label="Share Rate"
                variant="outlined"
                errorMessages={{ percent: 'Value must be from 0% - 100%' }}
                validators={{ percent: whenValue(percent(0, 100)) }}
              />
            </div>
          </div>

          <Divider />

          <div className="group">
            <div className="control">
              <Typography>Affiliate Members</Typography>
            </div>

            <div className="control">
              <Form.Control
                name="affiliateMemberExempt"
                component={CheckBoxControl}
                value={formState?.values.affiliateMemberExempt || false}
                label="Exempt"
              />
            </div>

            <div className="control">
              <Form.Control
                name="affiliateMemberDiscount"
                component={PercentControl}
                value={
                  formState?.values.affiliateMemberExempt
                    ? null
                    : formState?.values.affiliateMemberDiscount
                }
                label="Discount"
                variant="outlined"
                errorMessages={{ percent: 'Value must be from 0% - 100%' }}
                validators={{ percent: whenValue(percent(0, 100)) }}
              />
            </div>

            <div className="control trial">
              <Form.Control
                name="affiliateMemberTrial"
                component={TrialPeriodControl}
                value={
                  formState?.values.affiliateMemberExempt
                    ? null
                    : formState?.values.affiliateMemberTrial
                }
                label="Trial"
                variant="outlined"
                errorMessages={{ hasBoth: 'Must select trial and unit or neither' }}
                validators={{ hasBoth: whenValue(([trial, unit]) => !!trial && !!unit) }}
              />
            </div>
          </div>

          <Divider />

          <div className="group">
            <div className="control">
              <Button type="submit" color="primary" variant="contained">
                {`${plan.id ? 'Update' : 'Create'} Plan`}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default PlanModal;
