import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';

import AuthService from '@/services/auth.service';

import routerMiddleware from '@/core/router-middleware';

import ROUTES from '@/routes';

import ResetPassword from './ResetPassword.component';

export default PresentationContainer({
  component: ResetPassword,
  middleware: [routerMiddleware()],
  controller: function ResetPasswordController({ history, match }) {
    const { code } = match.params;

    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [errors, setErrors] = useState({});

    const onEmailChange = useCallback(e => setEmail(e.target.value));
    const onPasswordChange = useCallback(e => setPassword(e.target.value));
    const onConfirmChange = useCallback(e => setConfirm(e.target.value));
    const onLoginPress = useCallback(() => history.replace(ROUTES.LOGIN));

    const onChangePasswordPress = useCallback(async () => {
      const formErrors = {};

      if (!email) {
        formErrors.email = ['Email required'];
      }

      if (!password) {
        formErrors.password = ['Password required'];
      }

      if (!confirm) {
        formErrors.confirm = ['Password confirm required'];
      }

      if (password !== confirm) {
        formErrors.password = [...(formErrors.password || []), 'Passwords do not match'];
        formErrors.confirm = [...(formErrors.confirm || []), 'Passwords do not match'];
      }

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }

      try {
        await AuthService.passwordRecoverConfirm(email, password, code);
        setSuccess(true);
      } catch (e) {
        setErrors({ form: [e.message] });
      }
    });

    return {
      success,
      email,
      password,
      confirm,
      errors,
      onLoginPress,
      onEmailChange,
      onPasswordChange,
      onConfirmChange,
      onChangePasswordPress
    };
  }
});
