import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Nav from '@/components/Nav';

import ChangePassword from './components/ChangePassword';
import UpdateUser from './components/UpdateUser';
import ChangePaymentMethod from './components/ChangePaymentMethod';
import ChangePlan from './components/ChangePlan';
import DeleteAccount from './components/DeleteAccount';

import './Account.styles.scss';

const Account = ({ controller }) => (
  <div className="Account">
    <title>My Account</title>
    <Nav>
      <div className="content">
        <div className="liner">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <UpdateUser />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ChangePassword />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ChangePlan locale={controller.locale} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ChangePaymentMethod locale={controller.locale} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <DeleteAccount />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Divider className="footer-div" />

          <div className="footer">
            <Typography>Looking for the app?</Typography>

            <div className="app-store-buttons">
              <a
                href="https://apps.apple.com/us/app/fastercise/id1545070683?itsct=apps_box_badge&amp;itscg=30200"
                style={{
                  display: 'inline-block',
                  overflow: 'hidden'
                }}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1620259200&h=f6f2334ae1684d0437a2e7cf905963f4"
                  alt="Download on the App Store"
                  style={{ borderRadius: '13px', width: '128px' }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.fastercise.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={{ borderRadius: '13px', width: '148px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Nav>
  </div>
);

export default Account;
