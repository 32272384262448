import React from 'react';
import Form from 'react-forms-system';

import Button from '@material-ui/core/Button';

import Modal from '@/components/Modal';
import SearchFieldControl from '@/components/SearchFieldControl';
import TextInputControl from '@/components/TextInputControl';

import { required } from '@/core/validators';

const AffiliateModal = ({ controller, onClose, affiliate }) => (
  <Modal visible onClose={onClose} className="AffiliateModal">
    <Modal.Header onClose={onClose} />
    <Form onStateChange={controller.handleFormChange} onSubmit={controller.handleSubmit}>
      <div className="content form">
        <div className="group">
          <div className="control">
            <Form.Control
              name="code"
              label="Code"
              variant="outlined"
              value={controller.formState?.values.code}
              component={TextInputControl}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <div className="group">
          <div className="control">
            <Form.Control
              name="user"
              label="User"
              variant="outlined"
              disabled={controller.disableUser}
              value={controller.formState?.values.user}
              component={SearchFieldControl}
              getOptions={controller.handleGetUsers}
              getOptionLabel={controller.handleGetUserLabel}
              getOptionSelected={controller.handleGetUserSelected}
              defaultValue={null}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>

          <div className="control">
            <Form.Control
              name="plan"
              label="Plan"
              variant="outlined"
              value={controller.formState?.values.plan}
              component={SearchFieldControl}
              getOptions={controller.handleGetPlans}
              getOptionLabel={controller.handleGetPlanLabel}
              getOptionSelected={controller.handleGetPlanSelected}
              defaultValue={null}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>
        </div>

        <div className="group">
          <Button type="submit" color="primary" variant="contained">
            {`${affiliate.id ? 'Update' : 'Create'} Affiliate`}
          </Button>
        </div>
      </div>
    </Form>
  </Modal>
);

export default AffiliateModal;
