export const CARD_OPTIONS = {
  style: {
    base: {
      color: '#A9EFF0',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':-webkit-autofill': {
        color: '#A9EFF0'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
      ':-webkit-autofill': {
        color: '#fa755a'
      }
    }
  }
};

export const PAGE_SOURCE = {
  WEB: 'web',
  APP: 'app'
};

export const PAYMENT_METHOD = {
  CARD: 0,
  BANCONTACT: 1
};

export const SCREEN = {
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS'
};
