import { useQuery, useMutation } from 'react-query';
import PresentationContainer from 'react-presentation-container';

import get from 'lodash/get';
import pull from 'lodash/pull';

import ConfigService from '@/services/config.service';
import ExerciseService from '@/services/exercise.service';

import Array from '@/core/Array';

import ExerciseConfig from './ExerciseConfig.component';

export default PresentationContainer({
  component: ExerciseConfig,
  controller: function ExerciseConfigController() {
    const {
      isLoading: configLoading,
      error: configError,
      data: config,
      refetch: refetchConfig
    } = useQuery('exercise_config', () => ConfigService.read('exercise'));

    const { isLoading: isConfigMutating, mutate: mutateConfig } = useMutation(
      updatedConfig => ConfigService.update('exercise', updatedConfig),
      {
        onSuccess: refetchConfig
      }
    );

    const {
      isLoading: exercisesLoading,
      error: exercisesError,
      data: exercises = []
    } = useQuery('exercises', () => ExerciseService.read());

    const {
      isLoading: labelsLoading,
      error: labelsError,
      data: labels = []
    } = useQuery('exercise_labels', () => ExerciseService.labels());

    const availableLabels = labels.filter(
      label => !get(config, 'labelPriority', []).find(id => label.id === id)
    );

    const isLabelPickerEnabled = availableLabels.length > 0;

    const onRecommendedChange = async e => {
      mutateConfig({ ...config, recommended: e.target.value });
    };

    const onLabelDownPress = labelId => () => {
      const { labelPriority = [] } = config || {};

      const labelIndex = labelPriority.indexOf(labelId);

      const newPriority = Array.swap(labelPriority, labelIndex, labelIndex + 1);

      mutateConfig({ ...config, labelPriority: newPriority });
    };

    const onLabelUpPress = labelId => () => {
      const { labelPriority = [] } = config || {};

      const labelIndex = labelPriority.indexOf(labelId);

      const newPriority = Array.swap(labelPriority, labelIndex, labelIndex - 1);

      mutateConfig({ ...config, labelPriority: newPriority });
    };

    const onLabelDeletePress = labelId => () => {
      const { labelPriority = [] } = config || {};

      const newPriority = pull(labelPriority, labelId);

      mutateConfig({ ...config, labelPriority: newPriority });
    };

    const onAddLabelChange = e => {
      const { labelPriority = [] } = config || {};

      mutateConfig({ ...config, labelPriority: [...labelPriority, e.target.value] });
    };

    return {
      isLoading: isConfigMutating || configLoading || exercisesLoading || labelsLoading,
      error: configError || exercisesError || labelsError,
      exercises,
      config,
      availableLabels,
      isLabelPickerEnabled,
      labels,
      onRecommendedChange,
      onLabelDownPress,
      onLabelUpPress,
      onLabelDeletePress,
      onAddLabelChange
    };
  }
});
