import React from 'react';

import Nav from '@/components/Nav';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Exercises from './components/Exercises';
import ExerciseConfig from './components/ExerciseConfig';
import AdminLogs from './components/AdminLogs';
import UserList from './components/UserList';
import AffiliatePlans from './components/AffiliatePlans';
import Affiliates from './components/Affiliates';
import Overview from './components/Overview';
import CheckoutSessions from './components/CheckoutSessions';
import UserSessions from './components/UserSessions';
import Funnels from './components/Funnels';
import Collections from './components/Collections';
import InformationItems from './components/InformationItems';
import HomeConfig from './components/HomeConfig';

import { SCREENS } from './Admin.constants';
import './Admin.styles.scss';

const COMPONENTS = {
  [SCREENS.OVERVIEW]: {
    title: 'Overview',
    component: Overview
  },
  [SCREENS.USER_LIST]: {
    title: 'UserList',
    component: UserList
  },
  [SCREENS.FUNNELS]: {
    title: 'Funnels',
    component: Funnels
  },
  [SCREENS.CHECKOUT_SESSIONS]: {
    title: 'CheckoutSessions',
    component: CheckoutSessions
  },
  [SCREENS.USER_SESSIONS]: {
    title: 'UserSessions',
    component: UserSessions
  },
  [SCREENS.EXERCISES]: {
    title: 'Exercises',
    component: Exercises
  },
  [SCREENS.EXERCISE_CONFIG]: {
    title: 'ExerciseConfig',
    component: ExerciseConfig
  },
  [SCREENS.ADMIN_LOGS]: {
    title: 'AdminLogs',
    component: AdminLogs
  },
  [SCREENS.AFFILIATE_PLANS]: {
    title: 'AffiliatePlans',
    component: AffiliatePlans
  },
  [SCREENS.AFFILIATES]: {
    title: 'Affiliates',
    component: Affiliates
  },
  [SCREENS.COLLECTIONS]: {
    title: 'Collections',
    component: Collections
  },
  [SCREENS.INFORMATION_ITEMS]: {
    title: 'Information Items',
    component: InformationItems
  },
  [SCREENS.HOME_CONFIG]: {
    title: 'Home Config',
    component: HomeConfig
  }
};

const ContentComponent = ({ visible, component: Component, ...restOfProps }) => {
  if (!visible) {
    return null;
  }

  return <Component {...restOfProps} />;
};

const Admin = ({ controller }) => (
  <div className="Admin">
    <title>Admin</title>
    <Nav>
      <div className="Admin-content">
        <div className="liner">
          <div className="picker">
            {!!controller.selectedScreen && (
              <IconButton onClick={controller.onBackPress}>
                <ChevronLeftIcon />
              </IconButton>
            )}
            {!controller.selectedScreen &&
              Object.keys(COMPONENTS).map(key => (
                <ListItem button onClick={controller.onPickerPress(key)}>
                  <ListItemText primary={COMPONENTS[key].title} />
                </ListItem>
              ))}
          </div>
          <div className="screens">
            <div className="liner">
              {Object.keys(COMPONENTS).map(key => (
                <ContentComponent
                  visible={controller.selectedScreen === key}
                  component={COMPONENTS[key].component}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Nav>
  </div>
);

export default Admin;
