import { getAxios } from './service.helpers';

import AuthService from './auth.service';

export default class ExerciseService {
  static create = async exercise => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/exercise', exercise);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: exerciseData, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return exerciseData;
  };

  static read = async id => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/exercise${id ? `/${id}` : ''}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: exerciseData, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return exerciseData;
  };

  static labels = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get('/exercise/labels');

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: labels, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return labels;
  };

  static getPresignedUrl = async contentType => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      `/exercise/create-presigned?contentType=${contentType}`
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: presignedUrl, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return presignedUrl;
  };

  static uploadFile = async (file, type) => {
    const { url, key } = await this.getPresignedUrl(type);

    const { status, statusText } = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': type
        // reportProgress: true,
      }
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    return key;
  };

  static update = async (id, updates) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.patch(`/exercise/${id}`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: exerciseData, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return exerciseData;
  };

  static videoData = async (exerciseId, bitrate) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      `/exercise/video-data/${exerciseId}${bitrate ? `?bitrate=${bitrate}` : ''}`
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: videoData, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return videoData;
  };

  static updateBitrates = async exerciseId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/exercise/update-bitrates', {
      exerciseId
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return true;
  };
}
