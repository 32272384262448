import { getAxios } from './service.helpers';
export default class ConfigService {
  static read = async configName => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/config/${configName}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: config = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return config;
  };

  static update = async (configName, configData) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.put(`/config/${configName}`, configData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: config = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return config;
  };
}
