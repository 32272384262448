import React from 'react';
import MDEditor from '@uiw/react-md-editor';

import get from 'lodash/get';

import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import UndoTwoToneIcon from '@material-ui/icons/UndoTwoTone';
import PublishIcon from '@material-ui/icons/Publish';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import onChangeText from '@/core/onChangeText';

import theme from '@/theme/material-theme';

import LoadingOverlay from '@/components/LoadingOverlay';
import Image from '@/components/Image';
import Video from '@/components/Video';
import FileUploader from '@/components/FileUploader';

const Actions = ({ title, onUploadPress }) => (
  <div className="actions">
    <div className="title-wrapper">
      <Typography variant="body1">{title}</Typography>
    </div>
    <div className="buttons">
      <IconButton onClick={onUploadPress}>
        <PublishIcon />
      </IconButton>
    </div>
  </div>
);

const Exercise = ({ controller }) => (
  <div className="Admin-Exercises-Exercise">
    <div className="fab-actions">
      <Fab color="secondary" onClick={controller.handleUndoPress} disabled={!controller.canSave}>
        <UndoTwoToneIcon />
      </Fab>

      <Fab color="secondary" onClick={controller.handleSavePress} disabled={!controller.canSave}>
        <SaveTwoToneIcon />
      </Fab>
    </div>
    <div className="liner">
      <FileUploader ref={controller.fileUploadRef} onFile={controller.handleFile} />

      <div className="form-control">
        <TextField
          variant="outlined"
          label=" Exercise Name"
          value={get(controller, 'exercise.name', '')}
          onChange={onChangeText(controller.handleExerciseUpdate('name'))}
        />
      </div>

      <div className="form-control">
        <Autocomplete
          multiple
          id="tags-filled"
          options={controller.availableLabels}
          value={get(controller, 'labels')}
          onChange={(e, updatedLabels) => controller.handleExerciseUpdate('labels')(updatedLabels)}
          freeSolo
          getOptionSelected={(label, value) => label?.id === value?.id}
          getOptionLabel={(label) => label?.name}
          renderOption={(label) => {
            return (
              <Typography>
                {`${label.name}${label.attributes ? ` (${label.attributes})` : ''}`}
              </Typography>
            );
          }}
          renderTags={(value, getTagProps) =>
            value.map((label, index) => {
              const props = getTagProps({ index });
              return (
                <div
                  className={['label-chip', ...(label.attributes ? ['attributes'] : [])].join(' ')}
                >
                  <Typography>
                    {`${label.name}${label.attributes ? ` (${label.attributes})` : ''}`}
                  </Typography>
                  <IconButton
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => controller.handleChangeLabelVisibility(label)}
                  >
                    {label.visible ? <VisibilityTwoToneIcon /> : <VisibilityOffTwoToneIcon />}
                  </IconButton>
                  <IconButton size="small" onClick={props.onDelete}>
                    <CancelTwoToneIcon />
                  </IconButton>
                </div>
              );
            })
          }
          renderInput={(params) => <TextField variant="outlined" {...params} label="Labels" />}
        />
      </div>

      <Grid container spacing={3} className="media">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="media-wrap">
            <Paper className="img-paper" elevation={5}>
              {get(controller, 'exercise.thumb') && (
                <Image
                  alt={get(controller, 'exercise.name')}
                  src={get(controller, 'exercise.thumb.base64', get(controller, 'exercise.thumb'))}
                />
              )}
              <Actions title="Thumbnail" onUploadPress={controller.handleUploadThumbPress} />
            </Paper>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="media-wrap">
            <Paper className="img-paper" elevation={5}>
              {get(controller, 'exercise.executeVideo') && (
                <Video
                  src={get(
                    controller,
                    'exercise.executeVideo.base64',
                    get(controller, 'exercise.executeVideo'),
                  )}
                />
              )}
              <Actions title="Execute" onUploadPress={controller.handleUploadExecutePress} />
            </Paper>
          </div>

          <div className="bitrate-container">
            {controller.executeBitrates.map((bitrate) => (
              <Chip
                label={bitrate}
                icon={
                  get(controller, 'videoData.inProgress.execute').includes(bitrate) ? (
                    <CircularProgress size={12} />
                  ) : (
                    undefined
                  )
                }
              />
            ))}
          </div>

          <Button variant="contained" onClick={controller.handleUpdateBitratesPress}>
            Update Bitrates
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <div className="media-wrap">
            <Paper className="img-paper" elevation={5}>
              {get(controller, 'exercise.educateVideo') && (
                <Video
                  src={get(
                    controller,
                    'exericse.educateVideo.base64',
                    get(controller, 'exercise.educateVideo'),
                  )}
                />
              )}
              <Actions title="Educate" onUploadPress={controller.handleUploadEducatePress} />
            </Paper>
          </div>

          <div className="bitrate-container">
            {controller.educateBitrates.map((bitrate) => (
              <Chip
                label={bitrate}
                icon={
                  get(controller, 'videoData.inProgress.educate').includes(bitrate) ? (
                    <CircularProgress size={12} />
                  ) : (
                    undefined
                  )
                }
              />
            ))}
          </div>

          <Button variant="contained" onClick={controller.handleUpdateBitratesPress}>
            Update Bitrates
          </Button>
        </Grid>
      </Grid>

      <div className="form-control">
        <MDEditor
          value={get(controller, 'exercise.description')}
          height={500}
          onChange={controller.handleExerciseUpdate('description')}
        />
      </div>
    </div>
    <LoadingOverlay visible={controller.loadingMessage} message={controller.loadingMessage} />
  </div>
);

export default Exercise;
