import { useState, useCallback, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import CountryList from 'country-list';
import QueryString from 'query-string';
import { useQuery } from 'react-query';

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { TryCall } from '@fastercise/common';

import stripeMiddleware from '@/core/stripe.middleware';
import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';
import formatPrice from '@/core/formatPrice';
import useDebounce from '@/core/useDebounce';

import CheckoutService from '@/services/checkout.service';
import StripeService from '@/services/stripe.service';

import ROUTES from '@/routes';

import { SCREEN } from './Checkout.constants';
import Checkout from './Checkout.component';

const readSession = ({ queryKey: [, sessionId] }) => CheckoutService.read(sessionId);
const readTaxInfo = ({ queryKey: [, country, postalCode, priceId, discount] }) =>
  StripeService.calculateTax({ country, postalCode, priceId, discount });

export default PresentationContainer({
  component: Checkout,
  middleware: [stripeMiddleware(), routerMiddleware()],
  controller: function CheckoutController({ history, location }) {
    const { sessionId } = QueryString.parse(location.search);

    const [currentScreen] = useState(SCREEN.ACCOUNT_DETAILS);
    const [errors, setErrors] = useState([]);
    const [isCheckingAccountDetails, setIsCheckingAccountDetails] = useState(false);
    const [accountDetails, setAccountDetails] = useState();

    const { formCountry, formPostalCode } = useDebounce(
      () => ({
        formCountry: accountDetails?.country?.code,
        formPostalCode: accountDetails?.postalCode
      }),
      500
    );

    const { isLoading: isSessionLoading, data: session, error: sessionError } = useQuery(
      ['CheckoutSession.read()', sessionId],
      readSession,
      {
        enabled: !!sessionId
      }
    );

    const { isLoading: isTaxInfoLoading, data: taxInfo } = useQuery(
      [
        'StripeService.calculateTax',
        session?.country || formCountry,
        session?.postalCode || formPostalCode,
        session?.priceId,
        session?.affiliatePlan?.affiliateMemberDiscount ? session?.affiliatePlan?.id : undefined
      ],
      readTaxInfo,
      {
        enabled:
          (!!session?.country || !!formCountry) &&
          (!!session?.postalCode || !!formPostalCode) &&
          !session?.affiliatePlan?.affiliateMemberExempt
      }
    );

    useEffect(() => {
      if (session?.complete) {
        history.replace(routeWithParams(ROUTES.CHECKOUT_COMPLETE));
      }
    }, [session]);

    useEffect(() => {
      if (sessionError) {
        history.replace(routeWithParams(ROUTES.SELECT_PLAN));
      }
    }, [sessionError]);

    const handleErrorsClose = () => setErrors([]);
    const handleGoBackPress = useCallback(
      () =>
        history.replace(
          routeWithParams(ROUTES.SELECT_PLAN, {
            query: { affiliateCode: session?.affiliateCode, sessionId }
          })
        ),
      [session]
    );
    const handleAccountDetailsChange = ({ values }) => setAccountDetails(values);
    const handleAccountDetailsSubmit = async ({ values, validationState }) => {
      if (!validationState.valid) {
        return;
      }

      setIsCheckingAccountDetails(true);

      if (!session.userId) {
        const {
          firstName,
          lastName,
          email,
          country: { code: country },
          postalCode,
          password,
          phone
        } = values;

        const { err: userError } = await TryCall(
          CheckoutService.createUser({
            sessionId,
            firstName,
            lastName,
            email,
            country,
            postalCode,
            password,
            phone
          })
        );

        if (userError) {
          setErrors([userError.message]);
          setIsCheckingAccountDetails(false);
          return;
        }
      }

      const { priceId, affiliateCode } = session;

      const { err: subscriptionError } = await TryCall(
        CheckoutService.createSubscription({
          sessionId,
          priceId,
          affiliateCode
        })
      );

      if (subscriptionError) {
        setErrors([subscriptionError.message]);
        setIsCheckingAccountDetails(false);
        return;
      }

      history.replace(routeWithParams(ROUTES.CHECKOUT_COMPLETE));
    };

    const getCountries = () => sortBy(CountryList.getData(), ['name']);
    const getCountryLabel = ({ name }) => name;
    const getCountrySelected = ({ code: optionId }, { code: valueId }) => optionId === valueId;

    const tax = (taxInfo?.tax || 0) / 100;
    const total = (taxInfo?.total || 0) / 100;
    const currency = get(session, 'price.currency', 'usd');
    const trialDuration =
      get(session, 'affiliatePlan.affiliateMemberTrial') || get(session, 'product.trialDuration');

    return {
      errors,
      session,
      currentScreen,
      product: session && {
        name: get(session, 'product.name'),
        price: get(session, 'price.unit_amount', 0) / 100,
        interval: get(session, 'price.recurring.interval_count'),
        currency: get(session, 'price.currency'),
        trialDuration: get(session, 'product.trialDuration')
      },
      tax: tax && formatPrice(tax, currency),
      total: formatPrice(total, currency),
      trialDuration,
      currency,
      affiliatePlan: session?.affiliatePlan,
      isCheckingAccountDetails,
      isSessionLoading,
      isTaxInfoLoading,
      getCountries,
      getCountryLabel,
      getCountrySelected,
      handleAccountDetailsChange,
      handleAccountDetailsSubmit,
      handleErrorsClose,
      handleGoBackPress
    };
  }
});
