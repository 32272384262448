import { useState, useCallback, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';
import moment from 'moment';

import useDebounce from '@/core/useDebounce';

import ReportService from '@/services/report.service';

import Funnels from './Funnels.component';

export default PresentationContainer({
  component: Funnels,
  controller: function FunnelsController() {
    const [startDate, setStartDate] = useState(
      moment()
        .startOf('week')
        .startOf('day')
    );

    const [endDate, setEndDate] = useState(
      moment()
        .endOf('week')
        .endOf('day')
    );

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(() => search, 500);

    const {
      isLoading: isSignUpFunnelLoading,
      data: signUpFunnel = []
    } = useQuery(
      `ReportService.signUpFunnel(${startDate.unix()}, ${endDate.unix()}, ${debouncedSearch})`,
      () => ReportService.signUpFunnel(startDate.unix(), endDate.unix(), debouncedSearch)
    );

    const handleStartDateChange = useCallback(
      date => {
        const newDate = date.clone().startOf('day');
        setStartDate(newDate);

        if (newDate.isAfter(endDate)) {
          setEndDate(newDate.clone().endOf('day'));
        }
      },
      [endDate]
    );

    const handleEndDateChange = useCallback(
      date => {
        const newDate = date.clone().endOf('day');
        setEndDate(newDate);

        if (newDate.isBefore(startDate)) {
          setStartDate(newDate.clone().startOf('day'));
        }
      },
      [startDate]
    );

    const handleSearchChange = useCallback(e => {
      setSearch(e.target.value);
    });

    return {
      signUpFunnel,
      isSignUpFunnelLoading,
      startDate,
      endDate,
      search,
      handleStartDateChange,
      handleEndDateChange,
      handleSearchChange
    };
  }
});
