import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import ArrowBackTwoToneIcon from '@material-ui/icons/ArrowBackTwoTone';

import PlanCard from '@/components/PlanCard';
import Snackbar from '@/components/Snackbar';

import '@/styles/form.scss';

import AccountDetailsScreen from './components/AccountDetailsScreen';

import './Checkout.styles.scss';
import { SCREEN } from './Checkout.constants';

// TODO Handle errors

const Checkout = ({ controller }) => (
  <div className="Checkout">
    <title>Checkout</title>
    <div className="liner">
      <div className="summary">
        <div className="header">
          <IconButton className="back-icon" onClick={controller.handleGoBackPress}>
            <ArrowBackTwoToneIcon />
          </IconButton>
          <Typography className="back-label">Pick Plan</Typography>
        </div>
        <div className="content">
          <div className="details-header">
            <Typography variant="h5">Subscription Details</Typography>
          </div>

          {controller.product && (
            <div className="plan-card-container">
              <PlanCard
                name={get(controller, 'product.name')}
                price={get(controller, 'product.price')}
                currency={get(controller, 'product.currency')}
                interval="month"
                exempt={get(controller, 'affiliatePlan.affiliateMemberExempt')}
                discount={get(controller, 'affiliatePlan.affiliateMemberDiscount', undefined)}
                trial={controller.trialDuration}
              />
            </div>
          )}

          <div className="totals">
            <>
              <div className="totals-row">
                <Typography>Tax</Typography>
                <Typography>
                  {!!controller.isTaxInfoLoading && <CircularProgress color="#eee" size={14} />}
                  {!controller.isTaxInfoLoading && !!controller.tax && controller.tax}
                  {!controller.isTaxInfoLoading && !controller.tax && '---'}
                </Typography>
              </div>

              <Divider />

              <div className="totals-row">
                <Typography>Total</Typography>
                <Typography>
                  {!!controller.isTaxInfoLoading && <CircularProgress color="#eee" size={14} />}
                  {!controller.isTaxInfoLoading && !!controller.tax && controller.total}
                  {!controller.isTaxInfoLoading && !controller.tax && '---'}
                </Typography>
              </div>

              <Divider />
            </>

            {!!controller.trialDuration && (
              <div className="totals-row">
                <Typography>First Payment</Typography>
                <Typography>
                  {moment()
                    .add(...controller.trialDuration)
                    .format('Do MMM YYYY')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>

      <Card className="checkout-form" elevation={5}>
        {controller.isSessionLoading && (
          <div className="progress-screen">
            <CircularProgress />
          </div>
        )}

        {!controller.isSessionLoading && (
          <>
            {controller.currentScreen === SCREEN.ACCOUNT_DETAILS && (
              <AccountDetailsScreen controller={controller} />
            )}
          </>
        )}
      </Card>
    </div>
    <Snackbar
      open={controller.errors.length > 0}
      message={controller.errors.join(' ')}
      onClose={controller.handleErrorsClose}
    />
  </div>
);

export default Checkout;
