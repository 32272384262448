import React, { useState, useCallback, useEffect, useRef } from 'react';

const DRAG_CLASS = 'DragList-draggable';
const MOUSE_BUTTON = {
  LEFT: 0,
};

const closest = (element, s) => {
  let el = element;

  do {
    if (Element.prototype.matches.call(el, s)) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);

  return null;
};

const isAbove = function (nodeA, nodeB) {
  // Get the bounding rectangle of nodes
  const rectA = nodeA.getBoundingClientRect();
  const rectB = nodeB.getBoundingClientRect();

  return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
};

const swapIndex = (arr, indexA, indexB) => {
  return arr.reduce((prev, item, index) => {
    if (index === indexA) {
      return [...prev, arr[indexB]];
    } else if (index === indexB) {
      return [...prev, arr[indexA]];
    }

    return [...prev, item];
  }, []);
};

function DragList({ options, renderItem, onChange }) {
  const [dragIndex, setDragIndex] = useState();
  const [dragPos, setDragPos] = useState();
  const placeholderRef = useRef();

  const handleMouseMove = useCallback(
    (e) => {
      const draggable = closest(e.target, `.${DRAG_CLASS}`);

      const prevElement = draggable.previousElementSibling;
      const nextElement = placeholderRef.current.nextElementSibling;

      const index = parseInt(draggable.getAttribute('index'), 10);

      if (onChange && prevElement && isAbove(draggable, prevElement)) {
        const newOrder = swapIndex(options, index, index - 1);
        setDragIndex(index - 1);
        onChange(newOrder);
      }

      if (onChange && nextElement && isAbove(nextElement, draggable)) {
        const newOrder = swapIndex(options, index, index + 1);
        setDragIndex(index + 1);
        onChange(newOrder);
      }

      setDragPos((prev) => ({ ...prev, top: e.pageY - prev.y, left: e.pageX - prev.x }));
    },
    [options]
  );

  const handleMouseUp = useCallback(() => {
    setDragIndex();
    setDragPos();
  }, []);

  const handleMouseDown = useCallback((e, index) => {
    if (e.button !== MOUSE_BUTTON.LEFT) {
      return;
    }

    const draggable = closest(e.target, `.${DRAG_CLASS}`);

    const rect = draggable.getBoundingClientRect();

    setDragPos({
      x: e.pageX - rect.left,
      y: e.pageY - rect.top,
      top: rect.top,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    });

    setDragIndex(index);
  }, []);

  useEffect(() => {
    if (typeof dragIndex === 'undefined') {
      return;
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragIndex, options]);

  return (
    <div className="working">
      {options.map((option, index) => (
        <>
          <div
            index={index}
            className={DRAG_CLASS}
            style={{
              ...(index === dragIndex
                ? {
                    position: 'fixed',
                    top: dragPos.top,
                    left: dragPos.left,
                    width: dragPos.width,
                    height: dragPos.height,
                    background: 'white',
                    border: '1px solid #eee',
                    zIndex: 100,
                  }
                : {}),
              cursor: 'grab',
              userSelect: 'none',
            }}
            onMouseDown={(e) => handleMouseDown(e, index)}
          >
            {renderItem(option)}
          </div>
          {index === dragIndex && (
            <div ref={placeholderRef} style={{ width: dragPos.width, height: dragPos.height }}>
              {' '}
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default DragList;
