import React from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './CheckoutSession.styles.scss';

const CheckoutSessions = ({ controller }) => (
  <div className="CheckoutSessions">
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Client Ip</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>PostalCode</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Affiliate Code</TableCell>
            <TableCell>Complete</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {controller.sessions.length < 1 && <TableCell rowSpan={11}>No Sessions</TableCell>}
          {controller.sessions.map(session => (
            <TableRow key={session.id}>
              <TableCell>{session.id}</TableCell>
              <TableCell style={!session.ip ? { color: 'orange' } : undefined}>
                {session.ip || 'None'}
              </TableCell>
              <TableCell style={!session.firstName ? { color: 'red' } : undefined}>
                {session.firstName || 'None'}
              </TableCell>
              <TableCell style={!session.lastName ? { color: 'red' } : undefined}>
                {session.lastName || 'None'}
              </TableCell>
              <TableCell style={!session.email ? { color: 'red' } : undefined}>
                {session.email || 'None'}
              </TableCell>
              <TableCell style={!session.country ? { color: 'red' } : undefined}>
                {session.country || 'None'}
              </TableCell>
              <TableCell style={!session.postalCode ? { color: 'red' } : undefined}>
                {session.postalCode || 'None'}
              </TableCell>
              <TableCell style={!session?.product.name ? { color: 'red' } : undefined}>
                {session?.product.name || 'None'}
              </TableCell>
              <TableCell style={!session.affiliateCode ? { color: 'blue' } : undefined}>
                {session.affiliateCode || 'None'}
              </TableCell>
              <TableCell style={{ color: session.complete ? 'green' : 'red' }}>
                {session.complete ? 'Yes' : 'No'}
              </TableCell>
              <TableCell>{moment(session.createTime).format('MMM DD, YYYY HH:mm:ss')}</TableCell>
              <TableCell>{moment(session.updateTime).format('MMM DD, YYYY HH:mm:ss')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {controller.areSessionsLoading && <CircularProgress size={50} />}
  </div>
);

export default CheckoutSessions;
