import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import './LoadingOverlay.styles.scss';

const LoadingOverlay = ({ visible, message }) => (
  <div className={['LoadingOverlay', ...(visible ? ['visible'] : ['hidden'])].join(' ')}>
    <CircularProgress size={50} className="progress" />
    {message && <Typography variant="h5">{message}</Typography>}
  </div>
);

export default LoadingOverlay;
