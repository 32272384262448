import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import ReportService from '@/services/report.service';

import CheckoutSessions from './CheckoutSessions.component';

const getSessions = () => ReportService.checkoutSessions();

export default PresentationContainer({
  component: CheckoutSessions,
  controller: function CheckoutSessionsController() {
    const { isLoading: areSessionsLoading, data: sessions = [] } = useQuery(
      'ReportService.checkoutSessions',
      getSessions
    );

    return { areSessionsLoading, sessions };
  }
});
