import React from 'react';

import Button from '@material-ui/core/Button';

import Nav from '@/components/Nav';

const Home = ({ controller }) => (
  <div>
    <title>Home</title>
    <Nav>
      <h1>Home</h1>
    </Nav>
  </div>
);

export default Home;
