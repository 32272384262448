import React from 'react';
import Form from 'react-forms-system';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { required, matchesPeer, phone, or, empty } from '@/core/validators';

import TextInputControl from '@/components/TextInputControl';
import SearchFieldControl from '@/components/SearchFieldControl';

import './AccountDetailsScreen.styles.scss';

const truthy = value => !!value;

const AccountDetailsScreen = ({ controller }) => (
  <div className="AccountDetailsScreen">
    {[controller.isCheckingIntent, controller.isSessionLoading].some(truthy) && (
      <div className="progress-screen">
        <CircularProgress />
      </div>
    )}
    {![controller.isCheckingIntent, controller.isSessionLoading].some(truthy) && (
      <Form
        className="form"
        onSubmit={controller.handleAccountDetailsSubmit}
        onStateChange={controller.handleAccountDetailsChange}
      >
        <div className="group title-group">
          <Typography variant="h5" align="center">
            Account Details
          </Typography>
        </div>

        <div className="group">
          <div className="control">
            <Form.Control
              name="firstName"
              component={TextInputControl}
              label="First Name"
              defaultValue={controller.session?.firstName || ''}
              variant="outlined"
              validators={{ required }}
              errorMessages={{ required: 'Required' }}
            />
          </div>

          <div className="control">
            <Form.Control
              name="lastName"
              component={TextInputControl}
              label="Last Name"
              defaultValue={controller.session?.lastName || ''}
              variant="outlined"
              validators={{ required }}
              errorMessages={{ required: 'Required' }}
            />
          </div>
        </div>

        <Divider />

        <div className="group">
          <div className="control">
            <Form.Control
              name="country"
              label="Country"
              variant="outlined"
              component={SearchFieldControl}
              getOptions={controller.getCountries}
              getOptionLabel={controller.getCountryLabel}
              getOptionSelected={controller.getCountrySelected}
              defaultValue={controller
                .getCountries()
                .find(({ code }) => code === controller.session?.country)}
              errorMessages={{ required: 'Required' }}
              validators={{ required }}
            />
          </div>

          <div className="control">
            <Form.Control
              name="postalCode"
              component={TextInputControl}
              label="Postal Code"
              defaultValue={controller.session?.postalCode || ''}
              variant="outlined"
              validators={{ required }}
              errorMessages={{ required: 'Required' }}
            />
          </div>
        </div>

        <Divider />

        <div className="group">
          <div className="control">
            <Form.Control
              name="email"
              component={TextInputControl}
              label="Email"
              defaultValue={controller.session?.email || ''}
              variant="outlined"
              type="email"
              validators={{ required }}
              errorMessages={{ required: 'Required' }}
            />
          </div>

          <div className="control">
            <Form.Control
              name="phone"
              component={TextInputControl}
              label="Phone (optional)"
              defaultValue={controller.session?.phone || ''}
              variant="outlined"
              type="tel"
              validators={{ phone: or(phone, empty) }}
              errorMessages={{ phone: 'Invalid phone number (may need to select a country)' }}
              peerDependencies={{ country: 'country' }}
            />
          </div>
        </div>

        <Divider />

        <div className="group">
          <div className="control">
            <Form.Control
              name="password"
              component={TextInputControl}
              label="Password"
              defaultValue=""
              variant="outlined"
              type="password"
              autoComplete="off"
              validators={{ required, matchesPeer }}
              errorMessages={{ required: 'Required', matchesPeer: 'Passwords do not match' }}
              peerDependencies={{ confirm: 'peer' }}
            />
          </div>

          <div className="control">
            <Form.Control
              name="confirm"
              component={TextInputControl}
              label="Confirm Password"
              defaultValue=""
              variant="outlined"
              type="password"
              autoComplete="off"
              validators={{ required, matchesPeer }}
              errorMessages={{ required: 'Required', matchesPeer: 'Passwords do not match' }}
              peerDependencies={{ password: 'peer' }}
            />
          </div>
        </div>

        <div className="group">
          <div className="control">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={controller.isCheckingAccountDetails}
            >
              {!!controller.isCheckingAccountDetails && (
                <CircularProgress size={26} color="secondary" />
              )}
              {!controller.isCheckingAccountDetails && 'Create Account'}
            </Button>
          </div>
        </div>
      </Form>
    )}
  </div>
);

AccountDetailsScreen.propTypes = {
  controller: PropTypes.shape({
    isCheckingIntent: PropTypes.bool,
    isSessionLoading: PropTypes.bool,
    isCheckingAccountDetails: PropTypes.bool,
    handleAccountDetailsSubmit: PropTypes.func.isRequired,
    session: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string
    }).isRequired,
    getCountries: PropTypes.func.isRequired,
    getCountryLabel: PropTypes.func.isRequired,
    getCountrySelected: PropTypes.func.isRequired
  }).isRequired
};

export default AccountDetailsScreen;
