import { useState, useContext } from 'react';
import PresentationContainer from 'react-presentation-container';

import AuthContext from '@/core/AuthContext';
import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';
import ROUTES from '@/routes';

import Nav from './Nav.component';

export default PresentationContainer({
  component: Nav,
  middleware: [routerMiddleware()],
  controller: function NavController({ history }) {
    const { user } = useContext(AuthContext);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [avatarAnchor, setAvatarAnchor] = useState(null);

    const onDrawerTogglePress = () => {
      setIsDrawerOpen(prev => !prev);
    };

    const onAvatarPress = e => {
      setAvatarAnchor(e.currentTarget);
    };

    const onAvatarMenuClose = () => {
      setAvatarAnchor(null);
    };

    const onLogoutPress = () => {
      history.push(ROUTES.LOGOUT);
    };

    const onNav = route => {
      history.push(routeWithParams(route));
    };

    const shouldShowMenuItem = item => {
      return item.shouldShow(user);
    };

    return {
      user,
      avatarAnchor,
      isDrawerOpen,
      onLogoutPress,
      onDrawerTogglePress,
      onAvatarPress,
      onAvatarMenuClose,
      onNav,
      shouldShowMenuItem
    };
  }
});
