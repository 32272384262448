import { useState } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import ReportService from '@/services/report.service';

import UserSessions from './UserSessions.component';

export default PresentationContainer({
  component: UserSessions,
  controller: function UserSessionsController() {
    const [selectedSession, setSelectedSession] = useState();

    const { isLoading, data: sessions = [] } = useQuery('ReportService.userSessions', () =>
      ReportService.userSessions()
    );

    const handleSessionPress = session => setSelectedSession(session);
    const handleSessionModalClose = () => setSelectedSession();

    return { isLoading, sessions, selectedSession, handleSessionPress, handleSessionModalClose };
  }
});
