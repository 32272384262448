import { useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import { routeWithParams } from '@/core/router-helpers';
import routerMiddleware from '@/core/router-middleware';

import InformationService from '@/services/information.service';

import ROUTES from '@/routes';

import InformationItems from './InformationItems.component';

export default PresentationContainer({
  component: InformationItems,
  middleware: [routerMiddleware()],
  controller: function InformationItemsController({ history }) {
    const {
      isLoading: areItemsLoading,
      error: itemsError,
      data: informationItems = [],
    } = useQuery('InformationService.read', () => InformationService.read());

    const handleItemPress = useCallback((informationItemId) => {
      history.push(
        routeWithParams(ROUTES.ADMIN_INFORMATION_ITEM, { params: { itemId: informationItemId } })
      );
    }, []);

    const handleAddItemPress = useCallback(() => {
      history.push(routeWithParams(ROUTES.ADMIN_INFORMATION_ITEM));
    }, []);

    return { informationItems, areItemsLoading, itemsError, handleItemPress, handleAddItemPress };
  },
});
