import './Nav.styles.scss';

import React from 'react';

import get from 'lodash/get';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import MenuIcon from '@material-ui/icons/Menu';

import hasPermission from '@/core/hasPermission';

import logo from '@/assets/burst-logo-white.png';
import longLogo from '@/assets/burst-logo-long-white.png';

import ROUTES from '@/routes.js';

const menuItems = [
  {
    text: 'Affiliate',
    icon: SupervisorAccountTwoToneIcon,
    route: ROUTES.AFFILIATE,
    shouldShow: ({ affiliate }) => !!affiliate
  },
  {
    text: 'Admin',
    icon: AssignmentIndTwoToneIcon,
    route: ROUTES.ADMIN,
    shouldShow: ({ permissions }) => hasPermission('READ_ADMIN', permissions)
  }
];

const NavMenuItem = ({ label, align = 'center', children, onClick }) => (
  <ButtonBase className={['Nav-menu-item', ...(align ? [align] : [])].join(' ')} onClick={onClick}>
    {children}

    {label && (
      <Typography variant="body1" display="block">
        {label}
      </Typography>
    )}
  </ButtonBase>
);

const Nav = ({ controller, title, content, children }) => {
  const filteredItems = menuItems.filter(controller.shouldShowMenuItem);

  return (
    <div className="Nav">
      <Paper
        elevation={16}
        className="Nav-menu"
        style={{ ...(controller.isDrawerOpen ? { width: 300 } : {}) }}
      >
        <div className="Nav-menu-header">
          {!controller.isDrawerOpen && (
            <img
              src={logo}
              alt="burst fitness"
              className="primary-logo"
            />
          )}
          {controller.isDrawerOpen && (
            <img
              src={longLogo}
              alt="burst fitness"
              className="logo-long"
            />
          )}
        </div>

        <Divider style={{ backgroundColor: 'rgba(255,255,255, 0.1)', height: 4 }} />

        <div className="Nav-menu-content">
          {filteredItems.map(item => {
            const IconComponent = item.icon;
            return (
              <NavMenuItem
                label={controller.isDrawerOpen && item.text}
                align={controller.isDrawerOpen ? 'left' : 'center'}
                onClick={item.route && (() => controller.onNav(item.route))}
              >
                <IconComponent color="white"  />
              </NavMenuItem>
            );
          })}
        </div>

        {filteredItems.length > 0 && (
          <>
            <Divider style={{ backgroundColor: 'rgba(255,255,255, 0.1)', height: 4 }} />

            <div className="Nav-menu-footer">
              <NavMenuItem
                onClick={controller.onDrawerTogglePress}
                align={controller.isDrawerOpen ? 'left' : 'center'}
              >
                {controller.isDrawerOpen ? (
                  <ArrowBackIcon color="white" />
                ) : (
                  <ArrowForwardIcon color="white" />
                )}
              </NavMenuItem>
            </div>
          </>
        )}
      </Paper>

      <AppBar className="Nav-menu-mobile" position="static" color="secondary">
        <Toolbar>
          {filteredItems.length > 0 && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={controller.onDrawerTogglePress}
            >
              <MenuIcon />
            </IconButton>
          )}
          {title && <Typography variant="h6">{title}</Typography>}
          <div className="filler" />
          <div className="items-right">
            <IconButton onClick={controller.onAvatarPress}>
              <Avatar
                alt={get(controller, 'user.displayName', 'unknown')}
                src={get(controller, 'user.profile.image')}
              >
                <PersonTwoToneIcon />
              </Avatar>
            </IconButton>
          </div>
        </Toolbar>
        <Drawer
          anchor="left"
          open={controller.isDrawerOpen}
          classes={{ root: 'Nav-Drawer' }}
          onClose={controller.onDrawerTogglePress}
        >
          <div className="Nav-menu-header">
            {!controller.isDrawerOpen && (
              <img
                src={logo}
                alt="burst fitness"
                className="primary-logo"
              />
            )}
            {controller.isDrawerOpen && (
              <img
                src={longLogo}
                alt="burst fitness"
                className="logo-long"
              />
            )}
          </div>

          <Divider style={{ backgroundColor: 'rgba(255,255,255, 0.1)', height: 4 }} />

          <List>
            {filteredItems.map(item => {
              const IconComponent = item.icon;
              return (
                <ListItem
                  button
                  onClick={item.route && (() => controller.onNav(item.route))}
                  style={{ paddingLeft: 24 }}
                >
                  <ListItemIcon>
                    <IconComponent color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={controller.avatarAnchor}
        keepMounted
        open={!!controller.avatarAnchor}
        onClose={controller.onAvatarMenuClose}
      >
        <MenuItem onClick={() => controller.onNav(ROUTES.ACCOUNT)}>
          <ListItemIcon>
            <AccountCircleTwoToneIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">My Account</Typography>
        </MenuItem>
        <MenuItem onClick={controller.onLogoutPress}>
          <ListItemIcon>
            <LockTwoToneIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Menu>

      <div className="Nav-content">
        <div className="Nav-content-header">
          <IconButton onClick={controller.onAvatarPress}>
            <Avatar
              alt={get(controller, 'user.displayName', 'unknown')}
              src={get(controller, 'user.profile.image')}
            >
              <PersonTwoToneIcon />
            </Avatar>
          </IconButton>
        </div>

        <div className="Nav-content-liner">{children}</div>
      </div>
    </div>
  );
};

export default Nav;
