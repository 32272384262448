import { useEffect, useReducer } from 'react';

const INIT_STATE = { isLoading: true };

const withCallReducer = (state, { action, payload } = {}) => {
  switch (action) {
    case 'ON_START':
      return INIT_STATE;
    case 'ON_DATA':
      return { isLoading: false, data: payload };
    case 'ON_ERROR':
      return { isLoading: false, error: payload };
    default:
      return state;
  }
};

const withCall = function withCallEffect(fn, changeOn = []) {
  const [state, dispatch] = useReducer(withCallReducer, INIT_STATE);

  const runFunc = async () => {
    try {
      const data = await fn();
      dispatch({ action: 'ON_DATA', payload: data });
    } catch (e) {
      dispatch({ action: 'ON_ERROR', payload: e });
    }
  };

  const refetch = () => {
    dispatch({ action: 'ON_START' });
    runFunc();
  };

  useEffect(() => {
    refetch();
  }, changeOn);

  return { ...state, refetch };
};

export default withCall;
