import { v4 as uuid } from "uuid";
import find from "lodash/find";
import keys from "lodash/keys";
import map from "lodash/map";
import moment from "moment";

const DEFAULT_TIMEOUT = 10000;

export default class FlowMetrics {
  static EVENTS = {
    BEGIN: "BEGIN",
    LOG: "LOG",
    END: "END",
    FAIL: "FAIL",
    CANCEL: "CANCEL",
    TIMEOUT: "TIMEOUT",
    UPDATE_META: "UPDATE_META",
  };

  static _openFlows = {};

  static _timers = {};

  static async _doLog(flowId, event, log = {}) {
    const { [flowId]: flow } = this._openFlows;
    const { hash, meta, logFn, logs } = flow;

    await logFn({ hash, flowId, event, log, meta, logs });

    if (
      [
        this.EVENTS.END,
        this.EVENTS.FAIL,
        this.EVENTS.CANCEL,
        this.EVENTS.TIMEOUT,
      ].includes(event)
    ) {
      map(keys(this._timers), (key) => this._clearTimer(key));
      delete this._openFlows[flowId];
    }
  }

  static _findFlow(hash, matcher = () => true) {
    return find(this._openFlows, (flow) => {
      if (flow.hash !== hash) {
        return false;
      }

      return matcher(flow);
    });
  }

  static async _handleTimeout(flowId) {
    this._clearTimer(flowId);
    await this._doLog(flowId, this.EVENTS.TIMEOUT);
  }

  static _startTimer(flowId, time = DEFAULT_TIMEOUT) {
    this._clearTimer(flowId);

    this._timers[flowId] = {
      flowId,
      time,
      ref: setTimeout(() => this._handleTimeout(flowId), time),
    };
  }

  static _bumpTimer(flowId) {
    const { [flowId]: timer } = this._timers;

    if (!timer) {
      return;
    }

    this._startTimer(flowId, timer.time);
  }

  static _clearTimer(flowId) {
    const { [flowId]: timer } = this._timers;

    if (timer) {
      clearTimeout(timer.ref);
      delete this._timers[flowId];
    }
  }

  static async begin(
    hash,
    {
      log = {},
      logFn = async () => null,
      meta = {},
      matcher = () => true,
      timeout = DEFAULT_TIMEOUT,
    } = {}
  ) {
    const _createTime = moment();
    const enhancedLog = { ...log, _createTime, _createTimeDt: 0 };

    let currentFlow;

    do {
      currentFlow = this._findFlow(hash, matcher);

      if (currentFlow) {
        this._clearTimer(currentFlow.flowId);
        await this._doLog(currentFlow.flowId, this.EVENTS.CANCEL, enhancedLog);
      }
    } while (currentFlow);

    const flowId = uuid();

    this._openFlows[flowId] = {
      hash,
      flowId,
      logFn,
      meta,
      logs: [enhancedLog],
    };

    await this._doLog(flowId, this.EVENTS.BEGIN, enhancedLog);

    if (timeout) {
      this._startTimer(flowId, timeout);
    }
  }

  static async log(
    hash,
    log = {},
    {
      updater = (meta) => meta,
      event = this.EVENTS.LOG,
      matcher = () => true,
    } = {}
  ) {
    const _createTime = moment();
    const flow = this._findFlow(hash, matcher);

    if (!flow) {
      console.warn(`Tried to log on an unknown flow: '${hash}'`);
      return;
    }

    this._bumpTimer(flow.flowId);

    const [beginLog] = flow.logs;

    const enhancedLog = {
      ...log,
      _createTime,
      _createTimeDt: _createTime.diff(beginLog._createTime),
    };

    this._openFlows[flow.flowId] = {
      ...flow,
      logs: [...flow.logs, enhancedLog],
      meta: updater(flow.meta),
    };

    await this._doLog(flow.flowId, event, enhancedLog);
  }

  static async end(
    hash,
    { log = {}, updater = (meta) => meta, matcher = () => true } = {}
  ) {
    const _createTime = moment();
    const flow = this._findFlow(hash, matcher);

    if (!flow) {
      console.warn(`Tried to log on an unopened flow: '${hash}'`);
      return;
    }

    const [beginLog] = flow.logs;

    const enhancedLog = {
      ...log,
      _createTime,
      _createTimeDt: _createTime.diff(beginLog._createTime),
    };

    this._openFlows[flow.flowId] = {
      ...flow,
      logs: [...flow.logs, enhancedLog],
      meta: updater(flow.meta),
    };

    await this._doLog(flow.flowId, this.EVENTS.END, enhancedLog);
  }

  static async fail(
    hash,
    log = {},
    { updater = (meta) => meta, matcher = () => true } = {}
  ) {
    const _createTime = moment();
    const flow = this._findFlow(hash, matcher);

    if (!flow) {
      console.warn(`Tried to log on an unopened flow: '${hash}'`);
      return;
    }

    const [beginLog] = flow.logs;

    const enhancedLog = {
      ...log,
      _createTime,
      _createTimeDt: _createTime.diff(beginLog._createTime),
    };

    this._openFlows[flow.flowId] = {
      ...flow,
      logs: [...flow.logs, enhancedLog],
      meta: updater(flow.meta),
    };

    await this._doLog(flow.flowId, this.EVENTS.FAIL, enhancedLog);
  }

  static async updateMeta(hash, updater, { matcher = () => true } = {}) {
    return this.log(
      hash,
      {},
      { updater, matcher, event: this.EVENTS.UPDATE_META }
    );
  }
}
