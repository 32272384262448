import React from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AffiliateModal from './components/AffiliateModal';

import './Affiliates.styles.scss';

const Affiliates = ({ controller }) => (
  <div className="Affiliates">
    {controller.selectedAffiliate && (
      <AffiliateModal
        affiliate={controller.selectedAffiliate}
        onClose={controller.handleCloseAffiliateModal}
        onSubmit={controller.handlePlanSubmit}
      />
    )}

    <div className="header">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={controller.handleCreateAffiliatePress}
      >
        Create Affiliate
      </Button>
    </div>

    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Plan</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Members</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {controller.affiliates.length < 1 && <TableCell>No active affiliates</TableCell>}
          {controller.affiliates.map((affiliate) => (
            <TableRow key={affiliate.id}>
              <TableCell>{affiliate.code}</TableCell>
              <TableCell>{affiliate.user.firstName}</TableCell>
              <TableCell>{affiliate.user.lastName}</TableCell>
              <TableCell>{affiliate.user.email}</TableCell>
              <TableCell>{affiliate.plan?.name || 'None'}</TableCell>
              <TableCell>{moment(affiliate.createTime).format('MMM DD, YYYY (HH:mm)')}</TableCell>
              <TableCell>{affiliate.members.length}</TableCell>
              <TableCell className="actions-cell">
                <IconButton onClick={() => controller.handleUpdateAffiliatePress(affiliate)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => controller.handleDeleteAffiliatePress(affiliate)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={controller.errors.length > 0}
      autoHideDuration={6000}
      onClose={controller.handleErrorsClose}
    >
      <Alert severity="error">{controller.errors.join(', ')}</Alert>
    </Snackbar>
  </div>
);

export default Affiliates;
