import React from 'react';

import './Video.styles.scss';

const Video = ({ src }) => (
  <div className="Video">
    <video controls key={src}>
      <source src={src} type="video/mp4" />
    </video>
  </div>
);

export default Video;
