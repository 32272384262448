import { useState, useContext, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import moment from 'moment';
import { useQuery } from 'react-query';
import get from 'lodash/get';

import AuthContext from '@/core/AuthContext';
import useEffectDeep from '@/core/useEffectDeep';
import formatPrice from '@/core/formatPrice';

import ProductService from '@/services/product.service';
import UserService from '@/services/user.service';

import ChangePlan from './ChangePlan.component';

const priceSort = (a, b) => {
  const { price: priceA } = a.priceData;
  const { price: priceB } = b.priceData;

  if (priceA === priceB) return 0;

  return priceA < priceB ? -1 : 1;
};

const hydrateProduct = currency => product => {
  let derivedCurrency = currency;
  let price;

  do {
    price = product.prices.find(p => p.currency.toLowerCase() === derivedCurrency.toLowerCase());

    if (!price) {
      derivedCurrency = 'USD';
    }
  } while (!price);

  return {
    ...product,
    priceData: {
      id: price.id,
      price: price.unit_amount / 100,
      interval: price.recurring?.interval,
      intervalCount: price.recurring?.interval_count,
      currency: price.currency
    }
  };
};

const getProductPrice = product => {
  if (!product) {
    return undefined;
  }

  const { currency, price } = product.priceData;

  const usdToLocale = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency
  });

  return usdToLocale.format(price);
};

const getProductPriceInterval = product => {
  if (!product) {
    return undefined;
  }

  const { interval, intervalCount } = product.priceData;

  return intervalCount > 1 ? `/${intervalCount} ${interval}s` : `/${interval}`;
};

const getNextBillingDate = subscription => {
  const periodEnd = moment(subscription.currentPeriodEnd);

  return periodEnd.format('MMM DD, YYYY');
};

export default PresentationContainer({
  component: ChangePlan,
  controller: function ChangePlanController({ locale }) {
    const { user, refetch } = useContext(AuthContext);
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState();
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);

    const { isLoading, data: products = [], error } = useQuery('ProductService.read()', () =>
      ProductService.read()
    );

    const sortedProducts = products.map(hydrateProduct(locale.currency)).sort(priceSort);

    const currentProduct =
      user.subscription && sortedProducts.find(({ id }) => id === user.subscription.productId);

    useEffectDeep(() => {
      setSelectedProductId(currentProduct?.id);
    }, [currentProduct]);

    const onChangePlanPress = () => setIsEditModeActive(true);
    const onCancelPlanPress = async () => {
      setIsCancelling(true);
      try {
        await UserService.update({ priceId: 'cancel' });
        setTimeout(() => refetch());
      } catch (e) {
        setErrors({ form: [e.message] });
      }
      setIsCancelling(false);
    };
    const onSaveChangesPress = async () => {
      setIsSubmitting(true);
      const selectedProduct = sortedProducts.find(({ id }) => id === selectedProductId);

      const price = selectedProduct.prices.find(
        p => p.currency.toLowerCase() === locale.currency.toLowerCase()
      );

      try {
        await UserService.update({ priceId: price.id });
        refetch();
        setIsEditModeActive(false);
      } catch (e) {
        setErrors({ form: [e.message] });
      }
      setIsSubmitting(false);
    };
    const onCancelpress = () => setIsEditModeActive(false);

    const onProductPress = productId => setSelectedProductId(productId);

    const canSave =
      user.subscription?.type === 'apple' ||
      (currentProduct &&
        (selectedProductId !== currentProduct.id ||
          !user.subscription.active ||
          user.subscription.isCancelled)) ||
      (!currentProduct && selectedProductId);

    const hasPostalCode = !!user.postalCode;

    return {
      errors: {
        ...errors,
        ...(hasPostalCode
          ? {}
          : { form: ['Missing postal code', "Add postal code under 'User Details'"] })
      },
      selectedProductId,
      isEditModeActive,
      isSubmitting,
      isCancelling,
      hasPostalCode,
      onCancelPlanPress,
      onChangePlanPress,
      onSaveChangesPress,
      onCancelpress,
      onProductPress,
      getProductPrice,
      getProductPriceInterval,
      canSave,
      plan: user.subscription && {
        name: currentProduct?.name,
        price: formatPrice(
          get(user, 'subscription.price.unit_amount') / 100,
          get(user, 'subscription.price.currency')
        ),
        priceInterval: getProductPriceInterval(currentProduct),
        nextBillingDate: getNextBillingDate(user.subscription),
        isCancelled: user.subscription.isCancelled
      },
      subscription: user.subscription,
      products: sortedProducts
    };
  }
});
