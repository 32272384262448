import { useState, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import LogService from '@/services/log.service';

import AdminLogs from './AdminLogs.component';

export default PresentationContainer({
  component: AdminLogs,
  controller: function AdminLogsController() {
    const [{ isLoading, error, logs }, setState] = useState({
      isLoading: true,
      error: null,
      logs: []
    });

    const getLogs = async () => {
      setState({ isLoading: true, logs: [], error: null });

      try {
        const logsResponse = await LogService.read();
        setState({ isLoading: false, logs: logsResponse, error: null });
      } catch (e) {
        setState({ isLoading: false, logs: [], error: e });
      }
    };

    useEffect(() => {
      getLogs();
    }, []);

    return { isLoading, error, logs };
  }
});
