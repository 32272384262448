import React from 'react';
import moment from 'moment';

import maxBy from 'lodash/maxBy';
import get from 'lodash/get';
import min from 'lodash/min';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone';

import Alert from '@material-ui/lab/Alert';

import Chart from './components/Chart';
import './Overview.styles.scss';

const Overview = ({ controller }) => (
  <div className="Overview">
    {!!controller.isLoading && <CircularProgress size={50} />}
    {!!controller.error && <Alert severity="error">{controller.error.message}</Alert>}
    <div>
      <div className="actions">
        <IconButton
          onClick={controller.handleSyncSubscriptionPress}
          disabled={controller.isSyncing}
        >
          {controller.isSyncing ? <CircularProgress size={22} /> : <SyncTwoToneIcon />}
        </IconButton>
      </div>

      <Divider />

      <Typography variant="h5">
        Active Logins ( {controller.activeLogins.reduce((count, row) => count + row.count, 0)})
      </Typography>

      <Divider />

      <div>
        <Button onClick={() => controller.handleSetActiveLoginDays(7)}>7 Days</Button>
        <Button onClick={() => controller.handleSetActiveLoginDays(30)}>30 Days</Button>
        <Button onClick={() => controller.handleSetActiveLoginDays(60)}>60 Days</Button>
        <Button onClick={() => controller.handleSetActiveLoginDays(90)}>90 Days</Button>
        <Button onClick={() => controller.handleSetActiveLoginDays(365)}>365 Days</Button>
      </div>

      <div style={{ height: 300 }}>
        <Chart
          data={controller.activeLogins}
          type="bar"
          x={{
            scaleType: 'scaleTime',
            formatTick: (d, i) => moment(d).format('MMM-DD'),
            get: d => d.day,
            format: date => moment(date, 'YYYY-MM-DD').toDate(),
            tickCount: controller.activeLoginDays
          }}
          y={{
            scaleType: 'scaleLinear',
            get: d => d.count,
            format: count => count,
            tickCountBounds: [5]
          }}
          domain={[
            moment()
              .subtract(controller.activeLoginDays, 'days')
              .startOf('day')
              .subtract(12, 'hours'),
            moment()
              .endOf('day')
              .add(12, 'hours')
          ]}
          range={[0, maxBy(controller.activeLogins, ({ count }) => count)?.count]}
        />
      </div>

      <Divider />

      <Typography variant="h5">Account Lifespans</Typography>

      <Divider />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Months</TableCell>
              <TableCell>Paying</TableCell>
              <TableCell>Exempt</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controller.durations.map(({ lifespan, count, exempt }) => (
              <TableRow key={lifespan}>
                <TableCell component="th" scope="row">
                  {lifespan}
                </TableCell>
                <TableCell>{count - exempt}</TableCell>
                <TableCell>{exempt}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />

      <Typography variant="h5">Overview</Typography>

      <Divider />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Billing Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Trials</TableCell>
              <TableCell>Cancelled</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controller.overview.map(({ type, total, active, trial, cancelled }) => (
              <TableRow key={type}>
                <TableCell component="th" scope="row">
                  {type}
                </TableCell>
                <TableCell>{active}</TableCell>
                <TableCell>{trial}</TableCell>
                <TableCell>{cancelled}</TableCell>
                <TableCell>{total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

    <Divider />

    <Typography variant="h5">Country Overview</Typography>

    <Divider />

    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Billing Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Trials</TableCell>
              <TableCell>Cancelled</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controller.countryOverview.map(({ country, total, active, trial, cancelled }) => (
              <TableRow key={country}>
                <TableCell component="th" scope="row">
                  {country}
                </TableCell>
                <TableCell>{active}</TableCell>
                <TableCell>{trial}</TableCell>
                <TableCell>{cancelled}</TableCell>
                <TableCell>{total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>

    <Divider />

    <Typography variant="h5">New Subscriptions</Typography>

    <Divider />

    <div style={{ height: 300 }}>
      <Chart
        data={controller.userCount}
        type="bar"
        x={{
          scaleType: 'scaleTime',
          formatTick: (d, i) => (i % 2 === 0 ? moment(d).format('MMM-DD') : ''),
          get: d => d.date,
          format: date => date.toDate(),
          tickCount: moment().daysInMonth()
        }}
        y={{
          scaleType: 'scaleLinear',
          get: d => d.count,
          format: count => count,
          tickCountBounds: [5]
        }}
        domain={[
          moment()
            .subtract(1, 'month')
            .startOf('day')
            .subtract(12, 'hours'),
          moment()
            .endOf('day')
            .add(12, 'hours')
        ]}
        range={[0, maxBy(controller.userCount, ({ count }) => count)?.count]}
      />
    </div>

    <Divider />

    <Typography variant="h5">PageViews</Typography>

    <Divider />

    <div>
      {Object.keys(get(controller, 'pageViews', {})).map(sourceKey => {
        return (
          <div>
            <Typography variant="h6" style={{ marginBottom: '8px' }}>
              {sourceKey}
            </Typography>
            {Object.keys(get(controller, ['pageViews', sourceKey], {}))
              .sort()
              .map(path => (
                <Typography
                  variant="subtitle2"
                  style={{
                    marginLeft: `${(path.match(/\//g) || []).length * 8}px`,
                    paddingLeft: '4px',
                    borderLeft: '1px solid #aaa'
                  }}
                >
                  {`${path}: ${get(controller, ['pageViews', sourceKey, path])}`}
                </Typography>
              ))}
          </div>
        );
      })}
    </div>
  </div>
);

export default Overview;
