import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#1749d1',
      secondary: ''
    },
    secondary: {
      main: '#343434'
    },
    ternary: {
      main: '#fa7261'
    },
    text: {
      primary: '#343434'
    },
    error: {
      main: '#f50057'
    },
    action: {
      main: '#3e67dc'
    },
    white: {
      main: '#fff'
    },
  }
});
