import { useState, useCallback, useEffect, useRef } from 'react';
import PresentationContainer from 'react-presentation-container';
import { v4 as uuid } from 'uuid';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import map from 'lodash/map';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';

import { useQueries } from 'react-query';
import { VIDEO_TYPES, IMAGE_TYPES } from '@/constants/media'

import ExerciseService from '@/services/exercise.service';

import Async from '@/core/Async';
import usePrevious from '@/core/usePrevious';

import Exercise from './Exercise.component';

const loadingMessageMap = {
  thumb: 'Uploading Thumbnail',
  executeVideo: 'Uploading Execute Video',
  educateVideo: 'Uploading Educate Video'
};

export default PresentationContainer({
  component: Exercise,
  controller: function ExerciesesController({ exercise: { id: exerciseId }, onRefresh }) {
    const previousExerciseId = usePrevious(exerciseId);
    const fileUploadRef = useRef();

    const [loadingMessage, setLoadingMessage] = useState();
    const [updatedExercise, setUpdatedExercise] = useState();

    const [
      { isLoading: isVideoDataLoading, data: videoData, refetch: refetchVideoData },
      { isLoading: isAvailableLablesLoading, data: availableLabels = [] },
      { isLoading: isExerciseLoading, data: exercise, refetch: refetchExercise }
    ] = useQueries([
      {
        queryKey: `ExerciseService.videoData(${exerciseId})`,
        queryFn: () => ExerciseService.videoData(exerciseId, 700)
      },
      {
        queryKey: `ExerciseService.labels()`,
        queryFn: () => ExerciseService.labels()
      },
      {
        queryKey: `ExerciseService.read(${exerciseId})`,
        queryFn: () => ExerciseService.read(exerciseId)
      }
    ]);

    const exerciseState = updatedExercise || exercise;

    const canSave = (() => {
      const simpleFields = ['description', 'educateVideo', 'executeVideo', 'name', 'thumb'];
      if (!isEqual(pick(exercise, simpleFields), pick(exerciseState, simpleFields))) {
        return true;
      }

      if (!isEqual(sortBy(exercise?.labels, 'id'), sortBy(exerciseState?.labels, 'id'))) {
        return true;
      }

      return false;
    })();

    const labelsRef = get(exerciseState, 'labels', []);

    const executeBitrates = uniq([
      ...get(videoData, 'available.execute', []),
      ...get(videoData, 'inProgress.execute', [])
    ]);

    const educateBitrates = uniq([
      ...get(videoData, 'available.educate', []),
      ...get(videoData, 'inProgress.educate', [])
    ]);

    useEffect(() => {
      if (exerciseId !== previousExerciseId && !!exerciseState) {
        setUpdatedExercise();
      }
    }, [exerciseState, exerciseId, previousExerciseId, setUpdatedExercise]);

    const handleExerciseUpdate = useCallback(
      key => value => {
        setUpdatedExercise((prev = exercise) => {
          return {
            ...prev,
            [key]:
              key !== 'labels'
                ? value
                : value.map(label =>
                    label?.id ? label : { id: `new_${uuid()}`, name: label, visible: true }
                  )
          };
        });
      },
      [exercise]
    );

    const handleUploadThumbPress = useCallback(() => {
      fileUploadRef.current.openDialog('thumb', { accept: IMAGE_TYPES });
    }, [fileUploadRef]);

    const handleUploadExecutePress = useCallback(() => {
      fileUploadRef.current.openDialog('executeVideo', { accept: VIDEO_TYPES });
    }, [fileUploadRef]);

    const handleUploadEducatePress = () => {
      fileUploadRef.current.openDialog('educateVideo', { accept: VIDEO_TYPES });
    };

    const handleFile = useCallback(
      async (file, key) => {
        if (isValidMediaType(key, file))
          handleExerciseUpdate(key)(file);
      },
      [handleExerciseUpdate]
    );

    const handleUndoPress = useCallback(() => {
      setUpdatedExercise();
    }, [setUpdatedExercise]);

    const handleSavePress = async () => {
      setLoadingMessage('Saving Exercise');
      const requiredFields = ['name', 'description', 'thumb', 'executeVideo', 'educateVideo'];
      const allFields = [...requiredFields, 'labels'];

      if (some(requiredFields, field => !exerciseState[field] || exerciseState[field] === '')) {
        alert('Fill out all the values please!!!!');
        setLoadingMessage();
        return;
      }

      const updates = await Async.reduce(
        allFields,
        async (prevUpdates, field) => {
          if (!isEqual(exercise[field], exerciseState[field])) {
            const { [field]: value } = exerciseState;
            if (['thumb', 'executeVideo', 'educateVideo'].includes(field)) {
              setLoadingMessage(loadingMessageMap[field]);
              const { file, type } = value;
              const s3Key = await ExerciseService.uploadFile(file, type);
              setLoadingMessage('Saving Exercise');
              return { ...prevUpdates, [field]: s3Key };
            }
            return { ...prevUpdates, [field]: value };
          }

          return prevUpdates;
        },
        {}
      );

      if (!exerciseId) {
        const newExercise = await ExerciseService.create(updates);
        await refetchExercise();
        setUpdatedExercise();
        onRefresh(newExercise);
      } else {
        await ExerciseService.update(exerciseId, updates);
        await refetchExercise();
        setUpdatedExercise();
        onRefresh();
      }

      setLoadingMessage();
    };

    const handleChangeLabelVisibility = useCallback(
      targetLabel => {
        handleExerciseUpdate('labels')(
          map(exerciseState?.labels || [], label => {
            if (label.id !== targetLabel.id) {
              return label;
            }

            return { ...label, visible: !label.visible };
          })
        );
      },
      [exerciseState?.labels, handleExerciseUpdate]
    );

    const handleUpdateBitratesPress = async () => {
      await ExerciseService.updateBitrates(exerciseId);
      refetchVideoData();
    };

    const isValidMediaType = (key, file) => {
      const validTypes = {
        thumb: IMAGE_TYPES,
        executeVideo: VIDEO_TYPES,
        educateVideo: VIDEO_TYPES
      };
    
      // Ensure `key` is valid and has associated types
      if (!validTypes.hasOwnProperty(key)) {
        throw new Error(`Invalid key: ${key}`);
      }
    
      return validTypes[key].includes(file.type.toLowerCase());
    };

    return {
      exercise: exerciseState,
      canSave,
      fileUploadRef,
      availableLabels,
      loadingMessage,
      labels: labelsRef,
      videoData,
      isVideoDataLoading,
      executeBitrates,
      educateBitrates,
      handleExerciseUpdate,
      handleFile,
      handleUndoPress,
      handleSavePress,
      handleUploadThumbPress,
      handleUploadExecutePress,
      handleUploadEducatePress,
      handleUpdateBitratesPress,
      handleChangeLabelVisibility
    };
  }
});
