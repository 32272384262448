import phoneLib from 'phone';

import some from 'lodash/some';
import isNil from 'lodash/isNil';

export const empty = value => isNil(value) || value === '';

export const required = value => !!value && value !== [];

export const percent = (from, to) => value => {
  const floatVal = parseFloat(value);
  const fromVal = parseFloat(from) / 100;
  const toVal = parseFloat(to) / 100;

  return floatVal >= fromVal && floatVal <= toVal;
};

export const whenValue = validator => value => {
  if (!value) {
    return true;
  }

  return validator(value);
};

export const matchesPeer = (value, peerValues) => {
  return value === peerValues.peer;
};

export const phone = (value, peerValues) => {
  return phoneLib(value, { country: peerValues.country?.code || '' }).isValid;
};

export const or = (...validators) => (value, peerValues) =>
  some(validators, validator => validator(value, peerValues));
