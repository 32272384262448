import React from 'react';

import NativeSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

import theme from '@/theme/material-theme';

const SnackbarButton = ({ onPress }) => (
  <Button size="small" onClick={onPress} style={{ color: theme.palette.primary.main }}>
    Okay
  </Button>
);

const Snackbar = ({ message, open, onClose }) => (
  <NativeSnackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    open={open}
    onClose={onClose}
    style={{ marginLeft: 16, marginRight: 16 }}
  >
    <SnackbarContent message={message} action={<SnackbarButton onPress={onClose} />} />
  </NativeSnackbar>
);

export default Snackbar;
