import React from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import PlanModal from './components/PlanModal';

import './AffiliatePlans.styles.scss';

const AffiliatePlans = ({ controller }) => (
  <div className="AffiliatePlans">
    {!!controller.selectedPlan && (
      <PlanModal
        plan={controller.selectedPlan}
        onClose={controller.handleCreatePlanClose}
        onSubmit={controller.handleSubmitPlan}
      />
    )}

    <div className="header">
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={controller.handleCreatePlanPress}
      >
        Create Plan
      </Button>
    </div>

    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Exempt</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Trial</TableCell>
            <TableCell>Created</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {controller.plans.length < 1 && <TableCell>No active plans found</TableCell>}
          {controller.plans.map(plan => (
            <TableRow key={plan.id}>
              <TableCell>{plan.name}</TableCell>
              <TableCell>{plan.affiliateRate ? `${plan.affiliateRate * 100}%` : ''}</TableCell>
              <TableCell>{plan.affiliateMemberExempt ? 'Yes' : 'No'}</TableCell>
              <TableCell>
                {plan.affiliateMemberDiscount ? `${plan.affiliateMemberDiscount * 100}%` : ''}
              </TableCell>
              <TableCell>{(plan.affiliateMemberTrial || []).join(' ')}</TableCell>
              <TableCell>{moment(plan.createTime).format('MMM DD, YYYY (HH:mm)')}</TableCell>
              <TableCell className="actions-cell">
                <IconButton onClick={() => controller.handleEditPlanPress(plan)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => controller.handleDeletePlanPress(plan)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

export default AffiliatePlans;
