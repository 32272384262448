import React from 'react';
import propTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Alert from '@material-ui/lab/Alert';

import logoBlue from '@/assets/burst-logo-blue.png';

import './Login.styles.scss';

const Login = ({ controller }) => (
  <div className="Login">
    <div className="overlay" />
    <title>Login</title>
    <Card className="form-container">
      <CardContent>
        <div className="logo-container">
          <img src={logoBlue} className="logo" alt="Burst Fitness" />
        </div>
        <div className="form-control">
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            value={controller.email}
            onChange={controller.handleEmailChange}
          />
        </div>

        <div className="form-control">
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={controller.password}
            onChange={controller.handlePasswordChange}
            onKeyDown={controller.handlePasswordKeyPress}
          />
        </div>

        <div className="form-control actions">
          <Button variant="contained" color="primary" onClick={controller.handleLoginPress}>
            Log In
          </Button>
        </div>

        <div className="forgot-password-container">
          <Typography align="center">
            <Button variant="text" color="secondary" onClick={controller.handleForgotPasswordPress}>
              Forgot your password?
            </Button>
          </Typography>
        </div>

        <div className="form-control actions">
          <Button
            variant="outlined"
            color="secondary"
            onClick={controller.handleCreateAccountPress}
          >
            Create Account
          </Button>
        </div>

        {controller.errorMessage && (
          <Alert severity="error" variant="filled">
            {controller.errorMessage}
          </Alert>
        )}
      </CardContent>
    </Card>
  </div>
);

Login.propTypes = {
  controller: propTypes.shape({
    email: propTypes.string.isRequired,
    password: propTypes.string.isRequired,
    errorMessage: propTypes.string,
    handleEmailChange: propTypes.func.isRequired,
    handlePasswordChange: propTypes.func.isRequired,
    handlePasswordKeyPress: propTypes.func.isRequired,
    handleLoginPress: propTypes.func.isRequired,
    handleForgotPasswordPress: propTypes.func.isRequired,
    handleCreateAccountPress: propTypes.func.isRequired,
  }).isRequired,
};

export default Login;
