import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';

import formatPrice from '@/core/formatPrice';

import theme from '@/theme/material-theme';

import logo from '@/assets/burst-logo-black.png';

import { formatTrial, applyDiscount } from './PlanCard.helpers';

import './PlanCard.styles.scss';

const CardDecorator = ({ color }) => (
  <div className="CardDecorator" style={{ ...(color ? { background: color } : {}) }} />
);

CardDecorator.propTypes = {
  color: PropTypes.string
};

CardDecorator.defaultProps = {
  color: undefined
};

const PlanCard = ({
  name,
  price,
  currency,
  interval,
  discount,
  trial,
  exempt,
  features,
  onPress
}) => (
  <Card raised className={['PlanCard', ...(trial ? ['trial'] : [])].join(' ')}>
    {!exempt && (
      <div className="trial-badge">
        <div className="plate">
          <div className="ribbon">
            <Typography variant="body1" align="center">
              Free Trial
            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'center', justifyContent: 'center' }}>
            <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
              {formatTrial(trial)}
            </Typography>
          </div>
        </div>
      </div>
    )}

    <CardDecorator color={theme.palette.ternary.main} />

    <CardHeader title={name} />

    <div className="price-container">
      <Typography
        variant={exempt || discount ? 'h5' : 'h3'}
        className={['price', ...(exempt || discount ? ['discount'] : [])].join(' ')}
      >
        {formatPrice(price, currency)}
        {(exempt || discount) && (
          <div className="cross-out">
            <div className="line" />
          </div>
        )}
      </Typography>

      <Typography
        variant="caption"
        className={['interval', ...(exempt || discount ? ['discount'] : [])].join(' ')}
      >
        {`/${interval}`}
      </Typography>
    </div>

    {!!exempt && (
      <div className="discount-container">
        <Typography variant="h3">Free</Typography>
      </div>
    )}

    {discount && !exempt && (
      <div className="discount-container">
        <Typography variant="h3">
          {formatPrice(applyDiscount(price, discount), currency)}
        </Typography>
        <Typography variant="caption" className="interval">
          {`/${interval}`}
        </Typography>
      </div>
    )}

    <div className="logo-container">
      <div className="logo-wrapper">
        <img src={logo} alt="burst fitness" />
      </div>
    </div>

    {!exempt && discount && (
      <div className="price-disclaimer">
        <Typography variant="caption">Discounted price is for the first payment only</Typography>
      </div>
    )}

    {features && (
      <div className="features">
        <List component="nav" aria-label="main mailbox folders">
          {features.map(feature => (
            <ListItem button key={feature}>
              <ListItemIcon>
                <CheckCircleTwoToneIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </div>
    )}

    {onPress && (
      <div className="actions">
        <Button variant="contained" color="secondary" onClick={onPress}>
          Select
        </Button>
      </div>
    )}
  </Card>
);

export default PlanCard;
