import { createStore, applyMiddleware } from "redux";
import { v4 as uuid } from "uuid";

import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";

export const CALL_STRATEGY = {
  FIRST: "first",
  LATEST: "latest",
  EVERY: "every",
};

const CALL_EVENT = {
  START: "START",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  RESET: "RESET",
};

const INIT_CALL_STATE = {
  isLoading: false,
  error: null,
  data: null,
};

const tryPromise = async (promise) => {
  try {
    const res = await promise;

    return { res };
  } catch (err) {
    return { err };
  }
};

class SpacesCore {
  static _handlers = {};
  static _effects = {};
  static _spaces = {};
  static _subscriptions = {};
  static _inProgress = {};
  static _waits = {};
  static _getters = {};

  static init = () => {};

  static _storeListener = (spaceKey) => () => {
    const { [spaceKey]: space } = this._spaces;

    ["*", spaceKey].forEach((key) => {
      const { [key]: subscription } = this._subscriptions;

      if (subscription && subscription.length > 0) {
        subscription.forEach((handler) => handler(spaceKey, space.getState()));
      }
    });
  };

  static _storeMiddleware = () => (next) => async (action) => {
    const { [action.type]: { handler } = {} } = this._effects;
    const { [action.type]: waits = [] } = this._waits;

    this._waits[action.type] = [];

    waits.forEach((wait) => {
      const [space, type] = action.type.split(/\.(.+)/);
      wait({ space, type, payload: action.payload });
    });

    next(action);

    if (handler) {
      await handler(...action.payload);
    }
  };

  static defineSpace = (key, initState, getter = (state) => state) => {
    this._getters[key] = getter;

    const listener = this._storeListener(key);

    this._spaces[key] = createStore(
      (state, { type, payload }) => {
        const { [type]: handler } = this._handlers;

        if (!handler) {
          return state;
        }

        return handler(state, ...payload);
      },
      initState,
      applyMiddleware(this._storeMiddleware)
    );

    this._spaces[key].subscribe(listener);

    return {
      key,
      getState: () => this._spaces[key].getState(),
      defineHandler: function (actionType, handler) {
        SpacesCore._handlers[`${key}.${actionType}`] = handler;
      },
      defineEffect: function (actionType, handler, options) {
        SpacesCore._effects[`${key}.${actionType}`] = {
          handler,
          options,
        };
      },
      dispatch: function (actionType, ...args) {
        return SpacesCore.dispatch({ type: actionType, payload: args }, [key]);
      },
      waitFor: async function (initiator, actionType) {
        return SpacesCore.waitFor(initiator, key, actionType);
      },
      close: () => this._spaces[key].unsubscribe(listener),
    };
  };

  static dispatch = ({ type, ...restOfAction }, spaces = []) => {
    const targetSpaces =
      spaces.length > 0
        ? pick(
            this._spaces,
            spaces.map((spaceId) => get(spaceId, "key", spaceId))
          )
        : this._spaces;

    const spaceKeys = keys(targetSpaces);

    setTimeout(() => {
      spaceKeys.forEach((spaceKey) => {
        const key = get(spaceKey, "key", spaceKey);

        const { [key]: space } = this._spaces;

        space.dispatch({ type: `${key}.${type}`, ...restOfAction });
      });
    });
  };

  static subscribe = (listener, spaces = ["*"]) => {
    spaces.forEach((space) => {
      const key = get(space, "key", space);

      if (!this._subscriptions[key]) {
        this._subscriptions[key] = [];
      }

      this._subscriptions[key].push(listener);
    });
  };

  static unsubscribe = (listener, spaces = ["*"]) => {
    spaces.forEach((space) => {
      const key = get(space, "key", space);

      if (!this._subscriptions[key]) {
        return;
      }

      this._subscriptions[key] = this._subscriptions[key].filter(
        (prev) => prev !== listener
      );
    });
  };

  static getState = (spaces = []) => {
    const spaceKeys = spaces.map((space) => get(space, "key", space));

    const targetSpaces =
      spaces.length > 0 ? pick(this._spaces, spaceKeys) : this._spaces;

    return keys(targetSpaces).reduce((prev, spaceKey) => {
      const key = get(spaceKey, "key", spaceKey);

      return {
        ...prev,
        [key]: this._getters[key](this._spaces[key].getState()),
      };
    }, {});
  };

  static waitFor = async (initiator, space, actionTypes = []) => {
    let beenKickedOff = false;

    const kickOff = () => {
      if (!beenKickedOff) {
        beenKickedOff = true;
        initiator();
      }
    };

    return Promise.race(
      actionTypes.map((actionType) => {
        const fullKey = `${space}.${actionType}`;

        if (typeof this._waits[fullKey] === "undefined") {
          this._waits[fullKey] = [];
        }

        return new Promise((resolve) => {
          this._waits[fullKey].push(resolve);
          kickOff();
        });
      })
    );
  };

  static defineCall = (key, fn, options = {}) => {
    const { keepPrevious = false, strategy = CALL_STRATEGY.FIRST } = options;

    const space = this.defineSpace(key, INIT_CALL_STATE);

    space.defineHandler(CALL_EVENT.START, (state) => {
      return {
        error: null,
        isLoading: true,
        ...(keepPrevious ? {} : { data: null }),
      };
    });

    space.defineHandler(CALL_EVENT.SUCCESS, (state, data) => ({
      ...state,
      isLoading: false,
      data,
    }));

    space.defineHandler(CALL_EVENT.FAIL, (state, error) => ({
      ...state,
      isLoading: false,
      error,
    }));

    space.defineHandler(CALL_EVENT.resetCall, () => INIT_CALL_STATE);

    space.defineEffect(
      CALL_EVENT.START,
      async (...args) => {
        const { [key]: inProgress } = this._inProgress;

        if (options.strategy === CALL_STRATEGY.FIRST && inProgress) {
          return;
        }

        const id = uuid();

        this._inProgress[key] = id;

        const { res, err } = await tryPromise(fn(...args));

        const { [key]: currentTask } = this._inProgress;

        if (
          options.strategy !== CALL_STRATEGY.LATEST ||
          (options.strategy === CALL_STRATEGY.LATEST && id === currentTask)
        ) {
          if (err) {
            SpacesCore.dispatch({ type: CALL_EVENT.FAIL, payload: [err] }, [
              key,
            ]);
            return;
          }

          SpacesCore.dispatch({ type: CALL_EVENT.SUCCESS, payload: [res] }, [
            key,
          ]);
        }

        if (id === currentTask) {
          this._inProgress[key] = undefined;
        }
      },
      { strategy }
    );

    return { key };
  };

  static call = (key, ...args) => {
    this.dispatch({ type: CALL_EVENT.START, payload: args }, [
      get(key, "key", key),
    ]);
  };

  static resetCall = (key) => {
    this.dispatch({ type: CALL_EVENT.RESET }, [get(key, "key", key)]);
  };
}

SpacesCore.CALL_STRATEGY = CALL_STRATEGY;

export default SpacesCore;
