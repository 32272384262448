import React from 'react';

import get from 'lodash/get';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import AddIcon from '@material-ui/icons/Add';

import LoadingOverlay from '@/components/LoadingOverlay';

import Exercise from '../Exercise';

import './Exercises.styles.scss';

const ExerciseRow = ({ exercise, selected, onPress }) => (
  <ListItem button onClick={onPress} selected={selected}>
    <ListItemIcon />
    <ListItemText primary={exercise.name} />
  </ListItem>
);

const Exercises = ({ controller }) => (
  <div className="Admin-Exercises">
    {controller.isLoading && <LoadingOverlay message="Getting Exercies" />}
    <div className="content">
      <div className="liner">
        <div className="picker">
          {controller.exercises.map(exercise => (
            <ExerciseRow
              key={exercise.id}
              exercise={exercise}
              onPress={controller.onExercisePress(exercise)}
              selected={exercise.id === get(controller, 'selectedExercise.id')}
            />
          ))}
          <ListItem button onClick={controller.onCreateExercisePress}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Create Exercise" />
          </ListItem>
        </div>
        <div className="exercise">
          {!controller.selectedExercise && <pre>{'<  Pick an exercise'}</pre>}
          {controller.selectedExercise && (
            <Exercise exercise={controller.selectedExercise} onRefresh={controller.onRefreshList} />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Exercises;
