import lodashChunk from "lodash/chunk";
import isArray from "lodash/isArray";
import Promise from "bluebird";
import moment from "moment";

import sleep from "./sleep";
import TryCall from "./TryCall";

Promise.config({ cancellation: true });

export default class Async {
  static map = async (arr, callback, { serial = true } = {}) => {
    if (!serial) {
      return Promise.all(arr.map(callback));
    }

    const results = [];

    for (let i = 0; i < arr.length; i += 1) {
      results.push(await callback(arr[i], i, arr)); // eslint-disable-line no-await-in-loop
    }

    return results;
  };

  static mapValues = async (obj, callback) => {
    const keys = Object.keys(obj);
    const results = {};

    for (let i = 0; i < keys.length; i += 1) {
      const { [i]: key } = keys;
      const { [key]: value } = obj;

      const result = await callback(value, key, obj);

      results[key] = result;
    }

    return results;
  };

  static forEach = async (arr, callback) => {
    for (let i = 0; i < arr.length; i += 1) {
      await callback(arr[i], i, arr); // eslint-disable-line no-await-in-loop
    }
  };

  static reduce = async (arr, callback, initialValue) => {
    let prevResult = initialValue;

    for (let i = 0; i < arr.length; i += 1) {
      prevResult = await callback(prevResult, arr[i], i, arr); // eslint-disable-line no-await-in-loop
    }

    return prevResult;
  };

  static filter = async (arr, callback) => {
    const results = [];

    for (let i = 0; i < arr.length; i += 1) {
      const { [i]: item } = arr;
      if (await callback(item, i, arr)) {
        results.push(item);
      }
    }

    return results;
  };

  static throttle = async (arr, callback, size, time) => {
    const chunks = lodashChunk(arr, size);

    let results = [];

    for (let i = 0; i < chunks.length; i += 1) {
      const { [i]: chunk } = chunks;
      results = [
        ...results,
        ...(await Async.map(chunk, async (...args) =>
          TryCall(callback(...args))
        )),
      ];
      await sleep(time);
    }

    return results;
  };

  static all = async (targets) => {
    const keys = Object.keys(targets);

    const results = await Promise.all(keys.map((key) => targets[key]));

    if (isArray(targets)) {
      return results;
    }

    return results.reduce(
      (prev, res, index) => ({
        ...prev,
        [keys[index]]: res,
      }),
      {}
    );
  };

  static race = async (targets) => {
    let resolved = false;

    const keys = Object.keys(targets);

    const promises = keys.map((key) => {
      const { [key]: target } = targets;

      return {
        key,
        promise: new Promise((resolve, reject) => {
          if (!target.then) {
            resolve(target);
          }
          target.then(resolve).catch(reject);
        }),
      };
    });

    try {
      const winner = await new Promise((resolve, reject) => {
        promises.forEach(({ key, promise }) => {
          promise
            .then((result) => {
              if (resolved) {
                return;
              }

              resolved = true;

              promises.forEach((p) => {
                if (p.key !== key) {
                  p.promise.cancel();
                }
              });

              resolve({ key, result });
            })
            .catch(reject);
        });
      });

      if (isArray(targets)) {
        return keys.map((key) =>
          key === winner.key ? winner.result : undefined
        );
      }

      return keys.reduce(
        (prev, key) => ({
          ...prev,
          [key]: winner.key === key ? winner.result : undefined,
        }),
        {}
      );
    } catch (e) {
      return {};
    }
  };

  static memoize = (
    fn,
    {
      hasher = (...args) => JSON.stringify(args),
      staleTime = [5, "minutes"],
    } = {}
  ) => {
    const cache = {};

    return async (...args) => {
      const hash = hasher(...args);
      const { [hash]: cacheHit } = cache;

      if (
        cacheHit &&
        cacheHit.time &&
        cacheHit.time.isAfter(moment().subtract(...staleTime))
      ) {
        return cacheHit.result;
      }

      if (cacheHit && cacheHit.inProgress) {
        return new Promise((resolve, reject) => {
          cache[hash].callbacks.push((err, result) => {
            if (err) {
              reject(err);
              return;
            }
            resolve(result);
          });
        });
      }

      cache[hash] = { inProgress: true, callbacks: [] };

      const fnResult = await TryCall(fn(...args));

      return new Promise((resolve, reject) => {
        cache[hash].callbacks.push((err, result) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(result);
        });

        if (fnResult.err) {
          cache[hash].callbacks.forEach((callback) => callback(fnResult.err));
          return;
        }

        cache[hash] = {
          ...cache[hash],
          result: fnResult.res,
          time: moment(),
        };

        cache[hash].callbacks.forEach((callback) =>
          callback(undefined, fnResult.res)
        );
      });
    };
  };
}
