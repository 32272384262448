import { useState, useCallback, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useMutation } from 'react-query';
import get from 'lodash/get';

import AuthService from '@/services/auth.service';

import routerMiddleware from '@/core/router-middleware';

import ROUTES from '@/routes';

import Login from './Login.component';

const ENTER_KEY = 13;

export default PresentationContainer({
  component: Login,
  middleware: [routerMiddleware()],
  controller: function LoginController({ history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginMutation = useMutation(credentials =>
      AuthService.login(credentials.email, credentials.password)
    );

    const handleLoginPress = useCallback(async () => {
      loginMutation.mutate({ email, password });
    }, [email, password]);

    const handleEmailChange = useCallback(e => setEmail(e.target.value), []);
    const handlePasswordChange = useCallback(e => setPassword(e.target.value), []);
    const handlePasswordKeyPress = useCallback(e => {
      if (e.which === ENTER_KEY) {
        handleLoginPress();
      }
    });

    const handleCreateAccountPress = useCallback(() => {
      history.push(ROUTES.CREATE_ACCOUNT);
    });
    const handleForgotPasswordPress = useCallback(() => {
      history.push(ROUTES.FORGOT_PASSWORD);
    });

    useEffect(() => {
      if (!loginMutation.isLoading && loginMutation.isSuccess) {
        history.replace(ROUTES.ROOT);
      }
    }, [loginMutation.isLoading, loginMutation.isSuccess]);

    return {
      email,
      password,
      errorMessage: get(loginMutation, 'error.message'),
      handleForgotPasswordPress,
      handleEmailChange,
      handlePasswordChange,
      handleLoginPress,
      handleCreateAccountPress,
      handlePasswordKeyPress
    };
  }
});
