import { useState, useCallback, useRef, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';

import Image from './Image.component';

export default PresentationContainer({
  component: Image,
  controller: function ImageController() {
    const [isLandscape, setIsLandscape] = useState(false);

    const imageRef = useRef();

    const onImageLoad = () => {
      const {
        current: { naturalWidth, naturalHeight }
      } = imageRef;

      setIsLandscape(naturalWidth > naturalHeight);
    };

    return { isLandscape, imageRef, onImageLoad };
  }
});
