import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';

import Alert from '@material-ui/lab/Alert';

import Form from '@/components/Form';
import BancontactIcon from '@/components/icons/Bancontact';
import Snackbar from '@/components/Snackbar';

import { EXEMPT_TYPES as EXEMPT_SUBSCRIPTION_TYPES } from '@/constants/subscription';

import { CARD_OPTIONS } from './ChangePaymentMethod.constants';

import './ChangePaymentMethod.styles.scss';

const PaymentMethodIcon = ({ paymentMethod }) => (
  <div className="PaymentMethodIcon">
    {paymentMethod?.subType === 'bancontact' && <BancontactIcon style={{ marginRight: '8px' }} />}
    {paymentMethod?.subType === 'card' && <CreditCardTwoToneIcon style={{ marginRight: '8px' }} />}
  </div>
);

const ChangePaymentMethod = ({ controller }) => (
  <div className="ChangePaymentMethod">
    <div className="form-group">
      <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
        Payment Method
      </Typography>
    </div>

    <Divider />

    {EXEMPT_SUBSCRIPTION_TYPES.includes(controller.subscription?.type) && (
      <div className="form-group">
        <Typography variant="subtitle1">
          {`You have ${controller.subscription?.type} access, no need to update your subcription right now.`}
        </Typography>
      </div>
    )}

    {!EXEMPT_SUBSCRIPTION_TYPES.includes(controller.subscription?.type) &&
      controller.subscription?.active &&
      controller.paymentMethod?.type === 'apple' && (
        <div className="form-group">
          <Typography variant="subtitle1">
            You have an active subscription through Apple. You will need to manage your payment
            method through the Apple App Store.
          </Typography>
        </div>
      )}

    {!EXEMPT_SUBSCRIPTION_TYPES.includes(controller.subscription?.type) &&
      (controller.paymentMethod?.type !== 'apple' || !controller.subscription?.active) &&
      !controller.isEditModeActive && (
        <div>
          <div
            className="form-group"
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {controller.paymentMethod?.type === 'stripe' && (
              <PaymentMethodIcon paymentMethod={controller.paymentMethod} />
            )}
            {controller.paymentMethod?.subType === 'card' && (
              <Typography style={{ marginRight: '8px' }}>
                {get(controller, 'paymentMethod.brand')}
              </Typography>
            )}
            {controller.paymentMethod?.type === 'stripe' && (
              <Typography variant="subtitle1">
                {`****${get(controller, 'paymentMethod.last4')}`}
              </Typography>
            )}
          </div>

          <div className="form-group">
            <div className="form-control">
              <Button
                variant="contained"
                color="primary"
                onClick={controller.onEditPress}
                disabled={controller.isCheckingPayment || !controller.hasPostalCode}
              >
                {controller.isCheckingPayment && <CircularProgress size={24} color="secondary" />}
                {!controller.isCheckingPayment &&
                  (controller.paymentMethod?.type !== 'apple' && controller.paymentMethod
                    ? 'Change Payment Method'
                    : 'Add Payment Method')}
              </Button>
            </div>

            {controller.errors.form && (
              <Alert severity="error" variant="filled">
                {controller.errors.form.join(', ')}
              </Alert>
            )}
          </div>
        </div>
      )}

    {controller.isEditModeActive && (
      <Form>
        <div className="form-group">
          <div className="form-control">
            <TextField
              variant="outlined"
              label="Name on card"
              value={controller.cardName}
              onChange={controller.onCardNameChange}
              error={!!controller.errors?.cardName}
              helperText={(controller.errors?.cardName || []).join(', ')}
            />
          </div>

          <div
            className={['card-wrapper', ...(controller.errors.paymentMethod ? ['error'] : [])].join(
              ' '
            )}
          >
            <CardElement id="credit-card" options={CARD_OPTIONS} onReady={controller.onCardReady} />
            {controller.cardLoading && <LinearProgress />}
          </div>
          {!!controller.errors.paymentMethod && (
            <FormHelperText error>{controller.errors.paymentMethod.join(', ')}</FormHelperText>
          )}

          {controller?.alternatePaymentMethods?.length > 0 && (
            <>
              <div className="form-group or-divider">
                <Divider />
                <Typography>OR</Typography>
              </div>

              {controller?.alternatePaymentMethods?.includes('bancontact') && (
                <div className="form-control payment-method-bancontact">
                  <Button
                    onClick={controller.handleBancontactPress}
                    startIcon={!controller.isCheckingPayment && <BancontactIcon />}
                    variant="outlined"
                    disabled={controller.isCheckingPayment}
                  >
                    {controller.isCheckingPayment && (
                      <CircularProgress size={24} color="secondary" />
                    )}
                    {!controller.isCheckingPayment && 'Bancontact'}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        <div className="form-group">
          <div className="form-control">
            <Button
              variant="contained"
              color="primary"
              onClick={controller.handleChangePaymentMethodPress}
              disabled={controller.isCheckingPayment || controller.isSubmitting}
            >
              Save Changes
              {controller.isSubmitting && (
                <CircularProgress size={24} className="button-progress" color="secondary" />
              )}
            </Button>
          </div>

          <div className="form-control">
            <Button
              variant="outlined"
              onClick={controller.onEditCancel}
              disabled={controller.isCheckingPayment || controller.isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </div>

        {controller.errors.form && (
          <Alert severity="error" variant="filled">
            {controller.errors.form.join(', ')}
          </Alert>
        )}
      </Form>
    )}
    <Snackbar
      open={controller.errors.alert}
      message={get(controller, 'errors.alert', []).join(' ')}
      onClose={controller.handleCloseAlert}
    />
  </div>
);

export default ChangePaymentMethod;
