import moment from 'moment';

import { routeWithParams } from '@/core/router-helpers';

import { getAxios } from './service.helpers';

export default class LogService {
  static userList = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get('/report/admin/user-list');

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: users, error } = data;

    if (!success) {
      throw error;
    }

    return users;
  };

  static adminOverview = async (groupBy) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/overview', { query: { groupBy } })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: overview, error } = data;

    if (!success) {
      throw error;
    }

    return overview;
  };

  static userCount = async ({
    start = moment().subtract(1, 'month').startOf('day').unix(),
    end = moment().endOf('day').unix(),
    groupBy = 'day',
  } = {}) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/user-count', { query: { start, end, groupBy } })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: userCount, error } = data;

    if (!success) {
      throw error;
    }

    return userCount;
  };

  static activeLogins = async ({ days = '7' } = {}) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/active-logins', { query: { days } })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: activeLogins, error } = data;

    if (!success) {
      throw error;
    }

    return activeLogins;
  };

  static accountDuration = async ({ interval = 'month', exempt = false } = {}) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/account-duration', { query: { interval, exempt } })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: durations, error } = data;

    if (!success) {
      throw error;
    }

    return durations;
  };

  static checkoutSessions = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/checkout-sessions')
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: sessions, error } = data;

    if (!success) {
      throw error;
    }

    return sessions;
  };

  static userSessions = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/user-sessions')
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: sessions, error } = data;

    if (!success) {
      throw error;
    }

    return sessions;
  };

  static signUpFunnel = async (
    start = moment().startOf('week').startOf('day').unix(),
    end = moment().endOf('week').endOf('day').unix(),
    search = ''
  ) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/sign-up-funnel', { query: { start, end, search } })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: funnelData, error } = data;

    if (!success) {
      throw error;
    }

    return funnelData;
  };

  static pageViews = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/report/admin/page-view')
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: pageViews, error } = data;

    if (!success) {
      throw error;
    }

    return pageViews;
  };
}
