import { getAxios } from './service.helpers';

export default class StripeService {
  static createIntent = async intentData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/stripe/intent`, intentData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: clientId, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return clientId;
  };

  static getIntent = async intentId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/stripe/intent/${intentId}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: intent, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return intent;
  };

  static calculateTax = async taxData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/stripe/calculate-tax`, taxData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: taxInfo, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return taxInfo;
  };
}
