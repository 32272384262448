import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

function SearchField({
  value,
  label,
  variant,
  helperText,
  onChange,
  onBlur,
  error,
  disabled,
  getOptions,
  getOptionLabel,
  getOptionSelected
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const callGetOptions = async search => {
    setLoading(true);
    setOptions([]);
    const optionsResult = await getOptions(search);
    setLoading(false);
    setOptions(optionsResult);
  };

  useEffect(() => {
    callGetOptions();
  }, []);

  const handleTextChange = e => {
    callGetOptions(e.target.value);
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      value={value}
      disabled={disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, newValue) => onChange(newValue)}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          value={value}
          onBlur={onBlur}
          onChange={handleTextChange}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}

export default SearchField;
