import { getAxios } from './service.helpers';

export default class AffiliateService {
  static create = async affiliateData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/affiliate', affiliateData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: affiliate, error } = data;

    if (!success) {
      throw error;
    }

    return affiliate;
  };

  static read = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get('/affiliate');

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: affiliates, error } = data;

    if (!success) {
      throw error;
    }

    return affiliates;
  };

  static update = async (affiliateId, updates) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.patch(`/affiliate/${affiliateId}`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: affiliate, error } = data;

    if (!success) {
      throw error;
    }

    return affiliate;
  };

  static members = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/affiliate/members`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: members, error } = data;

    if (!success) {
      throw error;
    }

    return members;
  };

  static publicCode = async affiliateCode => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/affiliate/public-plan/${affiliateCode}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: plan, error } = data;

    if (!success) {
      throw error;
    }

    return plan;
  };

  static readPlan = async id => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/affiliate/plan${id ? `/${id}` : ''}`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: plans, error } = data;

    if (!success) {
      throw error;
    }

    return plans;
  };

  static createPlan = async planData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/affiliate/plan', planData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: plan, error } = data;

    if (!success) {
      throw error;
    }

    return plan;
  };

  static updatePlan = async (planId, updates) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.patch(`/affiliate/plan/${planId}`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: plan, error } = data;

    if (!success) {
      throw error;
    }

    return plan;
  };

  static emailInvites = async emails => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/affiliate/email-invites', { emails });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw error;
    }

    return true;
  };
}
