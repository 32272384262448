import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import getIpData from '@/core/getIpData';

import Account from './Account.component';

export default PresentationContainer({
  component: Account,
  controller: function AccountController() {
    const { data: { data: { currency_code: currency = 'USD' } = {} } = {} } = useQuery(
      'getIpData',
      () => getIpData()
    );

    return { locale: { currency } };
  },
});
