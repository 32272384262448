import React from 'react';

import SearchField from '@/components/SearchField';

const SearchFieldControl = ({
  value,
  onChange,
  onTouch,
  validationState,
  errorMessages,
  ...restOfProps
}) => {
  const { dirty, pending, touched, valid, ...restOfValidation } = validationState;

  const hasError = (dirty || touched) && !valid;

  const errors = Object.keys(restOfValidation).reduce((prev, key) => {
    if (!restOfValidation[key]) {
      return [...prev, errorMessages[key]];
    }
    return prev;
  }, []);

  return (
    <SearchField
      value={value}
      onChange={onChange}
      onBlur={onTouch}
      error={hasError}
      helperText={hasError ? errors.join(', ') : undefined}
      {...restOfProps}
    />
  );
};

export default SearchFieldControl;
