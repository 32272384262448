import { useEffect, useState } from 'react';
import PresentationContainer from 'react-presentation-container';

import { Spaces, spacesMiddleware } from '@fastercise/common-frontend';

import AffiliateService from '@/services/affiliate.service';

import AffiliatePlans from './AffiliatePlans.component';

const AffiliatePlanCall = Spaces.defineCall('affiliatePlanCall', async () =>
  AffiliateService.readPlan()
);

export default PresentationContainer({
  component: AffiliatePlans,
  middleware: [spacesMiddleware([AffiliatePlanCall])],
  controller: function AffiliatePlansController({ spaces }) {
    const [selectedPlan, setSelectedPlan] = useState();

    const { isLoading, error, data: plans } = spaces.affiliatePlanCall;

    useEffect(() => {
      Spaces.call(AffiliatePlanCall);
    }, []);

    const handleCreatePlanPress = () => setSelectedPlan({});
    const handleCreatePlanClose = () => setSelectedPlan();
    const handleSubmitPlan = async plan => {
      if (selectedPlan.id) {
        await AffiliateService.updatePlan(selectedPlan.id, plan);
      } else {
        await AffiliateService.createPlan(plan);
      }

      setSelectedPlan(undefined);
      Spaces.call(AffiliatePlanCall);
    };
    const handleDeletePlanPress = async plan => {
      await AffiliateService.updatePlan(plan.id, { active: false });

      Spaces.call(AffiliatePlanCall);
    };
    const handleEditPlanPress = async plan => {
      setSelectedPlan(plan);
    };

    return {
      isLoading,
      error,
      plans: plans || [],
      selectedPlan,
      handleCreatePlanPress,
      handleCreatePlanClose,
      handleSubmitPlan,
      handleDeletePlanPress,
      handleEditPlanPress
    };
  }
});
