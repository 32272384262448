import { useState } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import ReportService from '@/services/report.service';
import SubscriptionService from '@/services/subscription.service';

import UserList from './UserList.component';

export default PresentationContainer({
  component: UserList,
  controller: function UserListController() {
    const [isSyncing, setIsSyncing] = useState(false);

    const { isLoading, error, data: users = [], refetch } = useQuery('ReportService.userList', () =>
      ReportService.userList()
    );

    const handleSyncSubscriptionPress = async () => {
      setIsSyncing(true);
      await SubscriptionService.sync();
      setIsSyncing(false);
      refetch();
    };

    return {
      isLoading,
      error,
      users,
      isSyncing,
      handleSyncSubscriptionPress,
    };
  },
});
