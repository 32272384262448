import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

import './Header.styles.scss';

const Header = ({ onClose, title }) => (
  <>
    <div className="Modal-Header">
      {title && (
        <Typography component="h2" variant="h6">
          {title}
        </Typography>
      )}
      <div className="spacer" />
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>

    <Divider />
  </>
);

export default Header;
