import { getAxios } from './service.helpers';

export default class SubscriptionService {
  static sync = async subscriptionId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      `/subscription/sync${subscriptionId ? `/${subscriptionId}` : ''}`
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return true;
  };
}
