import { useState, useEffect, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';

import Image from './Image.component';

export default PresentationContainer({
  component: Image,
  controller: function ImageController({ getSource }) {
    const [source, setSource] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [isSourceLoading, setIsSourceLoading] = useState(!!getSource);

    const _loadSource = useCallback(async () => {
      setIsSourceLoading(true);
      const result = await getSource();
      setSource(result);
      setIsSourceLoading(false);

      if (result) {
        setIsVisible(true);
      }
    }, [getSource]);

    useEffect(() => {
      if (getSource) {
        _loadSource();
      }
    }, [_loadSource, getSource]);

    return {
      source,
      isSourceLoading,
      isVisible
    };
  }
});
