import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';

import Header from './components/Header';

import './Modal.styles.scss';

function Modal({ visible, onClose, children, className }) {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = () => setIsMouseDown(true);
  const handleMouseUp = () => setIsMouseDown(false);

  return visible ? (
    <div
      className={[
        'Modal',
        ...(isMouseDown ? ['mouse-down'] : []),
        ...(className ? [className] : [])
      ].join(' ')}
      onClick={onClose}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Paper onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
        {children}
      </Paper>
    </div>
  ) : null;
}

Modal.Header = Header;

export default Modal;
