const ROUTES = {
  ROOT: '/',
  CHECKOUT: '/checkout/:code?',
  LOGIN: '/login',
  HOME: '/home',
  LOGOUT: '/logout',
  ADMIN: '/admin/:screen?',
  ADMIN_COLLECTION: '/collection/:collectionId',
  ADMIN_INFORMATION_ITEM: '/information-item/:itemId?',
  ACCOUNT: '/account',
  CREATE_ACCOUNT: '/create-account',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:code',
  AFFILIATE: '/affiliate',
  SELECT_PLAN: '/select-plan',
  CHECKOUT_COMPLETE: '/checkout-complete',
  BANCONTACT: '/bancontact'
};

export const PUBLIC_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.CREATE_ACCOUNT,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.SELECT_PLAN,
  ROUTES.CHECKOUT,
  ROUTES.CHECKOUT_COMPLETE,
  ROUTES.ADMIN_INFORMATION_ITEM,
  ROUTES.BANCONTACT
];

export default ROUTES;
