import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import Form from '@/components/Form';

import './ChangePassword.styles.scss';

const ChangePassword = ({ controller }) => (
  <div className="ChangePassword">
    <div className="form-group">
      <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
        Password
      </Typography>
    </div>

    <Divider />

    {!controller.isEditModeActive && (
      <div className="form-group">
        <div className="form-control">
          <Button
            variant="contained"
            color="primary"
            onClick={controller.onEditPress}
            type="password"
          >
            Change Password
          </Button>
        </div>
      </div>
    )}
    {controller.isEditModeActive && (
      <Form>
        <div className="form-group">
          <div className="form-control">
            <TextField
              label="Old Password"
              type="password"
              value={controller.oldPassword}
              variant="outlined"
              autoComplete="no"
              onChange={controller.onOldPasswordChange}
              error={!!controller.errors?.oldPassword}
              helperText={(controller.errors?.oldPassword || []).join(', ')}
            />
          </div>

          <div className="form-control">
            <TextField
              label="New Password"
              type="password"
              value={controller.password}
              onChange={controller.onPasswordChange}
              variant="outlined"
              autoComplete="no"
              error={!!controller.errors?.password}
              helperText={(controller.errors?.password || []).join(', ')}
            />
          </div>

          <div className="form-control">
            <TextField
              label="Confirm Password"
              type="password"
              value={controller.confirm}
              onChange={controller.onConfirmChange}
              variant="outlined"
              autoComplete="no"
              error={!!controller.errors?.confirm}
              helperText={(controller.errors?.confirm || []).join(', ')}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-control">
            <Button
              variant="contained"
              color="primary"
              onClick={controller.onChangePasswordPress}
              disabled={controller.isSubmitting}
            >
              Change Password
              {controller.isSubmitting && (
                <CircularProgress size={24} className="button-progress" />
              )}
            </Button>
          </div>

          <div className="form-control">
            <Button
              onClick={controller.onCancelEditPress}
              disabled={controller.isSubmitting}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>

          {controller.errors.form && (
            <Alert severity="error" variant="filled">
              {controller.errors.form.join(', ')}
            </Alert>
          )}
        </div>
      </Form>
    )}
    <Snackbar
      open={!!controller.snackbar}
      autoHideDuration={6000}
      onClose={controller.onSnackbarClose}
      message={controller.snackbar}
    />
  </div>
);

export default ChangePassword;
