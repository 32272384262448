import React from 'react';
import PresentationContainer from 'react-presentation-container';

import FileUploader from './FileUploader.component';
import { toBase64 } from './FileUploader.helpers';

const FileUploaderContainer = PresentationContainer({
  component: FileUploader,
  bindMembers: ['onInputRef'],
  controller: class FileUploaderController extends React.Component {
    onInputRef = (ref) => {
      this.ref = ref;

      if (this.ref) {
        this.ref.onchange = async (e) => {
          const { target } = e;

          const {
            files: [file],
          } = target;

          const { type } = file;

          target.value = null;

          const { onFile = () => null } = this.props;

          const base64 = await toBase64(file);

          onFile({ file, type, base64 }, this.lastKey);
        };
      }
    };

    openDialog = (key, { accept }) => {
      this.lastKey = key;

      if (accept) {
        this.ref.setAttribute('accept', accept);
      }

      this.ref.click();
    };
  },
});

FileUploaderContainer.imgToBase64 = toBase64;

export default FileUploaderContainer;
