import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';

import { routeWithParams } from '@/core/router-helpers';

import ROUTES from '@/routes';

import Admin from './Admin.component';
import { SCREENS } from './Admin.constants';

export default PresentationContainer({
  component: Admin,
  controller: function AdminController({ match, history }) {
    const { screen } = match.params;

    const [selectedScreen, setSelectedScreen] = useState(screen || SCREENS.DEFAULT);

    const onPickerPress = pickedScreen => () => {
      history.push(routeWithParams(ROUTES.ADMIN, { params: { screen: pickedScreen } }));
    };

    const onBackPress = useCallback(() => {
      history.push(routeWithParams(ROUTES.ADMIN));
    });

    return {
      selectedScreen,
      onPickerPress,
      onBackPress
    };
  }
});
