import { useContext, useRef, useState } from 'react';
import PresentationContainer from 'react-presentation-container';
import copy from 'clipboard-copy';
import { useQuery } from 'react-query';

import AuthContext from '@/core/AuthContext';
import AffiliateService from '@/services/affiliate.service';

import logoBlue from '@/assets/burst-logo-blue.png';
import logoBlack from '@/assets/burst-logo-black.png';

import Affiliate from './Affiliate.component';

export default PresentationContainer({
  component: Affiliate,
  controller: function AffiliateController() {
    const { user } = useContext(AuthContext);
    const [snackbarMessage, setSnackbarMessage] = useState();
    const [buttonVariant, setButtonVariant] = useState('dark');
    const [emails, setEmails] = useState('');
    const [isSendingEmails, setIsSendingEmails] = useState(false);
    const linkRef = useRef();
    const buttonRef = useRef();

    const { isLoading: membersLoading, data: members = [] } = useQuery(
      'AffiliateService.members',
      () => AffiliateService.members()
    );

    const { affiliate } = user;

    const { protocol, host } = window.location;

    const link = `${protocol}//${host}/create-account?affiliateCode=${encodeURIComponent(
      affiliate.code
    )}`;

    const imageUrl = `${protocol}//${host}${buttonVariant === 'dark' ? logoBlue : logoBlack}`;

    const handleCopyLinkPress = () => {
      copy(link);

      setSnackbarMessage({ severity: 'info', message: 'Link copied to clipboard' });
    };

    const handleSnackbarClose = () => {
      setSnackbarMessage(undefined);
    };

    const handleCopyHtml = () => {
      copy(buttonRef.current.outerHTML);
      setSnackbarMessage({ severity: 'info', message: 'HTML copied to clipboard' });
    };

    const handleButtonVariantChange = (e, variant) => {
      setButtonVariant(variant);
    };

    const handleEmailsChange = e => {
      setEmails(e.target.value);
    };

    const handleSendEmailsPress = async () => {
      if (!emails) {
        setSnackbarMessage({
          severity: 'error',
          message: 'You must enter emails to send email invites'
        });
        return;
      }
      setIsSendingEmails(true);
      try {
        await AffiliateService.emailInvites(emails);
        setEmails('');
        setSnackbarMessage({ severity: 'success', message: 'Emails sent' });
      } catch (e) {
        setSnackbarMessage({ severity: 'error', message: e.message });
      } finally {
        setIsSendingEmails(false);
      }
    };

    return {
      link,
      linkRef,
      imageUrl,
      buttonRef,
      snackbarMessage,
      buttonVariant,
      emails,
      isSendingEmails,
      memberCount: affiliate.members.length,
      plan: affiliate.plan,
      members,
      handleCopyLinkPress,
      handleSnackbarClose,
      handleCopyHtml,
      handleButtonVariantChange,
      handleEmailsChange,
      handleSendEmailsPress
    };
  }
});
