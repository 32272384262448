import moment from 'moment';

export const formatTrial = (trial) => {
  if (!trial) {
    return 'No Trial';
  }

  return `${moment.duration(...trial).humanize()}`;
};

export const applyDiscount = (price, discount) => {
  return price - price * discount;
};
