import React, { useState, useCallback } from 'react';

import { useQuery } from 'react-query';

import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import RemoveCircleTwoToneIcon from '@material-ui/icons/RemoveCircleTwoTone';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import OpenInNewTwoToneIcon from '@material-ui/icons/OpenInNewTwoTone';
import ArrowBackTwoToneIcon from '@material-ui/icons/ArrowBackTwoTone';
import UndoTwoToneIcon from '@material-ui/icons/UndoTwoTone';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';

import CollectionService from '@/services/collection.service';

import Nav from '@/components/Nav';
import DragList from '@/components/DragList';
import Modal from '@/components/Modal';

import { getItemPrimaryText, getItemSecondaryText } from './Collection.helpers';

import './Collection.styles.scss';

function AddItemModal({ currentItems, onItemPress, onClose }) {
  const [search, setSearch] = useState('');

  const { isLoading, error, data: types = [] } = useQuery('CollectionService.itemTypes', () =>
    CollectionService.itemTypes()
  );

  const handleSearchChange = useCallback(value => setSearch(value), []);

  const filteredTypes = types.filter(
    ({ type, id, name }) =>
      name.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
      !currentItems.find(currentItem => {
        return currentItem.type === type && currentItem[currentItem.type].id === id;
      })
  );

  return (
    <Modal visible onClose={onClose}>
      <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
        <TextField
          label="search"
          variant="outlined"
          value={search}
          onChange={e => handleSearchChange(e.target.value)}
        />
      </div>

      <Divider />

      <List>
        {filteredTypes.length < 1 && (
          <ListItem>
            <ListItemText>No items to add</ListItemText>
          </ListItem>
        )}
        {filteredTypes.map(type => (
          <ListItem onClick={() => onItemPress(type)} button>
            <ListItemText primary={type.name} secondary={type.type} />
          </ListItem>
        ))}
      </List>
    </Modal>
  );
}

function EditCollectionItemModal({ onClose, onChange, defaultValue }) {
  const [label, setLabel] = useState(defaultValue);

  const handleSubmitPress = useCallback(() => {
    onChange(label);
  }, [onChange, label]);

  const handleClearPress = useCallback(() => {
    setLabel('');
  }, [setLabel]);

  return (
    <Modal visible onClose={onClose} className="EditCollectionItemModal">
      <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
        <TextField
          label="Label"
          variant="outlined"
          value={label}
          onChange={e => setLabel(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClearPress}>
                  <HighlightOffTwoToneIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>

      <Divider />

      <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
        <Button variant="contained" color="primary" onClick={handleSubmitPress}>
          Submit
        </Button>
      </div>
    </Modal>
  );
}

function CollectionItem({ item, controller, ordered }) {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleEditPress = useCallback(() => {
    setIsEditOpen(true);
  }, [setIsEditOpen]);

  const handleEditClose = useCallback(() => {
    setIsEditOpen(false);
  }, [setIsEditOpen]);

  const handleEditChange = useCallback(
    label => {
      setIsEditOpen(false);
      controller.handleLabelChange(item, label);
    },
    [item, setIsEditOpen, controller.handleLabelChange]
  );

  return (
    <ListItem className={['collection-item'].join(' ')}>
      {isEditOpen && (
        <EditCollectionItemModal
          onClose={handleEditClose}
          defaultValue={item.label}
          onChange={handleEditChange}
        />
      )}

      <ListItemText primary={getItemPrimaryText(item)} secondary={getItemSecondaryText(item)} />

      <ListItemSecondaryAction>
        <IconButton onClick={() => controller.handleCompletablePress(item)}>
          {item.completable ? (
            <CheckCircleTwoToneIcon style={{ color: 'green' }} />
          ) : (
            <BlockTwoToneIcon style={{ color: 'red' }} />
          )}
        </IconButton>

        <IconButton onClick={handleEditPress}>
          <EditTwoToneIcon />
        </IconButton>

        <IconButton onClick={() => controller.handleOpenItem(item)}>
          <OpenInNewTwoToneIcon />
        </IconButton>

        <IconButton onClick={() => controller.handleRemoveItem(item)}>
          <DeleteTwoToneIcon />
        </IconButton>

        <IconButton
          onMouseDown={e => e.stopPropagation()}
          onClick={() => controller.handleCheckToggle(item)}
        >
          {ordered ? <RemoveCircleTwoToneIcon /> : <AddCircleTwoToneIcon />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const Collection = ({ controller }) => (
  <div className="AdminCollection">
    <title>Collection</title>
    <Nav>
      {controller.isAddItemVisible && (
        <AddItemModal
          currentItems={[...controller.orderedItems, ...controller.unorderedItems]}
          onItemPress={controller.handleAddItem}
          onClose={controller.handleAddItemClose}
        />
      )}
      <div className="content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="actions">
              <IconButton onClick={controller.handleBackPress}>
                <ArrowBackTwoToneIcon />
              </IconButton>
            </div>

            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className="group details-header">
                  <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
                    Collection Details
                  </Typography>

                  <IconButton
                    disabled={!controller.canSaveDetails}
                    onClick={controller.handleDetailsSavePress}
                  >
                    <SaveTwoToneIcon />
                  </IconButton>
                </div>

                <Divider />

                {controller.isCollectionLoading && <CircularProgress size={50} />}

                {!controller.isCollectionLoading && (
                  <div style={{ padding: 16 }}>
                    <div className="group">
                      <div className="control">
                        <TextField
                          value={controller.collection.name || ''}
                          onChange={e => controller.handleDetailsChange('name', e.target.value)}
                          variant="outlined"
                          label="Collection Name"
                        />
                      </div>

                      <div className="control">
                        <FormControlLabel
                          label="Visible"
                          control={
                            <Checkbox
                              checked={controller.collection.visible}
                              onChange={() =>
                                controller.handleDetailsChange(
                                  'visible',
                                  !controller.collection.visible
                                )
                              }
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className="group details-header">
                  <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
                    Collection Items
                  </Typography>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton onClick={controller.handleAddItemPress}>
                      <AddTwoToneIcon />
                    </IconButton>

                    <IconButton
                      onClick={controller.handleUndoPress}
                      disabled={!controller.canSaveCollectionItems}
                    >
                      <UndoTwoToneIcon />
                    </IconButton>

                    <IconButton
                      disabled={!controller.canSaveCollectionItems}
                      onClick={controller.handleItemsSavePress}
                    >
                      <SaveTwoToneIcon />
                    </IconButton>
                  </div>
                </div>

                <div>
                  <Divider />
                  <Typography>Ordered Items</Typography>
                  <Divider />
                  <List>
                    <DragList
                      options={controller.orderedItems}
                      onChange={controller.handleItemsChange}
                      renderItem={item => (
                        <CollectionItem item={item} controller={controller} ordered />
                      )}
                    />
                  </List>

                  <Divider />
                  <Typography>Unordered Items</Typography>
                  <Divider />

                  <List>
                    {controller.unorderedItems.map(item => (
                      <CollectionItem item={item} controller={controller} />
                    ))}
                  </List>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Nav>
  </div>
);

export default Collection;
