import React from 'react';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '@material-ui/lab/Alert';

import { EXEMPT_TYPES as EXEMPT_SUBSCRIPTION_TYPES } from '@/constants/subscription';

import Form from '@/components/Form';

import './ChangePlan.styles.scss';

const ProductCard = ({ product, controller }) => (
  <Grid item xs={4}>
    <ButtonBase onClick={() => controller.onProductPress(product.id)}>
      <Card
        className={[...(product.id === controller.selectedProductId ? ['selected'] : [])].join(' ')}
      >
        <CardContent>
          <Typography>{product.name}</Typography>
          <Typography>
            {`${controller.getProductPrice(product)}${controller.getProductPriceInterval(product)}`}
          </Typography>
        </CardContent>
      </Card>
    </ButtonBase>
  </Grid>
);

const ChangePlan = ({ controller }) => (
  <div className="ChangePlan">
    <div className="form-group">
      <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
        Subscription
      </Typography>
    </div>

    <Divider />

    {EXEMPT_SUBSCRIPTION_TYPES.includes(controller.subscription?.type) && (
      <div className="form-group">
        <Typography variant="subtitle1">
          {`You have ${controller.subscription?.type} access, no need to update your subcription right now.`}
        </Typography>
      </div>
    )}

    {!EXEMPT_SUBSCRIPTION_TYPES.includes(controller.subscription?.type) &&
      !controller.isEditModeActive && (
        <div className="form-group">
          {controller.plan && (
            <>
              <div className="form-control">
                {(!controller.subscription.active || controller.plan.isCancelled) && (
                  <Typography>Subscription Inactive</Typography>
                )}
                {!controller.plan.isCancelled && controller.subscription.active && (
                  <>
                    <Typography>{controller.plan.name}</Typography>
                    <Typography>{`${controller.plan.price}${controller.plan.priceInterval}`}</Typography>
                  </>
                )}
                <Typography>
                  {`${
                    !controller.subscription.active || controller.plan.isCancelled
                      ? 'Previous Subscription Active Until:'
                      : 'Next Billing Date:'
                  } ${controller.plan.nextBillingDate}`}
                </Typography>
              </div>

              {controller.subscription?.type === 'apple' && controller.subscription.active && (
                <div className="form-control">
                  <Typography>
                    You have an active subscription through Apple. You will need to manage your
                    subscription through the Apple App Store
                  </Typography>
                </div>
              )}

              {(controller.subscription?.type !== 'apple' || !controller.subscription.active) && (
                <div className="form-control">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={controller.onChangePlanPress}
                  >
                    Change Subscription
                  </Button>
                </div>
              )}

              {controller.subscription?.type !== 'apple' &&
                controller.subscription.active &&
                !controller.plan?.isCancelled && (
                  <div className="form-control">
                    <Button
                      variant="outlined"
                      onClick={controller.onCancelPlanPress}
                      disabled={controller.isCancelling}
                    >
                      Cancel Subscription
                      {controller.isCancelling && (
                        <CircularProgress size={24} className="button-progress" />
                      )}
                    </Button>
                  </div>
                )}
            </>
          )}
          {!controller.plan && (
            <div className="form-control">
              <Button
                variant="contained"
                color="primary"
                onClick={controller.onChangePlanPress}
                disabled={!controller.hasPostalCode}
              >
                Pick a Plan
              </Button>
            </div>
          )}
          {controller.errors.form && (
            <Alert severity="error" variant="filled">
              {controller.errors.form.join(', ')}
            </Alert>
          )}
        </div>
      )}
    {controller.isEditModeActive && (
      <Form>
        <Grid container spacing={1} className="product-cards">
          {controller.products.map((product) => (
            <ProductCard key={product.id} product={product} controller={controller} />
          ))}
        </Grid>

        <div className="form-group">
          <div className="form-control">
            <Button
              variant="contained"
              color="primary"
              onClick={controller.onSaveChangesPress}
              disabled={!controller.canSave || controller.isSubmitting}
            >
              Save Changes
              {controller.isSubmitting && (
                <CircularProgress size={24} className="button-progress" />
              )}
            </Button>
          </div>

          <div className="form-control">
            <Button
              variant="outlined"
              onClick={controller.onCancelpress}
              disabled={controller.isSubmitting}
            >
              Cancel
            </Button>
          </div>
        </div>
        {controller.errors.form && (
          <Alert severity="error" variant="filled">
            {controller.errors.form.join(', ')}
          </Alert>
        )}
      </Form>
    )}
  </div>
);

export default ChangePlan;
