import { getAxios } from './service.helpers';

import UserService from './user.service';

const AUTH_STORAGE_KEY = 'fastercise_auth';

export default class AuthService {
  static async refreshUser() {
    try {
      const currentUser = await UserService.read();

      await this.storeAuthData({
        ...(await this.getAuthData()),
        user: currentUser
      });

      const { user } = await this.getAuthData();

      return user;
    } catch (e) {
      if (e.code === 'INVALID_TOKEN') {
        await this.destroyAuthData();
      }
      return null;
    }
  }

  static async getAuthData() {
    return JSON.parse(sessionStorage.getItem(AUTH_STORAGE_KEY) || 'null');
  }

  static async storeAuthData(data) {
    sessionStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(data));
  }

  static async destroyAuthData() {
    sessionStorage.removeItem(AUTH_STORAGE_KEY);
  }

  static async login(email, password) {
    const axios = await getAxios();

    const { data } = await axios.post('/auth/login', { email, password });
    const { success, error, data: body } = data;

    if (!success) {
      throw error;
    }

    await this.storeAuthData(body);

    return body;
  }

  static async logout() {
    await this.destroyAuthData();
  }

  static async createAccount(accountData) {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/auth/create-account`, accountData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: user, error } = data;

    if (!success) {
      throw error;
    }

    return user;
  }

  static async passwordRecover(email) {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/auth/password-recover`, { email });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw error;
    }

    return true;
  }

  static async passwordRecoverConfirm(email, password, code) {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/auth/password-recover/confirm`, {
      email,
      password,
      code
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw error;
    }

    return true;
  }

  static async passwordChange(oldPassword, password) {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/auth/password-change`, {
      oldPassword,
      newPassword: password
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, error } = data;

    if (!success) {
      throw error;
    }

    return true;
  }
}
