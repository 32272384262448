import React, { useEffect } from 'react';
import { BrowserRouter as NativeRouter, Route, withRouter, Redirect } from 'react-router-dom';

import values from 'lodash/values';

import Login from '@/pages/Login';
import Checkout from '@/pages/Checkout';
import Home from '@/pages/Home';
import Account from '@/pages/Account';
import ForgotPassword from '@/pages/ForgotPassword';
import ResetPassword from '@/pages/ResetPassword';
import Affiliate from '@/pages/Affiliate';
import SelectPlan from '@/pages/SelectPlan';
import CheckoutComplete from '@/pages/CheckoutComplete';
import Bancontact from '@/pages/Bancontact';

import Admin from '@/pages/Admin';
import AdminCollection from '@/pages/Admin/components/Collection';
import AdminInformationItem from '@/pages/Admin/components/InformationItem';

import LoadingOverlay from '@/components/LoadingOverlay';
import RouterError from '@/components/RouterError';

import ROUTES, { PUBLIC_ROUTES } from '@/routes.js';

import AuthService from '@/services/auth.service';
import SessionService from '@/services/session.service';

import { pathInRoutes, pathMatchesRoute } from '@/core/router-helpers';
import withCall from '@/core/withCall';
import AuthContext from '@/core/AuthContext';
import usePrevious from '@/core/usePrevious';

const Routes = withRouter(function Routes({ location, history }) {
  const { pathname } = location;
  const { href } = window.location;
  const prevHref = usePrevious(href);

  const { isLoading, error, data: user, refetch } = withCall(
    async () => AuthService.refreshUser(),
    [pathname]
  );

  const prevUserId = usePrevious(user?.id);

  const logPageView = async () => {
    await SessionService.pageView(href, user?.id);
  };

  useEffect(() => {
    if (pathname !== ROUTES.ROOT && (href !== prevHref || user?.id !== prevUserId)) {
      logPageView();
    }
  }, [href, prevHref, user, prevUserId]);

  if (error) {
    console.log(error);
    return <RouterError />;
  }

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} message="Checking Credentials" />;
  }

  if (!pathInRoutes(pathname, values(ROUTES))) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  if (pathname === ROUTES.LOGOUT) {
    AuthService.logout().then(() => history.replace(ROUTES.ROOT));

    return null;
  }

  if (pathname === ROUTES.ROOT && !user) {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  if (pathname === ROUTES.ROOT && user) {
    return <Redirect to={ROUTES.ACCOUNT} />;
  }

  if (!user && !pathInRoutes(pathname, PUBLIC_ROUTES)) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  if (user && pathMatchesRoute(pathname, ROUTES.LOGIN)) {
    return <Redirect to={ROUTES.ROOT} />;
  }

  return (
    <AuthContext.Provider value={{ user, refetch }}>
      <Route exact path={ROUTES.HOME} component={Home} />
      <Route exact path={ROUTES.CHECKOUT} component={Checkout} />
      <Route exact path={ROUTES.LOGIN} component={Login} />
      <Route exact path={ROUTES.ADMIN} component={Admin} />
      <Route exact path={ROUTES.ACCOUNT} component={Account} />
      <Route
        exact
        path={ROUTES.CREATE_ACCOUNT}
        component={() => (
          <Redirect to={{ pathname: ROUTES.SELECT_PLAN, search: location.search }} />
        )}
      />
      <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={ROUTES.AFFILIATE} component={Affiliate} />
      <Route exact path={ROUTES.SELECT_PLAN} component={SelectPlan} />
      <Route exact path={ROUTES.CHECKOUT_COMPLETE} component={CheckoutComplete} />
      <Route exact path={ROUTES.ADMIN_COLLECTION} component={AdminCollection} />
      <Route exact path={ROUTES.ADMIN_INFORMATION_ITEM} component={AdminInformationItem} />
      <Route exact path={ROUTES.BANCONTACT} component={Bancontact} />
    </AuthContext.Provider>
  );
});

export default function Router() {
  return (
    <NativeRouter>
      <Routes />
    </NativeRouter>
  );
}
