import React, { useState, useCallback } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';

import DragList from '@/components/DragList';
import Modal from '@/components/Modal';

import './HomeConfig.styles.scss';

function PickerModal({ options, renderOption, onClose, search = true }) {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = useCallback((value) => setSearchValue(value), []);

  const filteredOptions = options.filter(
    (option) => JSON.stringify(option).toLowerCase().indexOf(searchValue.toLowerCase()) > -1
  );

  return (
    <Modal visible onClose={onClose}>
      {search && (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
            <TextField
              label="search"
              variant="outlined"
              value={searchValue}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
          </div>

          <Divider />
        </>
      )}
      <List>{filteredOptions.map(renderOption)}</List>
    </Modal>
  );
}

const HomeConfig = ({ controller }) => (
  <div className="HomeConfig" style={{ padding: '16px' }}>
    <PickerModal
      options={controller.collections}
      renderOption={(option) => (
        <ListItem button>
          <ListItemText primary={option.name} />
        </ListItem>
      )}
    />
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="group details-header">
          <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
            Onboarding Collections
          </Typography>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <IconButton>
              <AddTwoToneIcon />
            </IconButton>

            {/* <IconButton
              onClick={controller.handleUndoPress}
              disabled={!controller.canSaveCollectionItems}
            >
              <UndoTwoToneIcon />
            </IconButton>

            <IconButton
              disabled={!controller.canSaveCollectionItems}
              onClick={controller.handleItemsSavePress}
            >
              <SaveTwoToneIcon />
            </IconButton> */}
          </div>
        </div>

        <Divider />

        <Card>
          <CardContent>
            <DragList
              options={controller.titleCollections}
              renderItem={(item) => (
                <ListItem className={['collection-item'].join(' ')}>
                  <ListItemText primary={item.name} />

                  <ListItemSecondaryAction>
                    {/* <IconButton onClick={() => controller.handleOpenItem(item)}>
                        <OpenInNewTwoToneIcon />
                    </IconButton> */}

                    <IconButton>
                      <DeleteTwoToneIcon />
                    </IconButton>

                    {/* <IconButton
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={() => controller.handleCheckToggle(item)}
                    >
                        <RemoveCircleTwoToneIcon />
                    </IconButton> */}
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </div>
);

export default HomeConfig;
