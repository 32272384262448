import React from 'react';

import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import Dialog from '@/components/Dialog';

import './Collections.styles.scss';

const Collections = ({ controller }) => (
  <div className="Collections">
    <Dialog
      visible={controller.isCreating}
      title="New Collection"
      type="prompt"
      prompt={controller.collection}
      promptLabel="Collection Name"
      onPromptChange={controller.handleCollectionNameChange}
      onClose={controller.handleCloseCreate}
      actions={[
        {
          label: 'Cancel',
          onPress: controller.handleCloseCreate,
          variant: 'outlined',
        },
        {
          label: 'Create',
          onPress: controller.handleCreatePress,
          variant: 'contained',
          color: 'primary',
        },
      ]}
    />

    <div className="actions">
      <IconButton onClick={controller.handleNewCollectionPress}>
        <AddTwoToneIcon />
      </IconButton>
    </div>

    <Divider style={{ marginTop: 8, marginBottom: 8 }} />

    {controller.collections.length > 0 && (
      <Card>
        <List component="nav" aria-label="main mailbox folders">
          {controller.collections.map((collection, index) => (
            <>
              {index !== 0 && <Divider />}
              <ListItem button onClick={() => controller.handleCollectionPress(collection)}>
                <ListItemText primary={collection.name} />
              </ListItem>
            </>
          ))}
        </List>
      </Card>
    )}

    {controller.collections.length < 1 && (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography variant="caption">No collections found</Typography>
      </div>
    )}
  </div>
);

export default Collections;
