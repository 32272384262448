import { useState } from 'react';
import PresentationContainer from 'react-presentation-container';

import pick from 'lodash/pick';

import UserService from '@/services/user.service';
import AffiliateService from '@/services/affiliate.service';

import AffiliateModal from './AffiliateModal.component';

export default PresentationContainer({
  component: AffiliateModal,
  middleware: [],
  controller: function AffiliateModalController({ onSubmit, affiliate }) {
    const [formState, setFormState] = useState({
      values: pick(affiliate, ['user', 'plan', 'code'])
    });

    const handleGetUsers = async () => UserService.readAll();
    const handleGetUserLabel = ({ firstName, lastName, email }) =>
      `${firstName} ${lastName} [${email}]`;
    const handleGetUserSelected = ({ id: optionId }, { id: valueId }) => optionId === valueId;

    const handleGetPlans = async () => AffiliateService.readPlan();
    const handleGetPlanLabel = ({ name }) => name;
    const handleGetPlanSelected = ({ id: optionId }, { id: valueId }) => optionId === valueId;
    const handleSubmit = ({ values, validationState }) => {
      if (!validationState.valid) {
        return;
      }

      onSubmit && onSubmit(values);
    };

    const handleFormChange = state => setFormState(state);

    return {
      formState,
      disableUser: !!affiliate.id,
      handleGetUsers,
      handleGetUserLabel,
      handleGetUserSelected,
      handleGetPlans,
      handleGetPlanLabel,
      handleGetPlanSelected,
      handleSubmit,
      handleFormChange
    };
  }
});
