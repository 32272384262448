import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import usePrevious from './usePrevious';

export default function useEffectDeep(fn, deps) {
  const prev = usePrevious(deps);

  useEffect(() => {
    if (!isEqual(prev, deps)) {
      return fn();
    }
  }, deps);
}
