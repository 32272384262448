import React, { useCallback, useRef } from 'react';
import MDEditor from '@uiw/react-md-editor';

import get from 'lodash/get';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import UndoTwoToneIcon from '@material-ui/icons/UndoTwoTone';
import ArrowBackTwoToneIcon from '@material-ui/icons/ArrowBackTwoTone';

import Nav from '@/components/Nav';
import FileUploader from '@/components/FileUploader';

import { VIDEO_TYPES, IMAGE_TYPES } from '@/constants/media'

import InfoService from '@/services/info.service';

import Image from './components/Image';
import Video from './components/Video';

import './InformationItem.styles.scss';

function ImagePicker({ controller }) {
  const thumb = get(controller, 'item.thumb.base64') || get(controller, 'item.thumbKey');
  const isTempThumb = !!get(controller, 'item.thumb.base64');

  const uploadRef = useRef();

  const handleEditPress = useCallback(() => {
    uploadRef.current.openDialog('thumb', { accept: IMAGE_TYPES });
  }, []);

  const handleDeletePress = useCallback(() => {
    controller.handleThumbChange(undefined);
  }, []);

  const handleFile = useCallback(file => {
    if (IMAGE_TYPES.includes(file.type))
      controller.handleThumbChange(file);
  }, []);

  const handleGetThumbSource = useCallback(async () => {
    if (isTempThumb || !thumb) {
      return thumb;
    }

    const result = await InfoService.selectImage(thumb);
    return result.uri;
  }, [thumb, isTempThumb]);

  return (
    <div className="ImagePicker">
      {!!thumb && <Image getSource={handleGetThumbSource} />}

      <FileUploader ref={uploadRef} onFile={handleFile} />

      {!thumb && (
        <Button
          variant="contained"
          onClick={handleEditPress}
          style={{
            marginLeft: '16px',
            marginRight: '16px',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 'calc(100% - 32px)'
          }}
        >
          Add Thumbnail
        </Button>
      )}

      {!!thumb && (
        <view className="actions">
          <IconButton className="edit-button" onClick={handleEditPress}>
            <EditTwoToneIcon />
          </IconButton>

          <IconButton className="delete-button" onClick={handleDeletePress}>
            <DeleteTwoToneIcon />
          </IconButton>
        </view>
      )}
    </div>
  );
}

function VideoPicker({ controller }) {
  const video = get(controller, 'item.video');
  const uploadRef = useRef();

  const handleEditPress = useCallback(() => {
    uploadRef.current.openDialog('thumb', { accept: VIDEO_TYPES });
  }, []);

  const handleDeletePress = useCallback(() => {
    controller.handleVideoChange(undefined);
  }, []);

  const handleFile = useCallback(file => {
    if (VIDEO_TYPES.includes(file.type))
      controller.handleVideoChange(file);
  }, []);

  const handleGetVideoSource = useCallback(async () => {
    if (!video) {
      return undefined;
    }

    if (!video.base64) {
      const result = await InfoService.selectVideo(video);
      return result.uri;
    }

    return video.base64;
  }, [video]);

  return (
    <div className="VideoPicker">
      {video && <Video getSource={handleGetVideoSource} />}

      <FileUploader ref={uploadRef} onFile={handleFile} onCancel={() => console.log('cancel')} />

      {video && (
        <>
          <IconButton className="edit-button" onClick={handleEditPress}>
            <EditTwoToneIcon />
          </IconButton>

          <IconButton className="delete-button" onClick={handleDeletePress}>
            <DeleteTwoToneIcon />
          </IconButton>
        </>
      )}

      {!video && (
        <Button variant="contained" onClick={handleEditPress} style={{ margin: 16 }}>
          Add Video
        </Button>
      )}
    </div>
  );
}

const InformationItem = ({ controller }) => (
  <div className="InformationItem">
    <title>Information Item</title>

    <Nav>
      <div className="content">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent className="actions">
                <div className="actions-left">
                  <IconButton onClick={controller.handleBackPress}>
                    <ArrowBackTwoToneIcon />
                  </IconButton>
                </div>

                <div className="actions-right">
                  <IconButton disabled={!controller.canSave} onClick={controller.handleUndoPress}>
                    <UndoTwoToneIcon />
                  </IconButton>

                  <IconButton disabled={!controller.canSave} onClick={controller.handleSavePress}>
                    <SaveTwoToneIcon />
                  </IconButton>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="header-card form">
              <CardContent>
                {(controller.isItemLoading || controller.loadingMessage) && <CircularProgress />}
                {!controller.isItemLoading && !!controller.item && (
                  <>
                    <div className="group">
                      <div className="control">
                        <TextField
                          label="Name"
                          variant="outlined"
                          style={{ width: '100%' }}
                          value={controller.item.name}
                          onChange={e => controller.handleNameChange(e.target.value)}
                        />
                      </div>

                      <div className="control">
                        <TextField
                          label="Link"
                          variant="outlined"
                          style={{ width: '100%' }}
                          value={controller.item.link}
                          onChange={e => controller.handleLinkChange(e.target.value)}
                        />
                      </div>
                    </div>

                    <Divider />

                    <div className="group horizontal media-group">
                      <div className="control">
                        <ImagePicker controller={controller} />
                      </div>

                      <div className="control">
                        <VideoPicker controller={controller} />
                      </div>
                    </div>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="form">
              <CardContent>
                {controller.isItemLoading && <CircularProgress />}
                {!controller.isItemLoading && !!controller.item && (
                  <MDEditor
                    value={controller.item.content}
                    height={500}
                    onChange={controller.handleContentChange}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Nav>
  </div>
);

export default InformationItem;
