import React, { useState, useContext } from 'react';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

import AuthService from '@/services/auth.service';
import UserService from '@/services/user.service';
import AuthContext from '@/core/AuthContext';

import './DeleteAccount.styles.scss';

const DeleteAccount = ({ history }) => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', error: false });

  const handleKickOut = async () => {
    await AuthService.logout();
    history.push('/login');
  };

  const onDeleteAccountPress = async () => {
    setIsLoading(true);

    if (
      window.confirm(
        'This will delete all of your data including, personal info, subscription and fitness progress and will also revoke your access to the application. \n\n  and Are you sure you want to delete your account?'
      )
    ) {
      const success = await UserService.delete(user);
      if (success) {
        setSnackbar({ open: true, message: 'Account Deleted', error: false });
        setTimeout(() => {
          handleKickOut();
        }, 5000);
        setIsLoading(false);
      } else {
        setSnackbar({ open: true, message: 'Failed to delete account', error: true });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="DeleteAccount">
      <div className="form-group">
        <Typography variant="h5" variantMapping={{ h5: 'h2' }}>
          Delete Account
        </Typography>
      </div>

      <Divider />

      <Typography component="p" className="deleteAccountDisclaimer">
        This will delete all of your data including, personal info, subscription and fitness
        progress and will also revoke your access to the application.
      </Typography>

      <div className="form-group">
        <div className="form-control">
          <Button
            className="deleteAccountButton"
            variant="contained"
            onClick={onDeleteAccountPress}
            disabled={isLoading}
          >
            Delete My Account
            {isLoading && <CircularProgress size={24} className="button-progress" />}
          </Button>
        </div>
      </div>

      <Snackbar
        open={snackbar.open}
        color="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={snackbar.error ? undefined : 5000}
        onClose={handleKickOut}
        message={snackbar.message}
      >
        <Alert severity="success">This is a success message!</Alert>
      </Snackbar>
    </div>
  );
};

export default withRouter(DeleteAccount);
