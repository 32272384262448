import { routeWithParams } from '@/core/router-helpers';

import { getAxios } from './service.helpers';

export default class ConfigService {
  static create = async collectionData => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/collection', collectionData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: collection = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return collection;
  };

  static read = async (collectionId, visible = true) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      routeWithParams('/collection/:collectionId?', {
        params: { collectionId },
        query: { visible }
      })
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: collections, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return collections;
  };

  static update = async (collectionId, updates) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.put(`/collection/${collectionId}`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: collection = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return collection;
  };

  static readItems = async collectionId => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/collection/${collectionId}/items`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: items, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return items;
  };

  static updateItems = async (collectionId, updatedItems) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.put(`/collection/${collectionId}/items`, {
      items: updatedItems
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: items, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return items;
  };

  static itemTypes = async () => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(`/collection/item-types`);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: types, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return types;
  };
}
