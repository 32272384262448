import { useState, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import QueryString from 'query-string';
import { useQuery } from 'react-query';

import { Spaces, spacesMiddleware } from '@fastercise/common-frontend';

import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';
import getIpData from '@/core/getIpData';

import ProductService from '@/services/product.service';
import CheckoutService from '@/services/checkout.service';

import AffiliatePlanCall from '@/store/affiliatePlan.call';

import ROUTES from '@/routes';

import { aggregatePriceData, byProductPrice } from './SelectPlan.helpers';
import SelectPlan from './SelectPlan.component';

export default PresentationContainer({
  component: SelectPlan,
  middleware: [spacesMiddleware([AffiliatePlanCall]), routerMiddleware()],
  controller: function SelectPlanController({ spaces, location, history }) {
    const [errors, setErrors] = useState([]);

    const query = QueryString.parse(location.search);

    let { sessionId } = query;
    const { affiliateCode } = query;

    const { affiliatePublicPlanCall } = spaces;

    const {
      isLoading: areProductsLoading,
      data: products = [],
      error: productsError,
    } = useQuery('ProductService.read()', () => ProductService.read());

    const { data: { data: { currency_code: currency } = {} } = {} } = useQuery('getIpData', () =>
      getIpData(),
    );

    useEffect(() => {
      if (affiliateCode) {
        Spaces.call(AffiliatePlanCall, affiliateCode);
      } else {
        Spaces.resetCall(AffiliatePlanCall);
      }
    }, []);

    useEffect(() => {
      if (productsError) {
        setErrors((prev) => [...prev, productsError.message]);
      }
    }, [productsError]);

    const sortedProducts = products.map(aggregatePriceData(currency)).sort(byProductPrice);

    const affiliatePlan = affiliatePublicPlanCall.data;

    const handleProductSelectPress = async (product) => {
      if (sessionId) {
        await CheckoutService.update(sessionId, {
          affiliateCode,
          priceId: product.priceData.id,
        });
      } else {
        ({ id: sessionId } = await CheckoutService.create({
          affiliateCode,
          priceId: product.priceData.id,
        }));
      }

      history.push(routeWithParams(ROUTES.CHECKOUT, { query: { sessionId } }));
    };

    const handleErrorsClose = () => setErrors([]);

    const affiliateBenefits = [
      ...(affiliatePlan?.affiliateMemberDiscount
        ? [`${affiliatePlan.affiliateMemberDiscount * 100}% off`]
        : []),
      ...(affiliatePlan?.affiliateMemberTrial
        ? [`a ${affiliatePlan.affiliateMemberTrial.join(' ')} free trial`]
        : []),
      ...(affiliatePlan?.affiliateMemberExempt ? ['free access'] : []),
    ];

    return {
      affiliatePlan,
      loading: areProductsLoading || affiliatePublicPlanCall.isLoading,
      products: sortedProducts,
      errors,
      affiliateBenefits,
      handleErrorsClose,
      handleProductSelectPress,
    };
  },
});
