import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import CollectionService from '@/services/collection.service';

import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';

import ROUTES from '@/routes';

import Collections from './Collections.component';

export default PresentationContainer({
  component: Collections,
  middleware: [routerMiddleware()],
  controller: function CollectionsController({ history }) {
    const [collectionName, setCollectionName] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    const {
      isLoading: isCollectionsLoading,
      error: colletionsError,
      data: collections = [],
      refetch: refetchCollections
    } = useQuery('CollectionService.read', () => CollectionService.read());

    const handleNewCollectionPress = useCallback(() => {
      setIsCreating(true);
    }, []);

    const handleCloseCreate = useCallback(() => {
      setCollectionName('');
      setIsCreating(false);
    }, []);

    const handleCollectionNameChange = useCallback(e => {
      setCollectionName(e.target.value);
    }, []);

    const handleCreatePress = useCallback(async () => {
      await CollectionService.create({ name: collectionName, visible: true });
      refetchCollections();
      handleCloseCreate();
    }, [collectionName]);

    const handleCollectionPress = useCallback(collection => {
      history.push(
        routeWithParams(ROUTES.ADMIN_COLLECTION, { params: { collectionId: collection.id } })
      );
    }, []);

    return {
      collections,
      isCreating,
      collectionName,
      handleNewCollectionPress,
      handleCloseCreate,
      handleCollectionNameChange,
      handleCreatePress,
      handleCollectionPress
    };
  }
});
