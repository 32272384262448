import moment from 'moment';

export const aggregatePriceData = (currency) => (product) => {
  // Ensure product and currency are valid
  if (!product || !Array.isArray(product.prices) || !currency) {
    throw new Error('Invalid product or currency input');
  }

  // Helper function to find the price in the specified currency
  const findPrice = (currencyToCheck) =>
    product.prices.find((p) => p.currency.toLowerCase() === currencyToCheck.toLowerCase());

  let price = findPrice(currency);

  // Fallback to 'USD' if price in the specified currency is not found
  if (!price) {
    price = findPrice('USD');
  }

  // Throw error if no valid price is found
  if (!price) {
    throw new Error(`No price found for currency ${currency} or 'USD'`);
  }

  return {
    ...product,
    priceData: {
      id: price.id,
      price: price.unit_amount / 100,
      interval: price.recurring?.interval || null,
      intervalCount: price.recurring?.interval_count || null,
      currency: price.currency,
    },
  };
};

export const byProductPrice = (a, b) => {
  const { price: priceA } = a.priceData;
  const { price: priceB } = b.priceData;

  if (priceA === priceB) return 0;

  return priceA < priceB ? -1 : 1;
};

export const getProductTrialDuration = (product, affiliatePlan) => {
  const { affiliateMemberTrial } = affiliatePlan || {};
  const { trialDuration } = product;

  if (!affiliateMemberTrial && !trialDuration) {
    return 'No Trial';
  }

  return `Free Trial: ${moment.duration(...(affiliateMemberTrial || trialDuration)).humanize()}`;
};

export const formatPriceInterval = (priceData) => {
  const { interval, intervalCount } = priceData;

  return intervalCount > 1 ? `${intervalCount} ${interval}s` : `${interval}`;
};

export const getDiscount = (priceData, affiliatePlan) => {
  if (!affiliatePlan) {
    return undefined;
  }

  const { affiliateMemberDiscount } = affiliatePlan;

  if (!affiliateMemberDiscount) {
    return undefined;
  }

  const { price } = priceData;

  return price - price * affiliateMemberDiscount;
};
