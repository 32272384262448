import React from 'react';

const Form = ({ onSubmit, ...restOfProps }) => (
  <form
    method="post"
    onSubmit={e => {
      e.preventDefault();
      onSubmit && onSubmit();
      return null;
    }}
    {...restOfProps}
  />
);

export default Form;
