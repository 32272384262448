import { match, compile } from 'path-to-regexp';
import QueryString from 'query-string';

export const pathMatchesRoute = (path, route) => {
  const matcher = match(route, { decode: decodeURIComponent });

  return !!matcher(path);
};

export const pathInRoutes = (path, routes) => routes.some(route => pathMatchesRoute(path, route));

export const routeWithParams = (route, { params = {}, query } = {}) => {
  const toPath = compile(route, { encode: encodeURIComponent });

  return `${toPath(params)}${query ? `?${QueryString.stringify(query)}` : ''}`;
};
