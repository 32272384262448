import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Video = ({ controller }) => (
  <div>
    {controller.isVisible && (
      <video src={controller.source} controls>
        <source src={controller.source} type="video/mp4" />
      </video>
    )}
    {!controller.isVisible && <CircularProgress />}
  </div>
);

export default Video;
