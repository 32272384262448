import { useState, useEffect } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';
import QueryString from 'query-string';

import ExerciseService from '@/services/exercise.service';

import routerMiddleware from '@/core/router-middleware';

import Exercieses from './Exercises.component';

export default PresentationContainer({
  component: Exercieses,
  middleware: [routerMiddleware()],
  controller: function ExerciesesController({ location }) {
    const { exerciseId } = QueryString.parse(location.search);
    const [selectedExercise, setSelectedExercise] = useState();

    console.log(exerciseId);

    const { isLoading, error, data: exercises = [], refetch } = useQuery('exercises', () =>
      ExerciseService.read()
    );

    useEffect(() => {
      if (isLoading) {
        return;
      }

      const exercise = exercises.find(({ id }) => (id = exerciseId));

      if (!exercise) {
        return;
      }

      setSelectedExercise(exercise);
    }, [isLoading]);

    const onExercisePress = (exercise) => () => {
      setSelectedExercise(exercise);
    };

    const onCreateExercisePress = () => {
      setSelectedExercise({});
    };

    const onRefreshList = (exercise) => {
      if (exercise) {
        setSelectedExercise(exercise);
      }
      refetch();
    };

    return {
      selectedExercise,
      isLoading,
      error,
      exercises,
      onExercisePress,
      onCreateExercisePress,
      onRefreshList,
    };
  },
});
