import sum from 'lodash/sum';

import { Async } from '@fastercise/common';

import { getAxios } from './service.helpers';

const MILLIES_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

const getNowInSeconds = () => Math.floor(Date.now() / MILLIES_IN_SECOND);
const secondsToMinutes = seconds => Math.floor(seconds / SECONDS_IN_MINUTE);

export default class InfoService {
  static _lastSpeedTest;

  static speedTest = async () => {
    if (this._lastSpeedTest) {
      const { averageSpeed, time } = this._lastSpeedTest;

      const diff = getNowInSeconds() - time;

      if (secondsToMinutes(diff) < 5) {
        return averageSpeed;
      }
    }

    const axios = await getAxios();

    const { status, statusText, data } = await axios.get('/info/speed-test');

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const {
      success,
      data: { url, size },
      error
    } = data;

    const PASSES_TO_RUN = 1;

    const passes = [];

    for (let i = 0; i < PASSES_TO_RUN; i++) {
      const start = Date.now();

      await fetch(url);

      const diff = Date.now() - start;
      const sTime = diff / 1000;
      const kbSize = size / 1024;
      const kbPerSecond = Math.floor(kbSize / sTime);

      passes.push(kbPerSecond);
    }

    const averageSpeed = Math.floor(sum(passes) / passes.length);

    this._lastSpeedTest = {
      averageSpeed,
      time: getNowInSeconds()
    };

    if (!success) {
      throw error;
    }

    return averageSpeed;
  };

  static selectVideo = async (videoKey, bitrate) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/info/select-video', {
      videoKey,
      bitrate
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: videoData, error } = data;

    if (!success) {
      throw error;
    }

    return videoData;
  };

  static selectImage = Async.memoize(async (imageKey, preset) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post('/info/select-image', {
      imageKey,
      preset
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: videoData, error } = data;

    if (!success) {
      throw error;
    }

    return videoData;
  });
}
