import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import PlanCard from '@/components/PlanCard';
import Snackbar from '@/components/Snackbar';

import burstLogoLongWhite from '@/assets/burst-logo-long-white.png';

import { formatPriceInterval } from './SelectPlan.helpers';
import './SelectPlan.styles.scss';

const SelectPlan = ({ controller }) => (
  <div className="SelectPlan">
    <title>Select Plan</title>
    <Grid container spacing={4} className="price-grid">
      <Grid item xs={12} lg={9}>
        <div className="header">
          <div className="long-logo-wrap">
            <img src={burstLogoLongWhite} className="logo" alt="burst fitness" />
          </div>
        </div>

        {controller.affiliateBenefits.length > 0 && (
          <div className="affiliate-discount">
            <Typography variant="h5">
              {`Your affiliate gave you ${controller.affiliateBenefits.join(' and ')}!`}
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>

    <Grid container spacing={4} className="price-grid">
      {controller.loading && (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        >
          <CircularProgress color="secondary" />
        </Grid>
      )}
      {controller.products.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
          <PlanCard
            name={product.name}
            price={get(product, 'priceData.price')}
            currency={get(product, 'priceData.currency')}
            interval={formatPriceInterval(product.priceData)}
            exempt={get(controller, 'affiliatePlan.affiliateMemberExempt')}
            discount={get(controller, 'affiliatePlan.affiliateMemberDiscount', undefined)}
            trial={get(controller, 'affiliatePlan.affiliateMemberTrial') || product.trialDuration}
            features={['Exercise Catalog', 'Burst Connect', 'Journal', 'Reminders', 'Support']}
            onPress={() => controller.handleProductSelectPress(product)}
          />
        </Grid>
      ))}
    </Grid>

    <Snackbar
      open={controller.errors.length > 0}
      message={controller.errors.join(' ')}
      onClose={controller.handleErrorsClose}
    />
  </div>
);

SelectPlan.propTypes = {
  controller: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired,
    affiliatePlan: PropTypes.shape({
      affiliateMemberExempt: PropTypes.bool.isRequired,
      affiliateMemberDiscount: PropTypes.number,
      affiliateMemberTrial: PropTypes.shape({
        0: PropTypes.number.isRequired,
        1: PropTypes.string.isRequired,
        length: PropTypes.oneOf([2]).isRequired,
      }),
    }).isRequired,
    affiliateBenefits: PropTypes.arrayOf(PropTypes.string).isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        trialDuration: PropTypes.shape({
          0: PropTypes.number.isRequired,
          1: PropTypes.string.isRequired,
          length: PropTypes.oneOf([2]).isRequired,
        }),
        priceData: PropTypes.shape({
          price: PropTypes.number.isRequired,
          currency: PropTypes.number.isRequired,
        }),
      }),
    ).isRequired,
    handleProductSelectPress: PropTypes.func.isRequired,
    handleErrorsClose: PropTypes.func.isRequired,
  }).isRequired,
};

export default SelectPlan;
