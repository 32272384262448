import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import ArrowForwardTwoToneIcon from '@material-ui/icons/ArrowForwardTwoTone';

import { KeyboardDatePicker } from '@material-ui/pickers';

const SignUpFunnel = ({ controller }) => {
  const [firstSegment] = controller.signUpFunnel;
  const { [controller.signUpFunnel.length - 1]: lastSegment } = controller.signUpFunnel;

  const { count: firstSegmentCount } = firstSegment || {};
  const { count: lastSegmentCount = 0 } = lastSegment || {};

  const funnelConversionRate = (lastSegmentCount / (firstSegmentCount || 1)) * 100;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
      <Typography variant="h4">{`Sign-Up Funnel (${funnelConversionRate.toFixed(2)}%)`}</Typography>

      <div style={{ padding: '16px' }}>
        <TextField
          label="Search... "
          value={controller.search}
          onChange={controller.handleSearchChange}
        />
      </div>

      {controller.isSignUpFunnelLoading && <CircularProgress size={50} />}

      {!controller.isSignUpFunnelLoading && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {controller.signUpFunnel.map(({ step, count }, index) => {
            const { [index + 1]: nextSegment } = controller.signUpFunnel;
            const { count: nextSegmentCount = 0 } = nextSegment || {};

            const segmentConversionRate = (nextSegmentCount / (count || 1)) * 100;

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#eee',
                    marginRight: '16px',
                    marginLeft: '16px',
                    padding: '16px',
                    borderRadius: '16px'
                  }}
                >
                  <Typography variant="caption">{step}</Typography>
                  <Typography variant="body1">{count}</Typography>
                </div>
                {nextSegment && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ArrowForwardTwoToneIcon
                      style={{ fontSize: 40, marginRight: '8px', marginLeft: '8px' }}
                    />
                    <Typography>{`${segmentConversionRate.toFixed(2)}%`}</Typography>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Funnels = ({ controller }) => (
  <div className="Funnels">
    <div style={{ padding: '16px' }}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="YYYY/MM/DD"
        margin="normal"
        label="Start Date"
        value={controller.startDate}
        onChange={controller.handleStartDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />

      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="YYYY/MM/DD"
        margin="normal"
        label="End Date"
        value={controller.endDate}
        onChange={controller.handleEndDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </div>

    <div>
      <SignUpFunnel controller={controller} />
    </div>
  </div>
);

export default Funnels;
