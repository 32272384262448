import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQueries } from 'react-query';
import moment from 'moment';

import ReportService from '@/services/report.service';
import SubscriptionService from '@/services/subscription.service';

import Overview from './Overview.component';

export default PresentationContainer({
  component: Overview,
  controller: function OverviewController() {
    const [isSyncing, setIsSyncing] = useState(false);
    const [activeLoginDays, setActiveLoginDays] = useState(7);

    const [
      {
        isLoading: overviewLoading,
        error: overviewError,
        data: overview = [],
        refetch: refetchOverview
      },
      {
        isLoading: userCountLoading,
        error: userCountError,
        data: userCount = [],
        refetch: refetchUserCount
      },
      {
        isLoading: countryOverviewLoading,
        error: countryOverviewError,
        data: countryOverview = [],
        refetch: refetchCountryOverview
      },
      {
        isLoading: activeLoginsLoading,
        error: activeLoginsError,
        data: activeLogins = [],
        refetch: refetchActiveLogins
      },
      {
        isLoading: durationLoading,
        error: durationError,
        data: durations = [],
        refetch: refetchDuration
      },
      {
        isLoading: pageViewsLoading,
        error: pageViewsError,
        data: pageViews = {},
        refetch: refetchPageViews
      }
    ] = useQueries([
      { queryKey: 'ReportService.adminOverview', queryFn: () => ReportService.adminOverview() },
      { queryKey: 'ReportService.userCount', queryFn: () => ReportService.userCount() },
      {
        queryKey: 'ReportService.adminOverview(country)',
        queryFn: () => ReportService.adminOverview('country')
      },
      {
        queryKey: `ReportService.activeLogins(${JSON.stringify({ days: activeLoginDays })})`,
        queryFn: () => ReportService.activeLogins({ days: activeLoginDays })
      },
      { queryKey: 'ReportService.accountDuration', queryFn: () => ReportService.accountDuration() },
      { queryKey: 'ReportService.pageViews', queryFn: () => ReportService.pageViews() }
    ]);

    const refetch = () => {
      refetchOverview();
      refetchCountryOverview();
      refetchUserCount();
      refetchActiveLogins();
      refetchDuration();
      refetchPageViews();
    };

    const handleSyncSubscriptionPress = async () => {
      setIsSyncing(true);
      await SubscriptionService.sync();
      setIsSyncing(false);
      refetch();
    };

    const handleSetActiveLoginDays = useCallback(days => {
      setActiveLoginDays(days);
    }, []);

    return {
      isLoading:
        overviewLoading ||
        countryOverviewLoading ||
        userCountLoading ||
        activeLoginsLoading ||
        durationLoading ||
        pageViewsLoading,
      error:
        overviewError ||
        countryOverviewError ||
        userCountError ||
        activeLoginsError ||
        durationError ||
        pageViewsError,
      overview,
      countryOverview,
      userCount: userCount.map(countGroup => {
        if (countGroup.day) {
          return {
            ...countGroup,
            date: moment(countGroup.day, 'YYYY-MM-DD').startOf('day')
          };
        }
      }),
      activeLogins,
      durations,
      pageViews,
      isSyncing,
      activeLoginDays,
      handleSyncSubscriptionPress,
      handleSetActiveLoginDays
    };
  }
});
