import get from 'lodash/get';

const ITEM_TYPE = {
  INFORMATION: 'Information',
  EXERCISE: 'Exercise',
};

const getItemName = (item) => 
  get(item, [item.type, 'name']);

const getItemType = (item) => {
    if (item.information) {
      return ITEM_TYPE.INFORMATION;
    }
  
    if (item.exercise) {
      return ITEM_TYPE.EXERCISE;
    }
};

export const getItemPrimaryText = (item) => {
  return item.label || getItemName(item);
}

export const getItemSecondaryText = (item) => {
  return item.label ? `${getItemName(item)} (${getItemType(item)})` : getItemType(item);
}
