import { useState, useEffect, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';

import assign from 'lodash/assign';

import { Async } from '@fastercise/common';

import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';

import InformationService from '@/services/information.service';

import ROUTES from '@/routes.js';

import InformationItem from './InformationItem.component';

const loadingMessageMap = {
  thumb: 'Uploading Thumbnail',
  video: 'Uploading Video'
};

export default PresentationContainer({
  component: InformationItem,
  middleware: [routerMiddleware()],
  controller: function InformationItemController({ match, history }) {
    const { itemId } = match.params;

    const [loadingMessage, setLoadingMessage] = useState();

    const {
      isLoading: isItemLoading,
      error: itemError,
      data: item,
      refetch: refetchItem
    } = useQuery(`InformationService.read(${itemId})`, () => InformationService.read(itemId), {
      enabled: !!itemId
    });

    const [itemUpdates, setItemUpdates] = useState({});

    const itemState = assign(item, itemUpdates);

    const handleBackPress = useCallback(() => {
      history.goBack();
    }, []);

    const handleNameChange = useCallback(
      value => setItemUpdates(prev => ({ ...prev, name: value })),
      []
    );

    const handleLinkChange = useCallback(value =>
      setItemUpdates(prev => ({ ...prev, link: value }))
    );

    const handleContentChange = useCallback(value => {
      setItemUpdates(prev => ({ ...prev, content: value }));
    }, []);

    const handleThumbChange = useCallback(value => {
      setItemUpdates(prev => ({ ...prev, thumb: value, thumbKey: undefined }));
    }, []);

    const handleVideoChange = useCallback(value => {
      setItemUpdates(prev => ({ ...prev, video: value }));
    }, []);

    const handleUndoPress = useCallback(() => {
      setItemUpdates(item);
    }, [item]);

    const handleSavePress = useCallback(async () => {
      setLoadingMessage('Saving Item');

      const errors = [
        ...(!itemState.name ? ['Name is required'] : []),
        ...(!itemState.thumb ? ['Thumbnail is required'] : []),
        ...(!itemState.content ? ['Content is required'] : [])
      ];

      if (errors.length > 0) {
        alert(errors.join(', '));
        setLoadingMessage();
        return;
      }

      const updates = await Async.mapValues(itemUpdates, async (value, key) => {
        if (!value || !['thumb', 'video'].includes(key)) {
          /*
           we want to return null to null out the field in the db
           this will allow the mobile app to correctly display the state
          */
          return value || null;
        }

        setLoadingMessage(loadingMessageMap[key]);

        const { file, type } = value;
        const s3Key = await InformationService.uploadFile(file, type);

        setLoadingMessage('Saving Item');

        return s3Key;
      });

      if (itemId) {
        await InformationService.update(itemId, updates);
        refetchItem();
      } else {
        const newItem = await InformationService.create(updates);

        history.replace(
          routeWithParams(ROUTES.ADMIN_INFORMATION_ITEM, { params: { itemId: newItem.id } })
        );
      }

      setItemUpdates({});
      setLoadingMessage();
    }, [item, itemUpdates]);

    const canSave = Object.keys(itemUpdates).length > 0;

    return {
      canSave,
      isItemLoading,
      itemError,
      item: itemState,
      loadingMessage,
      handleBackPress,
      handleUndoPress,
      handleNameChange,
      handleLinkChange,
      handleContentChange,
      handleThumbChange,
      handleVideoChange,
      handleSavePress
    };
  }
});
