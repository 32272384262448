import PresentationContainer from 'react-presentation-container';

import routerMiddleware from '@/core/router-middleware';
import ROUTES from '@/routes';

import Home from './Home.component';

export default PresentationContainer({
  component: Home,
  middleware: [routerMiddleware()],
  controller: function HomeController({ history }) {
    const onLogoutPress = () => {
      history.push(ROUTES.LOGOUT);
    };

    return { onLogoutPress };
  }
});
