export default class Async {
  static map = async (arr, callback) => {
    const results = [];

    for (let i = 0; i < arr.length; i += 1) {
      results.push(await callback(arr[i], i, arr)); // eslint-disable-line no-await-in-loop
    }

    return results;
  };

  static reduce = async (arr, callback, initialValue) => {
    let prevResult = initialValue;

    for (let i = 0; i < arr.length; i += 1) {
      prevResult = await callback(prevResult, arr[i], i, arr); // eslint-disable-line no-await-in-loop
    }

    return prevResult;
  };
}
