import { sleep } from '@fastercise/common';
import { getAxios } from './service.helpers';

export default class InformationService {
  static read = async (itemId) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      `/information${itemId ? `/${itemId}` : ''}`
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: item = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return item;
  };

  static update = async (itemId, updates) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.put(`/information/${itemId}`, updates);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: item = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return item;
  };

  static create = async (informationData) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.post(`/information`, informationData);

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: item = {}, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return item;
  };

  static getPresignedUrl = async (contentType) => {
    const axios = await getAxios();

    const { status, statusText, data } = await axios.get(
      `/information/create-presigned?contentType=${contentType}`
    );

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    const { success, data: presignedUrl, error } = data;

    if (!success) {
      throw new Error(error.message);
    }

    return presignedUrl;
  };

  static uploadFile = async (file, type) => {
    const { url, key } = await this.getPresignedUrl(type);

    const { status, statusText } = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': type,
        // reportProgress: true,
      },
    });

    if (status < 200 || status > 299) {
      throw new Error(statusText);
    }

    return key;
  };
}
