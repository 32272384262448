import React from 'react';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import './InformationItems.styles.scss';

const InformationItems = ({ controller }) => (
  <div className="InformationItems">
    <div>
      <div className="actions">
        <IconButton onClick={controller.handleAddItemPress}>
          <AddTwoToneIcon />
        </IconButton>
      </div>

      <Divider />
    </div>

    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Thumb</TableCell>
              <TableCell>Video Key</TableCell>
              <TableCell>Link</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {controller.itemsError && (
              <TableRow>
                <TableCell colSpan={5}>
                  <span>{controller.itemsError.message}</span>
                </TableCell>
              </TableRow>
            )}
            {controller.areItemsLoading && <CircularProgress />}
            {controller.informationItems.map(({ id, name, thumb, videoKey, link }) => (
              <TableRow
                key={id}
                onClick={() => controller.handleItemPress(id)}
                hover
                className="information-item-row"
              >
                <TableCell>{id}</TableCell>
                <TableCell>{name}</TableCell>
                <TableCell>{thumb}</TableCell>
                <TableCell>{videoKey}</TableCell>
                <TableCell>{link}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </div>
);

export default InformationItems;
