import React from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import IconCloseTwoTone from '@material-ui/icons/CloseTwoTone';

import Modal from '@/components/Modal';

import './UserSessions.styles.scss';

const SessionModal = ({ session, onClose, ...restOfProps }) => (
  <Modal {...restOfProps} className="SessionModal" onClose={onClose} visible>
    <div>
      <div
        style={{ padding: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <IconButton onClick={onClose}>
          <IconCloseTwoTone />
        </IconButton>
      </div>

      <Divider style={{ height: 4 }} />

      <div style={{ padding: 8 }}>
        <Typography>User Data</Typography>
      </div>

      <Divider style={{ height: 4 }} />

      <div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="right" className="user-data-descriptor">
                Id
              </TableCell>
              <TableCell>{session.user?.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="user-data-descriptor">
                First Name
              </TableCell>
              <TableCell>{session.user?.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="user-data-descriptor">
                Last Name
              </TableCell>
              <TableCell>{session.user?.lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className="user-data-descriptor">
                Email
              </TableCell>
              <TableCell>{session.user?.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <Divider style={{ height: 4 }} />

      <div style={{ padding: 8 }}>
        <Typography>Checkout Sessions</Typography>
      </div>

      <Divider style={{ height: 4 }} />

      <div>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Client Ip</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Postal Code</TableCell>
              <TableCell>Affiliate Code</TableCell>
              <TableCell>Complete</TableCell>
              <TableCell>CreateTime</TableCell>
              <TableCell>UpdateTime</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {session.checkoutSessions.length < 1 && (
              <TableRow>
                <TableCell colSpan={10}>None</TableCell>
              </TableRow>
            )}
            {/* 2021-10-13T17:09:04.000Z */}
            {session.checkoutSessions.map(session => (
              <TableRow role="checkbox" key={session.id}>
                <TableCell>{session.id}</TableCell>
                <TableCell>{session.ip}</TableCell>
                <TableCell>{session.firstName}</TableCell>
                <TableCell>{session.lastName}</TableCell>
                <TableCell>{session.email}</TableCell>
                <TableCell>{session.country}</TableCell>
                <TableCell>{session.postalCode}</TableCell>
                <TableCell>{session.affiliateCode}</TableCell>
                <TableCell>{session.complete}</TableCell>
                <TableCell>
                  {moment(`${session.createTime}+00:00`, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'YYYY-MM-DD hh:mm:ss A'
                  )}
                </TableCell>
                <TableCell>
                  {moment(`${session.updateTime}+00:00`, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'YYYY-MM-DD hh:mm:ss A'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Divider style={{ height: 4 }} />

      <div style={{ padding: 8 }}>
        <Typography>Entries</Typography>
      </div>

      <Divider style={{ height: 4 }} />

      <div>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Client Ip</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Meta</TableCell>
              <TableCell>CreateTime</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {session.entries.length < 1 && (
              <TableRow>
                <TableCell>None</TableCell>
              </TableRow>
            )}
            {/* 2021-10-13T17:09:04.000Z */}
            {session.entries.map(entry => (
              <TableRow role="checkbox" key={entry.id}>
                <TableCell>{entry.id}</TableCell>
                <TableCell>{entry.ip}</TableCell>
                <TableCell>{entry.source}</TableCell>
                <TableCell>{entry.eventType}</TableCell>
                <TableCell>{JSON.stringify(entry.meta, null, 2)}</TableCell>
                <TableCell>
                  {moment(`${entry.createTime}+00:00`, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                    'YYYY-MM-DD hh:mm:ss A'
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  </Modal>
);

const UserSessions = ({ controller }) => (
  <div className="UserSessions">
    {controller.selectedSession && (
      <SessionModal
        session={controller.selectedSession}
        onClose={controller.handleSessionModalClose}
      />
    )}
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className="user-sessions-table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Checkouts</TableCell>
            <TableCell>Entries</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {controller.sessions.map(session => (
            <TableRow
              hover
              role="checkbox"
              onClick={() => controller.handleSessionPress(session)}
              key={session.id}
            >
              <TableCell>{session.id}</TableCell>
              <TableCell style={!session?.user?.firstName ? { color: 'red' } : undefined}>
                {session?.user?.firstName || 'None'}
              </TableCell>
              <TableCell style={!session?.user?.lastName ? { color: 'red' } : undefined}>
                {session?.user?.lastName || 'None'}
              </TableCell>
              <TableCell style={!session?.user?.email ? { color: 'red' } : undefined}>
                {session?.user?.email || 'None'}
              </TableCell>
              <TableCell>{session.checkoutSessions.length}</TableCell>
              <TableCell>{session.entries.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {controller.isLoading && <CircularProgress size={50} />}
  </div>
);

export default UserSessions;
