import { useState, useEffect, useContext, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import QueryString from 'query-string';

import get from 'lodash/get';

import { TryCall } from '@fastercise/common';

import AuthContext from '@/core/AuthContext';
import stripeMiddleware from '@/core/stripe.middleware';
import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';
import getIpData from '@/core/getIpData';

import UserService from '@/services/user.service';
import StripeService from '@/services/stripe.service';

import ROUTES from '@/routes';

import ChangePaymentMethod from './ChangePaymentMethod.component';

const ALTERNATE_PAYMENT_METHODS = {
  usd: [],
  eur: ['bancontact']
};

export default PresentationContainer({
  component: ChangePaymentMethod,
  middleware: [stripeMiddleware(), routerMiddleware()],
  controller: function ChangePaymentMethodController({ history, location, locale }) {
    const { user, refetch } = useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();

    const { setup_intent: intentId } = QueryString.parse(location.search);

    const [cardName, setCardName] = useState('');
    const [isEditModeActive, setIsEditModeActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isCheckingPayment, setIsCheckingPayment] = useState(false);

    const [errors, setErrors] = useState({});

    const verifySetupIntent = async () => {
      const { res: setupIntent, err: setupIntentError } = await TryCall(
        StripeService.getIntent(intentId)
      );

      if (!setupIntentError && setupIntent.status === 'succeeded') {
        await UserService.update({ stripePaymentMethodId: setupIntent.paymentMethod });
        history.replace(routeWithParams(ROUTES.ACCOUNT));
        refetch();
      } else {
        setErrors({ alert: ['Failed to update payment method'] });
      }

      setIsCheckingPayment(false);
    };

    useEffect(() => {
      if (stripe && intentId) {
        setIsCheckingPayment(true);
        verifySetupIntent();
      }
    }, [stripe, intentId]);

    const onCardNameChange = useCallback(e => setCardName(e.target.value));

    const onEditPress = useCallback(() => setIsEditModeActive(true));
    const onEditCancel = useCallback(() => setIsEditModeActive(false));

    const handleChangePaymentMethodPress = useCallback(async () => {
      setIsSubmitting(true);
      const formErrors = {};

      if (!cardName) {
        formErrors.cardName = ['Required'];
      }

      const card = elements.getElement(CardElement);
      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: cardName
        }
      });

      if (paymentMethodError && paymentMethodError.code !== 'parameter_invalid_empty') {
        formErrors.paymentMethod = [paymentMethodError.message];
      }

      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setIsSubmitting(false);
        return;
      }

      try {
        await UserService.update({ stripePaymentMethodId: paymentMethod.id });
        refetch();
        setIsEditModeActive(false);
      } catch (e) {
        setErrors({ form: [e.message] });
      }
      setIsSubmitting(false);
    });

    const handleBancontactPress = async () => {
      setIsCheckingPayment(true);

      const { firstName, lastName, email } = user;

      const { clientSecret } = await StripeService.createIntent({
        ...(user.subscription?.stripeAccountId
          ? {
              accountId: user.subscription.stripeAccountId
            }
          : {
              firstName,
              lastName,
              email
            })
      });

      await stripe.confirmBancontactSetup(clientSecret, {
        payment_method: {
          billing_details: {
            name: `${firstName} ${lastName}`,
            email
          }
        },
        return_url: window.location.href
      });
      setIsCheckingPayment(false);
    };

    const handleCloseAlert = () => {
      setErrors(({ alert, ...restOfErrors }) => restOfErrors);
    };

    const alternatePaymentMethods =
      ALTERNATE_PAYMENT_METHODS[
        get(user, 'subscription.price.currency', locale.currency).toLowerCase()
      ];

    const hasPostalCode = !!user.postalCode;

    return {
      cardName,
      errors: {
        ...errors,
        ...(hasPostalCode
          ? {}
          : { form: ['Missing postal code', "Add postal code under 'User Details'"] })
      },
      isEditModeActive,
      isCheckingPayment,
      hasPostalCode,
      paymentMethod: user.paymentMethod,
      subscription: user.subscription,
      isSubmitting,
      alternatePaymentMethods,
      handleCloseAlert,
      handleChangePaymentMethodPress,
      handleBancontactPress,
      onCardNameChange,
      onEditPress,
      onEditCancel
    };
  }
});
