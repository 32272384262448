import React from 'react';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AssignmentReturnedTwoToneIcon from '@material-ui/icons/AssignmentReturnedTwoTone';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import CodeTwoToneIcon from '@material-ui/icons/CodeTwoTone';

import Nav from '@/components/Nav';

import './Affiliate.styles.scss';

const Affiliate = ({ controller }) => (
  <div className="Affiliate">
    <title>Affiliate</title>
    <Nav>
      <div className="content">
        <div className="liner">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography style={{ marginBottom: '8px' }}>Member Count</Typography>
                  <Typography style={{ marginBottom: '8px' }} variant="h2">
                    {controller.memberCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography style={{ marginBottom: '8px' }}>Plan Info</Typography>
                  {controller.plan.affiliateRate && (
                    <Typography style={{ marginBottom: '8px' }} variant="h6">
                      {`Profit Share Rate: ${controller.plan.affiliateRate * 100}%`}
                    </Typography>
                  )}
                  {controller.plan.affiliateMemberDiscount && (
                    <Typography style={{ marginBottom: '8px' }} variant="h6">
                      {`Member Discount: ${controller.plan.affiliateMemberDiscount * 100}%`}
                    </Typography>
                  )}
                  {controller.plan.affiliateMemberTrial && (
                    <Typography style={{ marginBottom: '8px' }} variant="h6">
                      {`Member Trial: ${controller.plan.affiliateMemberTrial.join(' ')}`}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      value={controller.link}
                      label="Link"
                      variant="outlined"
                      style={{ flex: 1 }}
                      inputRef={controller.linkRef}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        aria-label="directions"
                        style={{ marginLeft: '8px' }}
                        onClick={controller.handleCopyLinkPress}
                      >
                        <AssignmentReturnedTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography>Embed HTML Button</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ marginBottom: '8px' }}>
                      <ToggleButtonGroup
                        exclusive
                        aria-label="text alignment"
                        value={controller.buttonVariant}
                        onChange={controller.handleButtonVariantChange}
                      >
                        <ToggleButton value="dark" aria-label="left aligned">
                          <Typography>Dark</Typography>
                        </ToggleButton>
                        <ToggleButton value="light" aria-label="centered">
                          <Typography>Light</Typography>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>

                    <div style={{ display: 'flex', marginBottom: '8px' }}>
                      <div
                        style={{
                          padding: '8px',
                          border: `1px dashed #bbb`,
                          display: 'inline-block',
                          borderRadius: '5px'
                        }}
                      >
                        <a
                          href={controller.link}
                          ref={controller.buttonRef}
                          style={{
                            display: 'inline-block',
                            backgroundColor:
                              controller.buttonVariant === 'dark' ? '#343434' : '#A9EFF0',
                            width: 80,
                            height: 80,
                            boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.73)',
                            borderRadius: '5px'
                          }}
                        >
                          <img
                            src={controller.imageUrl}
                            alt="Burst Fitness"
                            style={{ height: '100%', width: '100%' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div>
                      <Button
                        startIcon={<CodeTwoToneIcon />}
                        variant="outlined"
                        onClick={controller.handleCopyHtml}
                      >
                        Copy Html
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography style={{ marginBottom: '8px' }}>Invite via Email</Typography>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      style={{ flex: 1 }}
                      value={controller.emails}
                      onChange={controller.handleEmailsChange}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        aria-label="directions"
                        style={{ marginLeft: '8px' }}
                        onClick={controller.handleSendEmailsPress}
                        disabled={controller.isSendingEmails}
                      >
                        {!controller.isSendingEmails && <SendTwoToneIcon />}
                        {controller.isSendingEmails && (
                          <CircularProgress size={24} color="secondary" />
                        )}
                      </IconButton>
                    </div>
                  </div>
                  <FormHelperText>
                    Separate emails by a comma. (john@mail.com, jane@mail.com, morgan@mail.com)
                  </FormHelperText>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Card}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>FirstName</TableCell>
                      <TableCell>LastName</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Subscription Type</TableCell>
                      <TableCell>Active</TableCell>
                      <TableCell>Trial</TableCell>
                      <TableCell>Cancelled</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Last Active</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {controller.members.map(
                      ({
                        id,
                        type,
                        active,
                        trialEnd,
                        isCancelled,
                        firstName,
                        lastName,
                        email,
                        createTime,
                        country,
                        lastTouch
                      }) => (
                        <TableRow key={id}>
                          <TableCell>{id}</TableCell>
                          <TableCell>{firstName}</TableCell>
                          <TableCell>{lastName}</TableCell>
                          <TableCell>{email}</TableCell>
                          <TableCell>{type}</TableCell>
                          <TableCell style={{ color: active ? 'green' : 'red' }}>
                            {active ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell style={{ color: trialEnd ? 'blue' : undefined }}>
                            {trialEnd ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell style={{ color: isCancelled ? 'red' : 'green' }}>
                            {isCancelled ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell>
                            {moment(createTime).format('MMM DD, YYYY HH:mm:ss')}
                          </TableCell>
                          <TableCell>{country}</TableCell>
                          <TableCell>
                            {lastTouch
                              ? moment(lastTouch).format('MMM DD, YYYY HH:mm:ss')
                              : 'Never'}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={!!controller.snackbarMessage}
            autoHideDuration={3000}
            onClose={controller.handleSnackbarClose}
          >
            <Alert
              variant="filled"
              elevation={6}
              severity={controller.snackbarMessage?.severity || 'info'}
            >
              {controller.snackbarMessage?.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </Nav>
  </div>
);

export default Affiliate;
