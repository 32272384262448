import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PUBLIC_KEY } from '@/constants/stripe';

export default () => Component =>
  function StripeMiddleware(props) {
    const stripeState = loadStripe(PUBLIC_KEY);

    return (
      <Elements stripe={stripeState}>
        <Component {...props} />
      </Elements>
    );
  };
