import React from 'react';

import Typography from '@material-ui/core/Typography';

import logo from '@/assets/burst-logo-black.png';

import './CheckoutComplete.styles.scss';

const CheckoutComplete = () => (
  <div className="CheckoutComplete">
    <div className="liner">
      <title>Account Created</title>
      <div className="header">
        <div className="logo-wrap">
          <img src={logo} className="logo" alt="burst fitness" />
        </div>
      </div>
      <Typography align="center">Account created successfully!</Typography>
      <Typography style={{ marginTop: '16px', maxWidth: '280px' }} align="center">
        To get started, download our mobile app and login with your new credentials.
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '32px' }}>
        <a
          href="https://apps.apple.com/us/app/fastercise/id1545070683?itsct=apps_box_badge&amp;itscg=30200"
          style={{
            display: 'inline-block',
            overflow: 'hidden'
          }}
        >
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1620259200&h=f6f2334ae1684d0437a2e7cf905963f4"
            alt="Download on the App Store"
            style={{ borderRadius: '13px', width: '128px' }}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.fastercise.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            style={{ borderRadius: '13px', width: '148px' }}
          />
        </a>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '32px' }}>
        <a href={window.location.origin}>Manage your account</a>
      </div>
    </div>
  </div>
);

export default CheckoutComplete;
