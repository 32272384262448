import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';
import { useQuery } from 'react-query';
import { v4 as uuid } from 'uuid';

import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import routerMiddleware from '@/core/router-middleware';
import { routeWithParams } from '@/core/router-helpers';

import CollectionService from '@/services/collection.service';

import ROUTES from '@/routes';

import Collection from './Collection.component';

const EDITABLE_DETAILS_FIELDS = ['name'];

export default PresentationContainer({
  component: Collection,
  middleware: [routerMiddleware()],
  controller: function CollectionController({ match, history }) {
    const { collectionId } = match.params;

    const [isAddItemVisible, setIsAddItemVisible] = useState(false);

    const [details, setDetails] = useState();

    const [updatedItems, setUpdatedItems] = useState();

    const {
      isLoading: isCollectionLoading,
      error: collectionError,
      data: collection,
      refetch: refetchCollection
    } = useQuery(
      `CollectionService.read(${collectionId})`,
      () => CollectionService.read(collectionId),
      {
        disabled: !!collectionId
      }
    );

    const {
      isLoading: areItemsLoading,
      error: itemsError,
      data: items = [],
      refetch: refetchItems
    } = useQuery(
      `CollectionService.readItems(${collectionId})`,
      () => CollectionService.readItems(collectionId),
      {
        disabled: !!collectionId
      }
    );

    const { orderedItems, unorderedItems } = (updatedItems || items).reduce(
      (prev, item) => ({
        ...prev,
        ...(item.displayOrder
          ? { orderedItems: [...prev.orderedItems, item] }
          : { unorderedItems: [...prev.unorderedItems, item] })
      }),
      { orderedItems: [], unorderedItems: [] }
    );

    const handleBackPress = useCallback(() => {
      history.goBack();
    }, []);

    const handleDetailsChange = (field, value) =>
      setDetails((prev = {}) => ({ ...prev, [field]: value }));

    const handleItemsChange = updates =>
      setUpdatedItems(() => [
        ...unorderedItems,
        ...updates.map((item, index) => ({ ...item, displayOrder: index + 1 }))
      ]);

    const handleCheckToggle = useCallback(
      target => {
        setUpdatedItems((prevItems = items) => {
          const highestOrder = prevItems.reduce((prev, itm) => {
            if (itm.displayOrder > prev) {
              return itm.displayOrder;
            }

            return prev;
          }, 0);

          const order = isNil(target.displayOrder) ? highestOrder + 1 : undefined;

          return prevItems.map(itm =>
            itm.id === target.id ? { ...itm, displayOrder: order } : itm
          );
        });
      },
      [items, setUpdatedItems]
    );

    const handleDetailsSavePress = useCallback(async () => {
      await CollectionService.update(collectionId, details);
      refetchCollection();
      setDetails();
    }, [collectionId, details, setDetails, refetchCollection]);

    const handleItemsSavePress = useCallback(async () => {
      const formattedUpdates = updatedItems.map(item => {
        return {
          ...pick(item, ['type', 'label']),
          displayOrder: item.displayOrder || null,
          completable: item.completable || false,
          id: get(item, [item.type, 'id'])
        };
      });

      await CollectionService.updateItems(collectionId, formattedUpdates);
      setUpdatedItems();
      refetchItems();
    }, [updatedItems]);

    const handleAddItemPress = useCallback(() => {
      setIsAddItemVisible(true);
    }, []);

    const handleAddItem = useCallback(
      itemType => {
        setIsAddItemVisible(false);

        const existingItem = (updatedItems || items).find(
          ({ type, ...rest }) => type === itemType.type && rest[type].id === itemType.id
        );

        if (existingItem) {
          return;
        }

        setUpdatedItems((prev = items) => [
          ...prev,
          {
            ...pick(itemType, ['type']),
            id: `add:${uuid()}`,
            displayOrder: null,
            [itemType.type]: omit(itemType, ['type'])
          }
        ]);
      },
      [setUpdatedItems, updatedItems, items]
    );

    const handleCompletablePress = useCallback(
      item =>
        setUpdatedItems((prev = items) =>
          prev.map(prevItem => {
            if (prevItem.id === item.id) {
              return { ...prevItem, completable: !prevItem.completable };
            }
            return prevItem;
          })
        ),
      [setUpdatedItems, items]
    );

    const handleLabelChange = useCallback(
      (item, label) => {
        setUpdatedItems((prev = items) =>
          prev.map(prevItem => {
            if (prevItem.id === item.id) {
              return { ...prevItem, label };
            }
            return prevItem;
          })
        );
      },
      [setUpdatedItems, items]
    );

    const handleOpenItem = useCallback(item => {
      if (item.type === 'information') {
        window.open(
          routeWithParams(ROUTES.ADMIN_INFORMATION_ITEM, { params: { itemId: item[item.type].id } })
        );
      } else if (item.type === 'exercise') {
        window.open(
          routeWithParams(ROUTES.ADMIN, {
            params: { screen: 'exercises' },
            query: { exerciseId: item[item.type].id }
          })
        );
      }
    });

    const handleUndoPress = useCallback(() => {
      setUpdatedItems();
    }, []);

    const handleRemoveItem = useCallback(
      item => {
        setUpdatedItems((prev = items) => prev.filter(prevItem => prevItem.id !== item.id));
      },
      [setUpdatedItems, items]
    );

    const handleAddItemClose = useCallback(() => {
      setIsAddItemVisible(false);
    }, [setIsAddItemVisible]);

    const canSaveDetails =
      details &&
      !isEqual(pick(details, EDITABLE_DETAILS_FIELDS), pick(collection, EDITABLE_DETAILS_FIELDS));

    const canSaveCollectionItems = (() => {
      const { originalOrderedItems, originalUnorderedItems } = items.reduce(
        (prev, item) => ({
          ...prev,
          ...(item.displayOrder
            ? { originalOrderedItems: [...prev.originalOrderedItems, item] }
            : { originalUnorderedItems: [...prev.originalUnorderedItems, item] })
        }),
        { originalOrderedItems: [], originalUnorderedItems: [] }
      );

      if (
        !isEqual(originalOrderedItems, orderedItems) ||
        differenceWith(originalUnorderedItems, unorderedItems, isEqual).length > 0 ||
        differenceWith(originalOrderedItems, orderedItems, isEqual).length > 0
      ) {
        return true;
      }

      const missingItems = differenceBy(originalUnorderedItems, unorderedItems, 'id');

      if (missingItems.length > 0) {
        return true;
      }

      const extraItems = differenceBy(unorderedItems, originalUnorderedItems, 'id');

      if (extraItems.length > 0) {
        return true;
      }

      return false;
    })();

    return {
      isCollectionLoading,
      collectionError,
      collection: { ...collection, ...omitBy(details, isNil) },
      canSaveDetails,
      canSaveCollectionItems,
      orderedItems: sortBy(orderedItems, 'displayOrder'),
      unorderedItems: sortBy(unorderedItems, ({ label, type, [type]: { name } }) => label || name),
      isAddItemVisible,
      handleLabelChange,
      handleCompletablePress,
      handleAddItemClose,
      handleUndoPress,
      handleRemoveItem,
      handleOpenItem,
      handleAddItem,
      handleAddItemPress,
      handleItemsSavePress,
      handleDetailsSavePress,
      handleBackPress,
      handleCheckToggle,
      handleDetailsChange,
      handleItemsChange
    };
  }
});
