import { useState, useCallback } from 'react';
import PresentationContainer from 'react-presentation-container';

import AuthService from '@/services/auth.service';

import ForgotPassword from './ForgotPassword.component';

export default PresentationContainer({
  component: ForgotPassword,
  controller: function ForgotPasswordController() {
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');

    const onEmailChange = useCallback(e => setEmail(e.target.value));

    const onSendPress = useCallback(async () => {
      if (!email) {
        setErrors({ email: ['Email required'] });
        return;
      }

      try {
        await AuthService.passwordRecover(email);
        setSuccess(true);
      } catch (e) {
        setErrors({ form: [e.message] });
      }
    });

    return { email, errors, success, onEmailChange, onSendPress };
  }
});
